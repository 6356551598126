import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import '../../../styles/assets/css/pages/patient_contract.css'

import Button from '@material-ui/core/Button'
//import '../../../styles/assets/css/admin/patient_contract.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { progress } from '../../admin/variables/patient_contract'
//import FiberNewIcon from '@mui/icons-material/FiberNew'
import WarningIcon from '@material-ui/icons/Warning'

class ConsentFormList extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          getData: [],
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var pid = this.props.authReducer.uid
    axios({
      method: 'post',
      url: connectNode + '/patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: pid }
    })
    .then((result) => {
      this.setState({
        getData: result['data'],
      })
    })
  }

  render() {
    return (
    <div className="patient_contract_list box_border4">
      <div className="row">
        <div className="small-12 columns headerPage1">
          Consent Forms
        </div>
      </div>
      {this.props.locationReducer.txt_message &&
      <div className="row warning_msg7">
        <div className="small-12 columns ">
          <div className="float_left1"><CheckCircleOutlineIcon /></div>
          <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
        </div>
      </div>}
      <div className="row">
        <div className="small-12 columns ">
          <div>
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>Form</th>
                  <th>Consented</th>
                </tr>
              </thead>
              <tbody>
              {this.state.getData.clinic_policies_end > 0 &&
              <tr>
                <td>Clinic Policies</td>
                <td><a href="/member/consent/clinicpolicy">PDF</a></td>
              </tr>}
              {this.state.getData.patient_waiver_date != null &&
              <tr>
                <td>Patient Waiver</td>
                <td><a href="/member/consent/patientwaiver">PDF</a></td>
              </tr>}
              {this.state.getData.communication_consent == 1 &&
              <tr>
                <td>Communication Preferences</td>
                <td><a href="/member/consent/communication">PDF</a></td>
              </tr>}
              {this.state.getData.treatment_consent == 1 &&
              <tr>
                <td>Treatment Consent Form</td>
                <td><a href="/member/consent/treatment">PDF</a></td>
              </tr>}
              <tr>
                <td>FITT-BD Information Sheet
                {this.state.getData.information_sheet_end == null &&
                  /*<FiberNewIcon className="icon_new1"/>*/'New'}
                </td>
                <td>
                {this.state.getData.information_sheet == 1 &&
                  <a href="/member/consent/information_sheet">PDF</a>}
                {this.state.getData.information_sheet == 2 && '-'}
                {this.state.getData.information_sheet_end == null &&
                  <a href="/member/information_sheet">Sign</a>}
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('PatientApptList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConsentFormList)
