import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { getNextPage } from './course_function'
import '../../../styles/assets/css/pages/interventions.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ReactHtmlParser from 'react-html-parser'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Accordion, AccordionItem } from '@szhsin/react-accordion'
import Popup from 'reactjs-popup'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import CourseNavigation from './course_navigation.jsx'

class CourseSession extends Component {
  constructor() {
    super(  );
    this.state = {
      session_title: '',
      title: '',
      content: '',
      image: '',
      tracker_steps: [],
      next_step: '',
      status_submit: true,
      start_datetime: new Date().toLocaleString(),
      start_timestamp: (Date.now()/1000),
      message: '',
      content: '',
      content_position: 0,
      content2: '',
      content2_position: 0,
      new_choices_val: '',
      new_choices: [],
      choice_selected: [],
      yn_questions: [],
      yn_questions_type: 0,
      yn_selected: {},
      select_question_type: 0,
      select_questions: [],
      select_questions_required: 0,
      select_questions_required_txt: '',
      select_questions_ans: 0,
      select_reward_type: 0,
      select_reward: 0,
      select_reward_condition: '',
      select_reward_condition_val: -1,
      select_reward_message: '',
      select_reward_message_condition: '',
      tf_main: [],
      tf_previous: [],
      tf_answer: [],
      tf_filled: [],
      filled_data: [],
      pick_one: -1,
      dd_main: [],
      dd_answer: [],
      time: 0,
      isOn: false,
      start: 0,
      showtime: '',
      curr_last_page: 0,
      content_addon: 0,
      total_rewards: 0,
      start_date: '',
      weeks_available: 99,
    }
  }

  componentDidMount() {
    window.addEventListener("click", function(e) {
      //console.log(e.srcElement.id)
      for(var i = 1; i<=10; i++) {
        if(e.srcElement.id == 'popup'+i) {
          document.getElementById('warning_box'+i).style.display = 'block'
        }
        if(e.srcElement.id == 'ans_y'+i) {
          document.getElementById('q_y'+i).style.display = 'block'
        }
        if(e.srcElement.id == 'ans_n'+i) {
          document.getElementById('q_n'+i).style.display = 'block'
        }
      }
      if(e.srcElement.id == 'start') {
        this.startTimer()
        document.getElementById('show_time').style.display = 'block'
      }
      if(e.srcElement.id == 'stop') {
        this.stopTimer()
      }
      if(e.srcElement.id == 'clear') {
        this.clearTimer()
      }
    })
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_session_page',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        cid: this.props.match.params.cid,
        session: this.props.match.params.session,
        sub_session: this.props.match.params.sub_session,
        sub_session_page: this.props.match.params.sub_session_page,
      }
    })
    .then((result) => {
      const start_date = result['data'][0][0][0]['start_date']
      const weeks_available = result['data'][0][0][0]['weeks_available']
      if(this.calculateDayDiff(start_date, weeks_available) == 2) {
        window.location.href = '/member/intervention/'+this.props.match.params.cid+'/main'
      } else {
        const get_content = result['data'][0][0][0]
        const get_content2 = result['data'][0][1]
        const get_accordion = result['data'][1]
        const get_popup = result['data'][2]
        const get_choices = this.checkFilledCheckbox(result['data'][3], result['data'][9])
        const get_yn_questions = result['data'][4]
        const get_select_questions = result['data'][5]
        const get_tf_main = result['data'][6].length > 0 ? result['data'][6][0] : []
        const get_tf_previous = result['data'][6].length > 0 ? result['data'][6][1] : 0
        const get_dd = result['data'][7]
        const get_stars = result['data'][8]
        const get_filled_data = result['data'][9]
        const get_tf_filled = result['data'][9]
        //console.log(get_filled_data)
        if(get_content.title || get_content.content) {
          var gen_tracker = []
          var gen_tf_answer = []
          var gen_dd_answer = []
          for(var i = 1; i <= get_content.sub_session; i++) {
            gen_tracker.push(i)
          }
          if(get_tf_main.length > 0) {
            get_tf_main.map(each => {
              gen_tf_answer.push({
                id: each.field_id,
                main_id: each.id,
                val: '',
                field_name: each.field_name })
            })
            gen_tf_answer = [gen_tf_answer]
          }
          if(get_dd.length > 0) {
            get_dd.map(each => {
              gen_dd_answer.push({
                qid: each.qid,
                val: 0,
              })
            })
          }
          this.setState({
            start_date: start_date,
            weeks_available: weeks_available,
            session_title: get_content.session_title,
            title: get_content.title,
            content: get_content.content,
            content_position: get_content.content_position,
            content2: get_content2.length > 1 && get_content2[1].content,
            content2_position: get_content2.length > 1 && get_content2[1].content_position,
            content_addon: get_content.add_on,
            image: get_content.image,
            tracker_steps: gen_tracker,
            completed_session_status: get_content.curr_last_page,
            accordion_status: get_content.type,
            accordion: get_accordion,
            reward_type: get_content.reward_type,
            reward: get_content.reward,
            popup: get_popup,
            choices: get_choices,
            choices_type: get_choices.length > 0 ? get_choices[0].type : 0,
            yn_questions: get_yn_questions,
            yn_questions_type: get_yn_questions.length > 0 ? get_yn_questions[0].type : 0,
            select_question_type: get_select_questions.length > 0 ? get_select_questions[0].type : 0,
            select_questions: get_select_questions.length > 0 ? this.reformSelectChoice(get_select_questions) : [],
            select_questions_required: get_select_questions.length > 0 ? get_select_questions[0].required : 0,
            select_questions_required_txt: get_select_questions.length > 0 ? get_select_questions[0].txt_required : '',
            select_reward_type: get_select_questions.length > 0 ? get_select_questions[0].select_reward_type : 0,
            select_reward: get_select_questions.length > 0 ? get_select_questions[0].select_reward : 0,
            select_reward_condition: get_select_questions.length > 0 ? get_select_questions[0].select_reward_condition : '',
            select_reward_condition_val: get_select_questions.length > 0 ? get_select_questions[0].select_reward_condition_val : -1,
            select_reward_message: get_select_questions.length > 0 ? get_select_questions[0].select_reward_message : '',
            select_reward_message_condition: get_select_questions.length > 0 ? get_select_questions[0].select_reward_message_condition : '',
            filled_data: get_filled_data,
            tf_main: get_tf_main,
            tf_previous: this.reformPopupAnswer(get_tf_previous, gen_tf_answer),
            tf_main_answer: this.checkFilledTF(gen_tf_answer[0], get_tf_filled),
            tf_filled: get_tf_filled,
            dd_main: get_dd,
            dd_answer: gen_dd_answer,
            total_rewards: get_stars,
            curr_last_page: get_content.curr_last_page,
            next_step: getNextPage(this.props.match.params.cid, get_content.curr_last_page,
              get_content.next_last_page, this.props.match.params.session,
              get_content.next_sub_session, get_content.next_sub_session_page),
          })
        } else {
          window.location.href = '/dashboard'
        }
      }
    })
  }

  checkFilledTF = (tf_main_answer, tf_filled) => {
    var genData = []
    genData = [[]]
    var genEach = {}
    if(tf_main_answer && tf_filled[0]) {
      if(tf_main_answer[0].main_id == tf_filled[0].tf_main_id) {
        tf_main_answer.map(e1 => {
          genEach = e1
          tf_filled.map(e2 => {
            if(e1.id == e2.tf_main_field_id) {
              genEach.val = e2.content
            }
          })
          genData[0].push(genEach)
        })
        return genData
      }
      return tf_main_answer
    } else if(tf_main_answer) {
      tf_main_answer.map(e1 => {
        genEach = e1
        genData[0].push(genEach)
      })
      return genData
    }
    return []
  }

  reformPopupAnswer = (tf_previous, tf_main_answer) => {
    var genPrevious = []
    //console.log(tf_previous)
    //console.log(tf_previous.length)
    if(tf_previous.length > 0 && tf_previous[0].length > 0) {
      tf_previous.map(each => {
        if(each[0].field_name == tf_main_answer[0][0].field_name &&
           each[1].field_name == tf_main_answer[0][1].field_name &&
           each[2].field_name == tf_main_answer[0][2].field_name) {
             genPrevious.push(each)
           }
      })
    }
    return genPrevious
  }

  reformSelectChoice = (select) => {
    var q_id = 0
    var genVal = []
    var genQuestion = ''
    var genTxt_required = ''
    var genChoices = []
    select.map((each, index) => {
      //console.log(each)
      if(each.q_id != q_id) {
        q_id = each.q_id
        genQuestion = each.question
        genTxt_required = each.txt_required
      }
      genChoices.push({
        id: each.id,
        label: each.label,
        choice_order: each.choice_order,
        value: each.value,
      })
      if((each.q_id != q_id || index == select.length-1) && q_id > 0) {
        genVal.push({
          q_id: q_id,
          question: genQuestion,
          required: each.required,
          txt_required: genTxt_required,
          choices: genChoices,
        })
        genChoices = []
      }
      //console.log(genVal)
    })
    return(genVal)
  }

  handleOpenWindow = () => {
    document.getElementById('warning_box').style.display = 'block'
  }

  handlePopup1() {
    window.addEventListener("click", function(e) {
      //console.log(e.srcElement.id)
      if(e.srcElement.id == 'popup1') {
        //document.getElementById('warning_box').style.display = 'block'
        this.handleOpenWindow()
      }
    })
  }

  closeWarningBox = (e, id) => {
    if(id) {
      document.getElementById(id).style.display = 'none'
      if(document.getElementById('track1')) {
        var player = document.getElementById('track1')
        player.pause()
      }
      if(document.getElementById('track2')) {
        var player = document.getElementById('track2')
        player.pause()
      }
    }
  }

  replaceWords = (text, replace) => {
    let result = text.replace("handleUpdateStatus", "onClick={()=>this.handleUpdateStatus(item.id)}");
  }

  checkRequired = () => {
    var val = 1
    //console.log(this.props.match.params.session)
    if(this.state.select_questions_required == 1 && this.state.select_questions_ans == 0) {
      if(this.props.match.params.session == 3 && this.props.match.params.sub_session == 2 && this.props.match.params.sub_session_page == 1 && this.state.select_questions_ans == 0) {

      } else {
        val = -1
      }
    }
    if(this.state.tf_main.length > 0) {
      if(this.state.tf_main[0].required == 1) {
        var buff = {}
        this.state.tf_main_answer.map(each => {
          for(var key in each) {
            if(each.hasOwnProperty(key)) {
              buff = each[key]
              if(buff.val.trim() == '') {
                val = -1
              }
            }
          }
        })
      }
    }
    if(this.state.dd_main.length > 0) {
      this.state.dd_main.map((each, index) => {
        if(each.required == 1 && this.state.dd_answer[index].val == 0) {
          val = -1
        }
      })
    }

    return val
  }

  handlePrevious = (e) => {
    window.history.go(-1)
    return false
  }

  calculateDayDiff = (date, weeks_available) => {
    var dateDiff =  Math.floor(Math.abs(new Date() - new Date(date)) / (1000 * 60 * 60 * 24))
    //console.log(date, weeks_available + " : " + dateDiff)
    if(weeks_available > 0) {
      if(dateDiff/7 >= weeks_available) {
        return 1
      } else {
        return 2
      }
    } else {
      return 1
    }
    return 2
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.checkRequired() == -1) {
      var getMessage = 'Please fill all information'
      if(this.state.select_questions_required_txt != '') {
        getMessage = this.state.select_questions_required_txt
      }
      if(this.state.tf_main.length > 0) {
        if(this.state.tf_main[0].tf_reward_message != '') {
          getMessage = this.state.tf_main[0].tf_reward_message
        }
      }
      this.setState({
        message: getMessage,
      })
      window.scrollTo(0, 0)
    } else {
      var genTF = 0
      if(this.state.tf_main.length > 0) {
        genTF = {
          main_id: this.state.tf_main[0].id,
          tf_reward_type: this.state.tf_main[0].tf_reward_type,
          tf_reward_condition: this.state.tf_main[0].tf_reward_condition,
          tf_reward_condition_val: this.state.tf_main[0].tf_reward_condition_val,
          tf_reward: this.state.tf_main[0].tf_reward,
        }
      }
      axios({
        method: 'post',
        url: connectNode + 'patient/courses/update_session_content_progress',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          cid: this.props.match.params.cid,
          session: this.props.match.params.session,
          sub_session: this.props.match.params.sub_session,
          sub_session_page: this.props.match.params.sub_session_page,
          completed_session_status: this.state.completed_session_status,
          start_datetime: this.state.start_datetime,
          start_timestamp: this.state.start_timestamp,
          reward_type: this.state.reward_type,
          reward: this.state.reward,
          choice_selected: this.state.choice_selected,
          yn_selected: this.state.yn_selected,
          tf_main: genTF,
          tf_main_answer: this.state.tf_main_answer,
          select_questions_ans: {
            select_questions_ans: this.state.select_questions_ans,
            select_reward_type: this.state.select_reward_type,
            select_reward: this.state.select_reward,
            select_reward_condition: this.state.select_reward_condition,
            select_reward_condition_val: this.state.select_reward_condition_val,
          },
          dd_answer: this.state.dd_answer,
        }
      })
      .then((result) => {
        if(result['data'] == this.props.authReducer.uid) {
          this.props.onLoadLocation(this.state.next_step, '')
          window.location.href = this.state.next_step
        }
      })
    }

  }

  appendInput = (name, fields, val) => {
    if(val.length > 0) {
      var newInputID = fields.length > 0 ? fields[fields.length-1].value + 1 : 1 //this.state.choices[this.state.choices.length-1].value + 1
      this.setState(prevState => (
        { [name]: prevState[name].concat([{
            //id: newInputID, val: val,
            choice_order: newInputID,
            label: val,
            type: this.state.choices_type,
            value: newInputID,
          }]),
          new_choices_val: '',
        }
      ))
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e) => {
    var fields = this.state.tf_main_answer
    var getIndex = fields.findIndex(obj => obj.id === e.target.value)
    var getVal = ''
    if (e.target.checked) {
      if(getIndex == -1 ) {
        getIndex = this.state.choices.findIndex(obj => obj.value == e.target.value)
        if(getIndex > -1) {
          getVal = this.state.choices[getIndex].label
        } else {
          getVal = 1
        }
        fields.push({id: e.target.value, value: getVal})
      }
    } else {
      if (getIndex > -1) {
        fields.splice(getIndex, 1);
      }
    }
    this.setState({
      choice_selected: fields,
    })
  }

  handleChange3 = (e) => {
    var getVal = this.state.yn_selected
    this.setState({
      yn_selected: Object.assign(getVal, {[e.target.name]: e.target.value}),
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state.[name])
  }

  handleChange6 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange6_1 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
    //console.log(this.state.select_reward_condition_val+0)
    if(this.state.select_reward_type == 1 && this.state.select_reward_condition.length > 0) {
      if(this.state.select_reward_condition == '>') {
        if(e.target.value > this.state.select_reward_condition_val+0) {
          this.setState({
            popup: [{
              content: this.state.select_reward_message,
              popup_order: 1,
              popup_type: 1,
              title: "",
            }]
          })
          document.getElementById('popup1').click()
        } else if(e.target.value == this.state.select_reward_condition_val+0) {
          this.setState({
            popup: [{
              content: this.state.select_reward_message_condition,
              popup_order: 1,
              popup_type: 1,
              title: "",
            }]
          })
          document.getElementById('popup1').click()
        }
      } else if(this.state.select_reward_condition == '=') {
        //console.log(e.target.value+' '+this.state.select_reward_condition+' '+this.state.select_reward_condition_val)
        if(e.target.value == this.state.select_reward_condition_val) {
          //console.log(this.state.select_reward_message
          document.getElementById('popup1').click()
        } else {
          if(this.state.popup.length > 1) {
            document.getElementById('popup2').click()
          }
        }
      }
    }
  }

  handleChange7 = (e) => {
    var fields = this.state.tf_main_answer
    var getIndex = fields.findIndex(obj => obj.id === e.target.value)
    var getVal = ''

    if (e.target.checked) {
      if(getIndex == -1 ) {
        getIndex = this.state.choices.findIndex(obj => obj.value == e.target.value)
        if(getIndex > -1) {
          getVal = this.state.choices[getIndex].label
        } else {
          getVal = 1
        }
        fields.push({id: e.target.value, value: getVal})
      }
    } else {
      if (getIndex > -1) {
        fields.splice(getIndex, 1);
      }
    }
    this.setState({
      choice_selected: fields,
    })
  }

  checkChkCheckbox = (choice_selected, checked) => {
    var val = ''
    if(choice_selected.length > 0) {
      choice_selected.map(each => {
        console.log(each.id + " : " + checked)
        if(each.id == checked) {
          val = 'checked'
        }
      })
    }
    return val
  }

  checkFilledCheckbox = (choices, filled_data) => {
    var buffData = {}
    var newData = []
    choices.map(each => {
      buffData = each
      buffData.checked = ""
      filled_data.map(e2 => {
        if(e2.choice_value == each.label) {
          buffData.checked = "checked"
        }
      })
      newData.push(buffData)
    })
    if(choices.length > 0) {
      filled_data.map(e2 => {
        if(e2.choice_id > choices[choices.length-1].value) {
          newData.push({
            checked: "checked",
            choice_order: choices[choices.length-1].choice_order + 1,
            label: e2.choice_value,
            type: 1,
            value: e2.choice_id,
          })
        }
      })
    }
    return newData
  }

  handleChangeTF = (e, tf_index) => {
    var field_id = Number(e.target.name.substr(3, 10).trim())
    var buff = this.state.tf_main_answer
    var getIndex = buff[tf_index].findIndex(obj => obj.id == field_id)
    buff[tf_index][getIndex].val = e.target.value
    this.setState({
      tf_main_answer: buff,
    })
  }

  appendInputTF = (name, fields, val) => {
    var gen_tf_answer = []
    this.state.tf_main.map(each => {
      gen_tf_answer.push({
        id: each.field_id,
        main_id: each.id,
        val: '',
        field_name: each.field_name })
    })
    gen_tf_answer = [gen_tf_answer]
    this.setState(prevState => ({
      tf_main_answer: prevState['tf_main_answer'].concat(gen_tf_answer),
    }))
  }

  removeInputTF = (e, tf_index) => {
    var buff = this.state.tf_main_answer
    buff.splice(tf_index, 1)
    this.setState({
      tf_main_answer: buff,
    })
  }

  fillInTF = (e, picked) => {
    var getAns = this.state.tf_main_answer[0]
    var getIndex = -1
    getAns.map((each, index) => {
      getIndex = picked.findIndex(obj => obj.field_name == each.field_name)
      if(getIndex >= 0) {
        getAns[index].val = picked[getIndex].val
      }
    })
    this.setState({
      tf_main_answer: [getAns],
    })
    this.closeWarningBox(e, 'warning_box1')
  }

  handleChangeDD1 = (e, index) => {
    //console.log(index + " : " + e.target.value)
    var buff = this.state.dd_answer
    buff[index].val = e.target.value
    var buff_main = this.state.dd_main[index]
    if(buff_main.type == 1) {
      if(buff_main.condition == '=') {
        document.getElementById("dd"+index).style.borderRadius = '5px';
        if(e.target.value == buff_main.condition_val) { // Right //
          document.getElementById("dd"+index).style.border = "3px solid #83d091";
        } else { // Wrong //
          document.getElementById("dd"+index).style.border = "3px solid #d77878";
        }
      }
    }
    this.setState({
      dd_answer: buff
    })
  }

  startTimer = () => {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.start,
      showtime: this.showTime(Date.now() - this.state.start)
    }), 1);
  }
  stopTimer = () => {
    this.setState({isOn: false})
    clearInterval(this.timer)
  }
  resetTimer = () => {
    this.setState({time: 0, isOn: false, showtime: "" })
  }
  showTime = (sec) => {
    return this.pad(parseInt(sec / 60000, 10)) + ' minutes ' + this.pad(parseInt(sec / 1000, 10)) + ' seconds '// + this.pad(sec++ % 60) + ' seconds'
  }
  pad = (val) => {
    val = val % 60
    var txt = val > 9 ? val : "0" + val
    return txt
  }

  handleStep = (step) => {
    window.location.href = "/member/intervention/"+this.props.match.params.cid+"/session/"+this.props.match.params.session+"/"+(step+1)+"/1"
  }

  render() {
    let start = (this.state.time == 0) ?
      <button onClick={this.startTimer}>start</button> :
      null
    let stop = (this.state.time == 0 || !this.state.isOn) ?
      null :
      <button onClick={this.stopTimer}>stop</button>
    let resume = (this.state.time == 0 || this.state.isOn) ?
      null :
      <button onClick={this.startTimer}>resume</button>
    let reset = (this.state.time == 0 || this.state.isOn) ?
      null :
      <button onClick={this.resetTimer}>reset</button>
    return (
      <div className="box_border4">
        <div className="row">
          <div className="small-12 medium-8 columns headerSession1">
            {this.state.session_title}
          </div>
          <div className="small-12 medium-4 columns nav_bar">
            <CourseNavigation />
          </div>
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={this.props.match.params.sub_session-1}>
            {this.state.tracker_steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                <StepButton onClick={(e) => this.handleStep(index)} >
                  <StepLabel {...labelProps} >
                  </StepLabel>
                </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== "" &&
              <div className="row warning_msg7">
                <div className="small-12 columns ">
                  <div className="float_left1"><CheckCircleOutlineIcon /></div>
                  <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            </div>}
          {this.state.title.trim().length > 0 &&
          <div className="title_session1">{this.state.title}</div>}
          <div className="small-12 columns block1">
            {this.state.message.length > 0 &&
            <div className="course_warning_msg1">{this.state.message}</div>}

            {this.state.content_position == 1 &&
            <div className="intervention_content1">{ReactHtmlParser(this.state.content)}</div>}
            {this.state.content2_position == 1 &&
            <div className="intervention_content1">{ReactHtmlParser(this.state.content2)}</div>}

            {this.state.content_addon == 1 &&
            <div className="intervention_content2">
              {(this.state.time == 0) ?
                <button onClick={this.startTimer} className="butt_timer" >start</button> : null}
              {(this.state.time == 0 || this.state.isOn) ? null :
                <button onClick={this.startTimer} className="butt_timer" >resume</button>}
              {(this.state.time == 0 || !this.state.isOn) ? null :
                <button onClick={this.stopTimer} className="butt_timer" >stop</button>}
              {(this.state.time == 0 || this.state.isOn) ? null :
                <button onClick={this.resetTimer} className="butt_timer" >reset</button>}
              <div className="top_space1 align_center bold">{this.state.showtime}</div>
            </div>}

            {this.state.content_addon == 2 &&
              <div className="small-12 columns">You’ve earned {this.state.total_rewards} stars
                over the course of this program. Well done!</div>}


            {this.state.accordion_status == 2 &&
            <div className="intervention_content1">
            <Accordion>
            {this.state.accordion.map((each, index) =>
              <AccordionItem header={ReactHtmlParser('<i class="fi-plus"></i> '+each.title)} key={'cc'+index} >{each.content}</AccordionItem>)}
            </Accordion>
            </div>}

            {this.state.choices_type > 0 &&
            <div className="intervention_content2">
              {this.state.content2_position == 3 &&
              <>
              <div className="small-12 columns">{this.state.content2}</div>
              <div className="sep_line_int1">&nbsp;</div>
              </>}
              {this.state.choices.map((each, index) =>
              <div>{each.value > 0 && each.type==1 &&
              <div className={"small-12 medium-6 columns space_bottom1"} key={'ch'+index} >
                <input type="checkbox"
                name="choices" value={each.value} onChange={(e) => this.handleChange7(e)}
                checked={this.checkChkCheckbox(this.state.choice_selected, each.value)} />
                {each.label}</div>}
              {each.value > 0  && each.type==2 &&
              <div className={"small-12 medium-6 columns space_bottom1"} key={'ch'+index} >
                <input type="radio"
                name="choices" value={each.value} onChange={(e) => this.handleChange2(e)}
                checked={each.checked} />
                {each.label}</div>}
              </div>)}
              {/*this.state.new_choices.map((each, index) =>
              <div className={"small-12 medium-6 columns space_bottom1"} key={'nch'+index} >
                <input type={(this.state.choices_type==1 && "checkbox") || (this.state.choices_type==2 && "radio")}
                name="choices" value={each.id} onChange={(e) => this.handleChange2(e)} />
                {each.val}</div>)*/}
              <div className="small-12 columns sep_line_int1">&nbsp;</div>
              <div className="show-for-medium  medium-1 columns no-show"><AddIcon /></div>
              <div className="small-12  medium-9 columns no_padding_left">
                <TextField label="" className="num_members" type="text" name="new_choices_val"
                onChange={(e) => this.handleChange(e)} variant="outlined" value={this.state.new_choices_val}
                InputProps={{ inputProps: { name: "new_choices_val", maxLength: 250 }}} />
              </div>
              <div className="small-12  medium-2 columns">
                <button type="button" className="addInput1"
                   onClick={ () => this.appendInput('choices', this.state.choices, this.state.new_choices_val) }>
                    Add
                </button>
              </div>
            </div>}

            {this.state.yn_questions_type > 0 &&
            <div className="intervention_content2">
              {this.state.yn_questions.map((each, index) =>
              <div key={'yn'+index}>
                <div className="small-12 medium-8 columns" >{each.question}</div>
                <div className="small-12 medium-2 columns" >
                  <input type="radio" name={"q"+each.id} value="1" id={"ans_y"+(index+1)}
                  onChange={(e) => this.handleChange3(e)} />
                  Yes</div>
                <div className="small-12 medium-2 columns" >
                  <input type="radio" name={"q"+each.id} value="2" id={"ans_n"+(index+1)}
                  onChange={(e) => this.handleChange3(e)} />
                  No</div>
                {index < this.state.yn_questions.length-1 && <div className="small-12 columns sep_line_int1">&nbsp;</div>}
                <div className="warning_box" id={"q_y"+(index+1)}>
                  <div className="row">
                    <div className="small-12 columns bold align_center">{each.yes_title}</div>
                    <div className="small-12 columns margin_top_space1">{each.yes_popup}</div>
                    <div className="small-12 columns margin_top_space1 align_center">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={(e) => this.closeWarningBox(e, "q_y"+(index+1))} type="button">Close</Button>
                    </div>
                  </div>
                </div>
                <div className="warning_box" id={"q_n"+(index+1)}>
                  <div className="row">
                    <div className="small-12 columns bold align_center">{each.no_title}</div>
                    <div className="small-12 columns margin_top_space1">{each.no_popup}</div>
                    <div className="small-12 columns margin_top_space1 align_center">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={(e) => this.closeWarningBox(e, "q_n"+(index+1))} type="button">Close</Button>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>}

            {this.state.select_question_type > 0 &&
            <div className="intervention_content3">
              {this.state.select_questions.map((each, index) =>
              <div className="row" key={'sl'+index}>
                <div className="small-12 medium-9 columns" >{each.question}</div>
                <div className="small-12 medium-3 columns" >
                <FormControl variant="outlined" >
                  {this.state.select_reward_type == 1 &&
                  <Select value={this.state.select_questions_ans} variant="outlined" label="" defaultValue=""
                    onChange={(e) => this.handleChange6_1(e, 'select_questions_ans')} >
                      {each.choices.map(ch =>
                        <MenuItem key={"slc"+each.id+ch.id} value={ch.value}>{ch.label}</MenuItem>
                      )}
                  </Select>}
                  {this.state.select_reward_type != 1 &&
                  <Select value={this.state.select_questions_ans} variant="outlined" label="" defaultValue=""
                    onChange={(e) => this.handleChange6(e, 'select_questions_ans')} >
                      {each.choices.map(ch =>
                        <MenuItem key={"slc"+each.id+ch.id} value={ch.id}>{ch.label}</MenuItem>
                      )}
                  </Select>}
                </FormControl>
                {this.state.select_reward_type == 1 && <a id="popup1" class="click_here"></a>}
                {this.state.popup.length > 1 && <a id="popup2" class="click_here"></a>}
              </div>
            </div>)}
            </div>}

            {this.state.dd_main.length > 0 &&
            <div className="intervention_content3">
              {this.state.dd_main.map((each, index) =>
              <div className="row top_space1" key={'ddm'+index}>
                <div className="small-12 medium-8 columns" >{each.question}</div>
                <div className="small-12 medium-4 columns" >
                <FormControl variant="outlined" id={'dd'+index} >
                  {each.type == 1 &&
                  <Select variant="outlined" label="" defaultValue=""
                    onChange={(e) => this.handleChangeDD1(e, index)} >
                    <MenuItem value="0" >{each.empty_default}</MenuItem>
                    {each.choices.map((ch, ch_index) =>
                      <MenuItem key={"slc"+index+ch_index} value={ch[0].val}>{ch[0].label}</MenuItem>
                    )}
                  </Select>}
                </FormControl>
              </div>
            </div>)}
            </div>}

            {this.state.tf_main.length > 0 &&
              <div className="row">
                <div className="small-12 columns">
                  <div className="intervention_content4">
                    <div className="course_title_topic1">{this.state.tf_main[0].tf_title}</div>
                    <div className="">{ReactHtmlParser(this.state.tf_main[0].tf_content)}</div>
                    {this.state.tf_main_answer.map((tf, tf_index) =>
                      <div key={'tfm'+tf_index}>
                      {tf.map((each, index) =>
                      <div className="row top_space1" key={'tf'+index}>
                        <div className="small-12 medium-3 columns txt_right_desktop_only bold">{each.field_name}</div>
                        <div className="small-12 medium-8 columns">
                          <TextField label="" className="num_members" type="text" name={'tf_'+each.id}
                          onChange={(e) => this.handleChangeTF(e, tf_index)} variant="outlined" value={each.val}
                          InputProps={{ inputProps: { name: 'tf_'+each.id, maxLength: 250 }}} />
                        </div>
                        <div className="small-12 medium-1 columns align_right">
                        {index == tf.length - 1 && tf_index > 0 &&
                          <CancelIcon className="remove_icon top_space1" onClick={ (e) => this.removeInputTF(e, tf_index) } />}
                        </div>
                      </div>)}
                      <div className="small-12 columns sep_line_int3">&nbsp;</div>
                    </div>)}
                    {this.state.tf_main[0].add_more === 1 &&
                    <div className="small-12 medium-2 columns">
                      <button type="button" className="addInput1"
                         onClick={ () => this.appendInputTF('choices', this.state.choices, this.state.new_choices_val) }>
                          Add
                      </button>
                    </div>}
                  </div>
                  {this.state.tf_main[0].fill_in_popup === 1 &&
                  <div className="warning_box" id="warning_box1">
                    <div className="row">
                      {this.state.tf_main[0].tf_title &&
                      <div className="small-12 columns bold align_center">{this.state.tf_main[0].tf_title}</div>}
                      <div className="small-12 columns margin_top_space1">
                      {this.state.tf_previous.map((tfp, tfp_index) =>
                        <div key={'tfmp'+tfp_index} className="each_tp_popup" >
                          {tfp.map((each, index) =>
                          <div className="row">
                            <div className="small-12 medium-1 no_padding_right2 columns">
                            {index == 0 &&
                              <input type="radio" name="pick_one" value={tfp_index} onChange={(e) => this.handleChange(e)} />}&nbsp;
                            </div>
                            <div className="small-12 medium-4 columns">{each.field_name}</div>
                            <div className="small-12 medium-7 columns">{each.val}</div>
                          </div>)}
                        </div>)}
                      </div>
                      <div className="small-12 columns margin_top_space1 align_center">
                      <Button variant="contained" color="primary" disableElevation className="margin_right_1"
                       onClick={(e) => this.fillInTF(e, this.state.tf_previous[this.state.pick_one])} type="button">ADD</Button>
                       <Button variant="contained" color="primary" disableElevation
                        onClick={(e) => this.closeWarningBox(e, "warning_box1")} type="button">Close</Button>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            }

            {this.state.content_position == 2 &&
            <div className="intervention_content1">{ReactHtmlParser(this.state.content)}</div>}
            {this.state.content2_position == 2 &&
            <div className="intervention_content1">{ReactHtmlParser(this.state.content2)}</div>}

            {this.state.popup && this.state.popup.length > 0 &&
              this.state.popup.map((each, index) =>
              <div className="warning_box" id={"warning_box"+(index+1)}>
                <div className="row">
                  {this.state.popup && this.state.popup[index].title &&
                  <div className="small-12 columns bold align_center">{this.state.popup[index].title}</div>}
                  <div className="small-12 columns margin_top_space1">{ReactHtmlParser(this.state.popup[index].content)}</div>
                  {this.state.popup[index].popup_type == 1 &&
                  <div className="small-12 columns margin_top_space1 align_center">
                  <Button variant="contained" color="primary" disableElevation
                   onClick={(e) => this.closeWarningBox(e, "warning_box"+(index+1))} type="button">OK</Button>
                  </div>}
                  {this.state.popup[index].popup_type == 2 &&
                  <div className="small-12 columns margin_top_space1 align_center">
                  <Button variant="contained" color="primary" className="margin_right_1" disableElevation
                   onClick={this.confirmCancel} type="button">Yes</Button>
                  <Button variant="contained" color="primary" disableElevation
                   onClick={(e) => this.closeWarningBox(e, "warning_box"+(index+1))} type="button">No</Button>
                  </div>}
                </div>
              </div>
            )}

            {this.state.image.trim().length > 0 &&
            <div className="img1"><img src={this.state.image} /></div>}
          </div>

          <div className="small-12 columns align_right">
            <Button variant="contained" color="primary" disableElevation className="previous_space1"
             onClick={this.handlePrevious} type="submit">PREVIOUS</Button>
            <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit">
             {this.state.curr_last_page == 0 && "NEXT"}
             {this.state.curr_last_page == 1 && "FINISH"}
            </Button>
          </div>
        </div>
      </div>
    </div>
)}}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    //console.log(route)
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourseSession)
