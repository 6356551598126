import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { phq9_1, phq9_2, phq9_questions } from '../variables/assessments/phq9.js'
import { calculateTimepoint1 } from '../../functions/timepoint.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

import StepperAssm from './stepper_assm'

class Phq9 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: 1, // 1: no data, 2: completed, 3: past
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'asrm',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
          q6: -1,
          q7: -1,
          q8: -1,
          q9: -1,
          q10: -1,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    if(timepoint == -1) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_baseline_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { uid: this.props.authReducer.uid }
      })
      .then((result) => {
          if(result['data'] !== 'phq9') {
            this.props.history.push('/member/assessment/'+result['data']+'/'+this.props.match.params.type+'/'+timepoint)
          }
        }
      )
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/get_assessments_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid:  this.props.authReducer.uid,
        }
      })
      .then((result) => {
        var next_step = 'asrm'
        if(timepoint == -2) {
          next_step = 'asrm'
        } else {
          if(result['data'].m_asrm == 0) {
            next_step = 'gad7'
            if(result['data'].m_gad7 == 0) {
              next_step = 'psqi'
              if(result['data'].m_psqi == 0) {
                next_step = 'who5'
                if(result['data'].m_who5 == 0) {
                  next_step = 'providers'
                  if(result['data'].m_providers == 0) {
                    next_step = 'mtf'
                    if(result['data'].m_mtf == 0) {
                      next_step = 'complete_assessments'
                    }
                  }
                }
              }
            }
          }
        }
        this.setState({
          next_step: next_step,
        })
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/chk_each_assm',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          assm: 'patients_assessment_phq9',
         }
      })
      .then((result) => {
        //console.log(result['data'])
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        //console.log(calTimepoint)
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].assm_status == 1) {
              // Completed //
              this.getCompletedData(2)
            } else {
              if(result['data'].status == 1 && result['data'].step !== 'phq9') {
                this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
              }
            }
          } else {
            // Back up overview //
            this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }
        } else {
          // Past timepoint //
          this.getCompletedData(3)
        }
      })
    }
  }

  getCompletedData = (status) => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: this.props.match.params.type,
        timepoint: this.props.match.params.timepoint,
        frm: 'phq9',
       }
    }).then((result) => {
      if(result['data'].status == 1) {
        this.setState({
          status: status,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          q9: result['data'].q9,
          q10: result['data'].q10,
        })
      }
      //console.log(this.state)
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = this.state.next_step
    if(this.state.status === 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1 || this.state.q6 === -1 ||
        this.state.q7 === -1 || this.state.q8 === -1 || this.state.q9 === -1 ||
        this.state.q10 === -1 ) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        var timepoint = this.props.match.params.timepoint
        var type = this.props.match.params.type
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_phq9',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            type: type,
            timepoint: timepoint,
          }
        })
        .then((result) => {
          //console.log(result['data'])
          if(result['data'] === next_step) {
            this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
            this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
          }
        })
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }

  }

  render() {

    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Health Questionnaire (PHQ-9)
          </div>
          {//this.state.status == 1 &&
          <StepperAssm activeStep={1} assm={'PHQ-9'} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box2_2">
            <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
          </div>
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-8 columns">
                Over the PAST TWO WEEKS, how often have you been bothered by any of the following problems?
              </div>
              {phq9_1.map(each =>
                <div className="medium-1 columns headerResize1" key={"q"+each.value}>
                  {each.label}
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>
            <div className="scroll_y_1">
            {phq9_questions.map(eachQ =>
              <div className="row" key={"q9_"+eachQ.value}>
                <div className="small-12 medium-8 columns">
                  {eachQ.value}. {eachQ.label}
                </div>
                {phq9_1.map(each =>
                  <div className="small-12 medium-1 columns" key={"q91"+each.value}>
                    <input type="radio" name={"q"+eachQ.value} value={each.value}
                    checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>
                )}
                {eachQ.value < 9 &&
                  <div className="small-12 columns">
                    <div className="sep_line3">&nbsp;</div>
                  </div>
                }
              </div>
            )}
            </div>

            <div className="row">
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
              <div className="small-12 columns">
                10. If you checked off any problems, how difficult have those problems made it for you to Do your work, take care of things at home, or get along with other people?
              </div>
              {phq9_2.map(each =>
                <div className="small-12 medium-3 columns"  key={"q92"+each.value}>
                  <input type="radio" name="q10" value={each.value}
                  checked={this.state['q10'] == each.value && 'checked'}
                  disabled={this.state.status > 1 && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>
              )}
            </div>

        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  //console.log("timepoint: "+this.props.match.params.timepoint)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Phq9)
