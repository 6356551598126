import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { tobacco_scale, audit_c1_scale, audit_c2_scale, audit_c3_scale } from '../variables/assessments/mini.js'


import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import validator from 'validator'
import StepperSCID from './stepper_SCID'

//import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

class Scid4_v1 extends Component {
  constructor() {
      super()
      this.quill = require('react-quill')
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'scid5_v1',
          tobacco: -2,
          audit_c_1: -2,
          audit_c_2: -2,
          audit_c_3: -2,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    if(this.props.match.params.task == 'edit') {
      this.getData('edit')
    } else {
      // Initial MINI form to database //
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/chk_scid_v1_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          timepoint: this.props.match.params.timepoint,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          //console.log('step: '+result['data'].mini_step)
          if(result['data'].scid_step === 'complete') {
            this.props.history.push('/admin/assessments/scid_v1_result/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else if(result['data'].scid_step !== 'scid4_v1') {
            this.getData('new')
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      })
    }
  }

  getData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_scid_v1_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'].end_2_timestamp > 0) {
        this.setState({
          completed_status: true,
          edit_status: get_edit_status,
          tobacco: result['data'].tobacco,
          audit_c_1: result['data'].audit_c_1,
          audit_c_2: result['data'].audit_c_2,
          audit_c_3: result['data'].audit_c_3,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    this.setState({
      status_submit: false,
      message: '',
    })
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/update_scid4_v1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        timepoint: timepoint,
      }
    })
    .then((result) => {
        if(result['data'] === this.state.next_step) {
          this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
          this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
        }
      }
    )
  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_scid4_v1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
      if(result['data'] == pid) {
        this.props.onLoadLocation('/admin/assessments/scid_v1_result/'+pid+'/-1', 'Edit successfully!')
        this.props.history.push('/admin/assessments/scid_v1_result/'+pid+'/-1')
      }
    })
  }

  render() {
    const ReactQuill = this.quill
    return (
      <div className="assm_mini mini_result box_border4">
        { //this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            Summary Sheet: Intake Appointment
          </div>
          {//!this.props.match.params.task == 'edit' &&
          <StepperSCID activeStep={4} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        { //this.state.appt_id > 0 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box1 frm_rad_1">
              <div className="row">
                <div className="small-12 columns bold underline bottom_space2">
                  Tobacco 30 day use
                </div>
                <div className="small-12 columns bold bottom_space1">
                  Has the patient used any tobacco product in the past 30 days?
                </div>
                {tobacco_scale.map(each =>
                  <div className="small-12 columns bottom_space2" key={'tb'+each.label}>
                    <input type="radio" name="tobacco" value={each.value}
                    checked={this.state.tobacco == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="small-12 columns box1 frm_rad_1">
              <div className="row">
                <div className="small-12 columns bold underline bottom_space1">
                  AUDIT-C
                </div>
                <div className="small-12 columns bold bottom_space1">
                  1. How often do you have a drink containing alcohol?
                </div>
                {audit_c1_scale.map(each =>
                  <div className="small-12 columns bottom_space2" key={'ac1'+each.label}>
                    <input type="radio" name="audit_c_1" value={each.value}
                    checked={this.state.audit_c_1 == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns bold bottom_space1">
                  2. How many standard drinks containing alcohol do you have on a typical day?
                </div>
                {audit_c2_scale.map(each =>
                  <div className="small-12 columns bottom_space2" key={'ac2'+each.label}>
                    <input type="radio" name="audit_c_2" value={each.value}
                    checked={this.state.audit_c_2 == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns bold bottom_space1">
                  3. How often do you have 6 drinks or more on one occasion?
                </div>
                {audit_c3_scale.map(each =>
                  <div className="small-12 columns bottom_space2" key={'ac3'+each.label}>
                    <input type="radio" name="audit_c_3" value={each.value}
                    checked={this.state.audit_c_3 == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns align_right">
            {!this.state.completed_status &&
              <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" className="submit_1">
                  Submit
              </Button>}
              {this.state.completed_status && !this.state.edit_status &&
              <Button variant="contained" color="primary" disableElevation
                 onClick={(e) => this.handleNext('scid5_v1')} className="submit_1">
                  Next
              </Button>}
              {this.state.edit_status &&
              <Button variant="contained" color="primary" disableElevation
                 onClick={(e) => this.handleEdit(e)} className="submit_1">
                  Submit
              </Button>}
            </div>
          </div>
        </form>}


    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Scid4_v1)
