import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import '../../../styles/assets/css/pages/stepper1.css'
import { scid_steps_v1 } from '../variables/assessments/scid_v1.js'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

class StepperSCID_v1 extends Component {
  handleStep = (step) => {
    if(step == 1) {
      window.location.href = "/admin/assessments/mini"+step+"/"+this.props.match.params.pid+"/1/-1"
    } else {
      window.location.href = "/admin/assessments/mini"+step+"/"+this.props.match.params.pid+"/-1"
    }
  }

  render() {
    return (
      <div className="small-12 columns stepper">
        <Stepper alternativeLabel nonLinear activeStep={this.props.activeStep}>
          {scid_steps_v1.map(each => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={each.value} {...stepProps}>
                <StepLabel {...labelProps} onClick={(e) => this.handleStep(each.value)} >
                {each.value == 1 &&
                <Link to={"/admin/assessments/mini"+each.value+"/"+this.props.match.params.pid+"/1/-1"}>{each.label}</Link>}
                {each.value > 1 &&
                <Link to={"/admin/assessments/mini"+each.value+"/"+this.props.match.params.pid+"/-1"}>{each.label}</Link>}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}
const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StepperSCID_v1)
