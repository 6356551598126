import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { calculateTimepoint3 } from '../../functions/timepoint.js'
import '../../../styles/assets/css/pages/assessments1.css'
import moment from 'moment'
import { IoIosSquare } from 'react-icons/io'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ScheduleIcon from '@material-ui/icons/Schedule'

class AssessmentList extends Component {
  constructor() {
      super()
      this.state = {
          message: '',
          timeline_start: '',
          now: Math.floor(Date.now() / 1000),
          diff_days: 0,
          mod_days: 0,
          status_assm: {},
          current_week: 0,
          current_month: 0,
          current_trimonth: 0,
          current_sixmonth: 0,
          current_year: 0,
          week_assm: [],
          month_assm: [],
          month_assm_unsc: [],
          trimonth_assm: [],
          trimonth_assm_unsc: [],
          sm_assm: [],
          year_assm: [],
          discharge_assm: [],
          self_stigma_assm: [],
          min_month: 0,
          min_trimonth: 0,
          min_year: 0,
      }
  }

  componentDidMount() {
    this.getTimelineStart()
  }

  getTimelineStart = async () => {
    var assm = []
    var assmM = []
    var assmTM = []
    var assmSM = []
    var assmY = []
    var status_assm = {}
    let toState = {}
    // Enable assm //
    await axios({
      method: 'post',
      url: connectNode + 'patient/general/get_enable_status_assm',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      status_assm = result['data']
    })
    // Timeline //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_timeline_start',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
      //console.log(result['data'])
      var calTimepoint = calculateTimepoint3(result['data'])
      //console.log(calTimepoint)
      /*for(var i=1; i<=calTimepoint.current_week+1; i++) {
        assm[i] = {phq4: 0, who5: 0}
      }*/
      for(var i=-1; i<=calTimepoint.current_month+1; i++) {
        assmM[i] = {phq9: 0, asmr: 0, gad7: 0, psqi: 0, who5: 0, providers: 0, mtf: 0, monthly_status: 0 }
      }
      for(var i=-1; i<=calTimepoint.current_trimonth+1; i++) {
        assmTM[i] = {providers: 0, pw18: 0}
      }
      for(var i=-1; i<=calTimepoint.current_sixmonth+1; i++) {
        assmSM[i] = {ders: 0}
      }
      for(var i=1; i<=calTimepoint.current_year+1; i++) {
        assmY[i] = {ders: 0}
      }

      toState = {
        timeline_start: result['data'].timeline_start,
        diff_days: calTimepoint.diff_days,
        mod_days: calTimepoint.mod_days,
        mod_months: calTimepoint.mod_months,
        mod_6m: calTimepoint.mod_6m,
        mod_years: calTimepoint.mod_years,
        current_week: calTimepoint.current_week,
        current_month: calTimepoint.current_month,
        current_trimonth: calTimepoint.current_trimonth,
        current_sixmonth: calTimepoint.current_sixmonth,
        current_year: calTimepoint.current_year,
        status_assm: status_assm,
        min_month: this.calculateFinishIn5days('m', result['data'].st_month, result['data'].start_month, result['data'].min_month), //result['data'].min_month < -1 ? result['data'].min_month - 1 : -9,
        min_trimonth: this.calculateFinishIn5days('tm', result['data'].st_trimonth, result['data'].start_trimonth, result['data'].min_trimonth), //result['data'].min_trimonth < -1 ? result['data'].min_trimonth - 1 : -9,
        min_year: this.calculateFinishIn5days('y', result['data'].st_year, result['data'].start_year, result['data'].min_year), //result['data'].min_year < -1 ? result['data'].min_year - 1 : -9,
      }
    })

    // --- Weekly --- //
    /*await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_list_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        current_timepoint: this.state.current_week,
        type_timepoint: 'w',
       }
    })
    .then((result) => {
      //console.log(result['data'])
      var tp = ''
      result['data'].map(each => {
        tp = each.timepoint
        if(each.phq4_id > 0) {
          assm[tp].phq4 = each.phq4_id
        }
        if(each.who5_id > 0) {
          assm[tp].who5 = each.who5_id
        }
      })
      toState.week_assm = assm
    })*/
    // --- Monthly --- //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_m_list_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        current_timepoint: this.state.current_month,
        type_timepoint: 'm',
       }
    })
    .then((result) => {
      var tp = ''
      result['data'].map(each => {
        tp = each.timepoint
        if(each.monthly_status && tp > 0) {
          assmM[tp].monthly_status = each.monthly_status
          //assm[tp] = {phq4: 0, who5: 0}
          if(each.phq9_id > 0) {
            assmM[tp].phq9 = each.phq9_id
          }
          if(each.asmr_id > 0) {
            assmM[tp].asmr = each.asmr_id
          }
          if(each.gad7_id > 0) {
            assmM[tp].gad7 = each.gad7_id
          }
          if(each.psqi_id > 0) {
            assmM[tp].psqi = each.psqi_id
          }
          if(each.who5_id > 0) {
            assmM[tp].who5 = each.who5_id
          }
          if(each.providers_end > 0) {
            assmM[tp].providers = each.uid
          }
          if(each.mtf_id > 0) {
            assmM[tp].mtf = each.mtf_id
          } else if(each.monthly_status == 2) {
            assmM[tp].mtf = 1
          }
        }
      })
      toState.month_assm = assmM

      var obj_assm = {}
      var buff_m_assm = []
      result['data'].map(each => {
        if(each.timepoint <= -9) {
          tp = each.timepoint
          obj_assm = {}
          obj_assm.tp = each.timepoint
          if(each.monthly_status) {
            //assmM[tp].monthly_status = each.monthly_status
            obj_assm.monthly_status = each.monthly_status
            obj_assm.start_date_time = each.start_date_time
            obj_assm.end_date_time = each.end_date_time
            if(each.phq9_id > 0) {
              //assmM[tp].phq9 = each.phq9_id
              obj_assm.phq9 = each.phq9_id
            }
            if(each.asmr_id > 0) {
              //assmM[tp].asmr = each.asmr_id
              obj_assm.asmr = each.asmr_id
            }
            if(each.gad7_id > 0) {
              //assmM[tp].gad7 = each.gad7_id
              obj_assm.gad7 = each.gad7_id
            }
            if(each.psqi_id > 0) {
              //assmM[tp].psqi = each.psqi_id
              obj_assm.psqi = each.psqi_id
            }
            if(each.who5_id > 0) {
              //assmM[tp].psqi = each.psqi_id
              obj_assm.who5 = each.who5_id
            }
            if(each.mtf_id > 0) {
              //assmM[tp].mtf = each.mtf_id
              obj_assm.mtf = each.mtf_id
            } else if(each.monthly_status == 2) {
              //assmM[tp].mtf = 1
              obj_assm.mtf = 1
            }
          }
          buff_m_assm.push(obj_assm)
        }

      })
      toState.month_assm_unsc = buff_m_assm

    })
    // --- Trimonthly --- //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_tm_list_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        current_timepoint: this.state.current_trimonth,
        type_timepoint: 'tm',
       }
    })
    .then((result) => {
      var tp = ''
      var obj_assm = {}
      var buff_tm_assm = []
      var buff_tm_assm_unsc = []
      result['data'].map(each => {
        tp = each.timepoint
        if(tp > 0) {
          if(each.pw18_id > 0) {
            assmTM[tp].pw18 = each.pw18_id
          }
          if(each.providers_id > 0) {
            //assmM[tp].psqi = each.psqi_id
            assmTM[tp].providers = each.providers_id
          }
          buff_tm_assm.push(assmTM)
        }
        if(tp <= -9) {
          obj_assm = {}
          obj_assm.tp = each.timepoint
          obj_assm.start_date_time = each.start_date_time
          obj_assm.end_date_time = each.end_date_time
          //assm[tp] = {phq4: 0, who5: 0}
          /*if(each.covid_id > 0) {
            obj_assm.covid = each.covid_id
          }*/
          if(each.pw18_id > 0) {
            //assmTM[tp].pw18 = each.pw18_id
            obj_assm.pw18 = each.pw18_id
          }
          if(each.providers_id > 0) {
            //assmTM[tp].pw18 = each.pw18_id
            obj_assm.providers = each.providers_id
          }
          buff_tm_assm_unsc.push(obj_assm)
        }
      })
      toState.trimonth_assm = assmTM
      toState.trimonth_assm_unsc = buff_tm_assm_unsc
    })
    // --- Six Month --- //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_sm_list_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        current_timepoint: this.state.current_sixmonth,
        type_timepoint: 'sm',
       }
    })
    .then((result) => {
      //console.log(result['data'])
      var tp = ''
      result['data'].map(each => {
        tp = each.timepoint
        //assm[tp] = {phq4: 0, who5: 0}
        if(each.ders_id > 0) {
          assmSM[tp].ders = each.ders_id
        }
      })
      toState.sm_assm = assmSM
    })
    // --- Yearly --- //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_y_list_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        current_timepoint: this.state.current_year,
        type_timepoint: 'y',
       }
    })
    .then((result) => {
      var tp = ''
      result['data'].map(each => {
        tp = each.timepoint
        //assm[tp] = {phq4: 0, who5: 0}
        if(each.ders_id > 0) {
          assmY[tp].ders = each.ders_id
        }
      })
      toState.year_assm = assmY
    })
    // --- Discharge --- //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_discharge_list_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        current_timepoint: this.state.current_month,
        type_timepoint: 'm',
       }
    })
    .then((result) => {
      toState.discharge_assm = result['data'][0]
    })
    // --- Self Stigma --- //
    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/check_self_stigma_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
       }
    })
    .then((result) => {
      toState.self_stigma_assm = result['data']
    })
    this.setState(toState)
  }

  calculateFinishIn5days = (type, status, start_timestamp, timepoint) => {
    var val = -9
    const now_timestamp = Math.floor(new Date().getTime()/1000)
    const num5days = 86400 * 5
    //console.log(type + ", " + status + ", " + timepoint + " : "+now_timestamp + " : " + start_timestamp + " , " + (parseFloat(start_timestamp) + parseFloat(num5days)))
    if(timepoint < -1) {
      if(status == 2) {
        val = timepoint - 1
      } else if(status == 1) {

        if(now_timestamp >= (parseFloat(start_timestamp) + parseFloat(num5days))) {
          val = timepoint - 1
        } else {
          val = timepoint
        }
      }
    }
    return val
  }

  addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  /*genColorStatusW = (current_week, mod_days, val, w, enable_status) => {
    //console.log(current_week, mod_days, val, w, enable_status)
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(w < current_week) {
        style = 'past_due each_assm1'
      } else {
        if(enable_status == 1) {
          if(mod_days >= 3) {
            style = 'due_soon each_assm1'
          } else if(mod_days <= 4){
            style = 'due_now each_assm1'
          } else {
            style = 'past_due each_assm1'
          }
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }*/

  genColorStatusM = (current_month, mod_months, val, m, enable_status, m_status) => {
    //console.log(current_month, mod_months, val, m, enable_status, m_status)
    var style = ''
    if(val > 0 || m_status == 2) {
      style = 'completed each_assm1'
    } else {
      if(m < current_month) {
        style = 'past_due each_assm1'
      } else {
        if(enable_status == 1) {
          if(mod_months >= -5 && mod_months <= 0) {
            style = 'due_soon each_assm1'
          } else if(mod_months > 0 && mod_months <= 5){
            style = 'due_now each_assm1'
          } else {
            style = 'past_due each_assm1'
          }
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorStatusTM = (current_month, mod_months, val, m) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(m < current_month) {
        style = 'past_due each_assm1'
      } else {
        if(mod_months >= -5 && mod_months <= 0) {
          style = 'due_soon each_assm1'
        } else if(mod_months > 0 && mod_months <= 5){
          style = 'due_now each_assm1'
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorStatusSM = (current_year, mod_years, val, m) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(m < current_year) {
        style = 'past_due each_assm1'
      } else {
        if(mod_years >= -5 && mod_years <= 0) {
          style = 'due_soon each_assm1'
        } else if(mod_years > 0 && mod_years <= 5){
          style = 'due_now each_assm1'
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorStatusY = (current_year, mod_years, val, m) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(m < current_year) {
        style = 'past_due each_assm1'
      } else {
        if(mod_years >= -5 && mod_years <= 0) {
          style = 'due_soon each_assm1'
        } else if(mod_years > 0 && mod_years <= 5){
          style = 'due_now each_assm1'
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorSelfStigma = (status, available_date_end) => {
    var now = new Date(),
    today = now.toISOString().substring(0,10)
    var style = ''
    if(status == 1) {
      style = 'completed each_assm1'
    } else {
      if(today <= available_date_end) {
        style = 'due_now each_assm1'
      } else {
        style = 'past_due each_assm1'
      }
    }
    return style
  }

  render() {
    var w_assm = this.state.week_assm
    var m_assm = this.state.month_assm
    var tm_assm = this.state.trimonth_assm
    var tm_assm_unsc = this.state.trimonth_assm_unsc
    var sm_assm = this.state.sm_assm
    var y_assm = this.state.year_assm

    var dateMoment = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    var dateMomentTM = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    var dateMoment6M = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    var dateMomentY = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    console.log(this.state)
    return (
      <div className="assessment_list box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          Questionnaires: Status
          </div>
          { typeof this.props.locationReducer.txt_message !== 'undefined' &&
            this.props.locationReducer.txt_message !== '' &&
            <div className={ (this.props.locationReducer.route === 'assessment_list_1' && "small-12 columns warning_msg8")
              || (this.props.locationReducer.route === 'assessment_list_2' && "small-12 columns warning_msg6")}>
              <div className="float_left1">
              { this.props.locationReducer.route === 'assessment_list_1' && <CheckCircleOutlineIcon />}
              { this.props.locationReducer.route === 'assessment_list_2' && <WarningIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
            </div>
          }
          <div className="small-12 columns mod_2">
            <span className="due_soon_color"><IoIosSquare /> Due soon</span>
            <span className="due_now_color"><IoIosSquare /> Due now</span>
            <span className="due_complete_color"><IoIosSquare /> Complete</span>
            <span className="due_past_color"><IoIosSquare /> Past Due</span>
          </div>
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-6 columns topic1">
                Monthly Questionnaires
              </div>
              <div className="small-6 columns" className="unscheduled_link">
                <a href={"/member/assessment/overview/m/"+(this.state.min_month)} >Add Unscheduled Questionaires (Complete in 5 days)</a>
              </div>
              <div className="small-12 columns month_scroll">
              { [...Array(this.state.current_month)].map((e, i) =>
                <div className="row each_month1" key={'m'+i} >
                  <div className="small-12 medium-4 columns each_assm_txt1 no_right_space1">
                    <CalendarTodayIcon /> <span className="space_right1">Month {i+1}</span>
                    <ScheduleIcon /> { dateMoment.add(1, 'M').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-12 medium-8 columns no-padding-right each_assm_link">
                    {((this.state.current_month == (i+1) && this.state.status_assm.m_phq9 == 1) || m_assm[i+1].phq9 > 0 ||
                      (this.state.current_month > (i+1) && m_assm[i+1].phq9 == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].phq9, (i+1), this.state.status_assm.m_phq9, this.state.status_assm.monthly_status) : ''}>
                    {m_assm.length > 0 && (m_assm[i+1].phq9 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_phq9 == 1)) &&
                      <a href={"/member/assessment/phq9/m/"+(i+1)}>PHQ-9</a>}
                    {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_phq9 == 0 && '-'}
                    {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].phq9 == 0 && 'PHQ-9'}
                    </div>}
                    {((this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 1) || m_assm[i+1].asmr > 0 ||
                      (this.state.current_month > (i+1) && m_assm[i+1].asmr == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].asmr, (i+1), this.state.status_assm.m_asrm, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].asmr > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 1)) &&
                        <a href={"/member/assessment/asrm/m/"+(i+1)}>ASMR</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].asmr == 0 && 'ASMR'}
                    </div>}
                    {((this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 1) || m_assm[i+1].gad7 > 0 ||
                      (this.state.current_month > (i+1) && m_assm[i+1].gad7 == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].gad7, (i+1), this.state.status_assm.m_gad7, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].gad7 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 1)) &&
                        <a href={"/member/assessment/gad7/m/"+(i+1)}>GAD-7</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].gad7 == 0 && 'GAD-7'}
                    </div>}
                    {((this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 1) || m_assm[i+1].psqi > 0 ||
                      (this.state.current_month > (i+1) && m_assm[i+1].psqi == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].psqi, (i+1), this.state.status_assm.m_psqi, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].psqi > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 1)) &&
                        <a href={"/member/assessment/psqi/m/"+(i+1)}>PSQI</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].psqi == 0 && 'PSQI'}
                    </div>}
                    {((this.state.current_month == (i+1) && this.state.status_assm.m_who5 == 1) || m_assm[i+1].who5 > 0 ||
                      (this.state.current_month > (i+1) && m_assm[i+1].who5 == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].who5, (i+1), this.state.status_assm.m_who5, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].who5 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_who5 == 1)) &&
                        <a href={"/member/assessment/who5/m/"+(i+1)}>WHO-5</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_who5 == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].who5 == 0 && 'WHO-5'}
                    </div>}
                    {((this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 1) || m_assm[i+1].mtf > 0 ||
                      (this.state.current_month > (i+1) && m_assm[i+1].mtf == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].mtf, (i+1), this.state.status_assm.m_mtf, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].mtf > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 1)) &&
                        <a href={"/member/assessment/mtf/m/"+(i+1)}>MTF</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 0 }
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].mtf == 0 && 'MTF'}
                    </div>}
                  </div>
                </div>
              ).reverse() }
              { this.state.month_assm_unsc.map((each, i) =>
                <div className="row each_month1" key={'m'+i} >
                  <div className="small-12 medium-4 columns each_assm_txt1 no_right_space1">
                    {each.tp > 0 && <><CalendarTodayIcon /> <span className="space_right1">Month {each.tp}</span></>}
                    {each.tp <= -9 && <><span className="space_right1">Unscheduled</span></>}
                    {each.tp > 0 && <><ScheduleIcon /> { moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY").add(each.tp, 'M').format('MM/DD/YYYY')}</>}
                    {each.tp <= -9 && <><ScheduleIcon /> {each.start_date_time}</>}
                  </div>
                  <div className="small-12 medium-8 columns no-padding-right each_assm_link">
                    {((this.state.current_month == (each.tp) && this.state.status_assm.m_phq9 == 1) || each.phq9 > 0 ||
                      (this.state.current_month > (i+1) && each.phq9 == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.phq9, each.tp, this.state.status_assm.m_phq9, this.state.status_assm.monthly_status) : ''}>
                    {m_assm.length > 0 && (each.phq9 > 0 || (this.state.current_month == each.tp && this.state.status_assm.m_phq9 == 1)) &&
                      <a href={"/member/assessment/phq9/m/"+each.tp}>PHQ-9</a>}
                    {m_assm.length > 0 && each.phq9 == 0 && 'PHQ-9'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_asrm == 1) || each.asmr > 0 ||
                      (this.state.current_month > each.tp && each.asmr == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.asmr, each.tp, this.state.status_assm.m_asrm, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (each.asmr > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 1)) &&
                        <a href={"/member/assessment/asrm/m/"+each.tp}>ASMR</a>}
                      {m_assm.length > 0 && each.asmr == 0 && 'ASMR'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_gad7 == 1) || each.gad7 > 0 ||
                      (this.state.current_month > each.tp && each.gad7 == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.gad7, each.tp, this.state.status_assm.m_gad7, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (each.gad7 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 1)) &&
                        <a href={"/member/assessment/gad7/m/"+each.tp}>GAD-7</a>}
                      {m_assm.length > 0 && each.gad7 == 0 && 'GAD-7'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_psqi == 1) || each.psqi > 0 ||
                      (this.state.current_month > each.tp && each.psqi == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.psqi, each.tp, this.state.status_assm.m_psqi, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (each.psqi > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 1)) &&
                        <a href={"/member/assessment/psqi/m/"+each.tp}>PSQI</a>}
                      {m_assm.length > 0 && each.psqi == 0 && 'PSQI'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_who5 == 1) || each.who5 > 0 ||
                      (this.state.current_month > each.tp && each.psqi == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.who5, each.tp, this.state.status_assm.m_who5, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (each.who5 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_who5 == 1)) &&
                        <a href={"/member/assessment/who5/m/"+each.tp}>WHO-5</a>}
                      {m_assm.length > 0 && each.psqi == 0 && 'WHO-5'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_mtf == 1) || each.mtf > 0 ||
                      (this.state.current_month > each.tp && each.mtf == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.mtf, each.tp, this.state.status_assm.m_mtf, this.state.status_assm.monthly_status) : ''}>
                      {m_assm.length > 0 && (each.mtf > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 1)) &&
                        <a href={"/member/assessment/mtf/m/"+each.tp}>MTF</a>}
                      {m_assm.length > 0 && each.mtf == 0 && 'MTF'}
                    </div>}
                  </div>
                </div>
              ) .reverse() }
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-6 columns topic1">
                Trimonthly Questionnaires
              </div>
              <div className="small-6 columns" className="unscheduled_link">
                <a href={"/member/assessment/pw18/tm/"+(this.state.min_trimonth)} >Add Unscheduled Questionaires (Complete in 5 days)</a>
              </div>
              <div className="small-12 columns month_scroll tri_month">
              {[...Array(this.state.current_trimonth)].map((e, i) =>
                <div className="row each_week1" key={'m'+i} >
                  <div className="small-12 medium-4 columns each_assm_txt1 no_right_space1">
                    <CalendarTodayIcon /> <span className="space_right2">Trimonth {i+1}</span>
                    <ScheduleIcon /> { dateMomentTM.add(3, 'M').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-12 medium-8 columns no-padding-right each_assm_link">
                    <div className={tm_assm.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, tm_assm[i+1].pw18, (i+1)) : ''}>
                      {tm_assm.length > 0 && (tm_assm[i+1].pw18 > 0 || (this.state.current_trimonth == (i+1) && this.state.status_assm.twelve_m_well_being == 1)) &&
                        <a href={"/member/assessment/pw18/tm/"+(i+1)}>Well-Being</a>}
                      {tm_assm.length > 0 && this.state.current_trimonth == (i+1) && this.state.status_assm.twelve_m_well_being == 0 && '-'}
                      {tm_assm.length > 0 && this.state.current_trimonth > (i+1) && tm_assm[i+1].pw18 == 0 && 'Well-Being'}
                    </div>
                    <div className={tm_assm.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, tm_assm[i+1].providers, (i+1)) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].providers > 0 || (this.state.current_trimonth == (i+1) && this.state.status_assm.m_providers == 1)) &&
                        <a href={"/member/assessment/providers/tm/"+(i+1)}>Providers</a>}
                      {m_assm.length > 0 && this.state.current_trimonth == (i+1) && this.state.status_assm.m_providers == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_trimonth > (i+1) && m_assm[i+1].providers == 0 && 'Providers'}
                    </div>
                  </div>
                </div>
              ).reverse() }
              { this.state.trimonth_assm_unsc.map((each, i) =>
                <div className="row each_week1" key={'m'+i} >
                  <div className="small-12 medium-4 columns each_assm_txt1 no_right_space1">
                    {each.tp > 0 && <><CalendarTodayIcon /> <span className="space_right1">Trimonth {each.tp}</span></>}
                    {each.tp <= -9 && <> <span className="space_right2">Unscheduled</span></>}
                    {each.tp > 0 && <><ScheduleIcon /> { dateMomentTM.add(each.tp * 3, 'M').format('MM/DD/YYYY')}</>}
                    {each.tp <= -9 && <><ScheduleIcon /> {each.start_date_time}</>}

                  </div>
                  <div className="small-12 medium-8 columns no-padding-right each_assm_link">
                    <div className={tm_assm_unsc.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, each.pw18, each.tp) : ''}>
                      {tm_assm_unsc.length > 0 && (each.pw18 > 0 || (this.state.current_trimonth == each.tp && this.state.status_assm.twelve_m_well_being == 1)) &&
                        <a href={"/member/assessment/pw18/tm/"+each.tp}>Well-Being</a>}
                      {tm_assm_unsc.length > 0 && each.pw18 == null && 'Well-Being'}
                    </div>
                    <div className={tm_assm_unsc.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, each.providers, each.tp) : ''}>
                      {tm_assm_unsc.length > 0 && (each.providers > 0 || (this.state.current_trimonth == each.tp && this.state.status_assm.m_providers == 1)) &&
                        <a href={"/member/assessment/providers/tm/"+each.tp}>Providers</a>}
                      {tm_assm_unsc.length > 0 && each.providers == null && 'Providers'}
                    </div>
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>

          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Six-month Questionnaires
              </div>

              <div className="small-12 columns month_scroll">
              { [...Array(this.state.current_sixmonth)].map((e, i) =>
                <div className="row each_week1" key={'y'+i} >
                  <div className="small-6 medium-4 columns each_assm_txt1">
                    <CalendarTodayIcon /> <span className="space_right1">Six month {i+1}</span>
                    <ScheduleIcon /> { dateMoment6M.add(6, 'months').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-6 medium-8 columns no-padding-right each_assm_link">
                    <div className={sm_assm.length > 0 ? this.genColorStatusSM(this.state.current_sixmonth, this.state.mod_6m, sm_assm[i+1].ders, (i+1)) : ''}>
                      <a href={"/member/assessment/ders18/sm/"+(i+1)}>DERS</a>
                    </div>
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>

          {this.state.current_year.length > 0 &&
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Yearly Questionnaires
              </div>
              <div className="small-12 columns month_scroll">
              { [...Array(this.state.current_year)].map((e, i) =>
                <div className="row each_week1" key={'y'+i} >
                  <div className="small-6 medium-4 columns each_assm_txt1">
                    <CalendarTodayIcon /> <span className="space_right1">Year {i+1}</span>
                    <ScheduleIcon /> { dateMomentY.add(12, 'months').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-6 medium-8 columns no-padding-right each_assm_link">
                    <div className={y_assm.length > 0 ? this.genColorStatusY(this.state.current_year, this.state.mod_years, y_assm[i+1].ders, (i+1)) : ''}>
                      <a href={"/member/assessment/ders18/y/"+(i+1)}>DERS</a>
                    </div>
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>}

          {this.state.self_stigma_assm[0] && this.state.self_stigma_assm[0].id>0 &&
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Self Stigma
              </div>

              <div className="small-12 columns month_scroll">
              { this.state.self_stigma_assm.map(each =>
                <div className="row each_week1" key={'ss'+each.id} >
                  <div className="small-6 medium-6 columns each_assm_txt1">
                    <ScheduleIcon /> { each.available_date_begin }
                  </div>
                  <div className="small-6 medium-6 columns no-padding-right each_assm_link">
                    <div className={this.genColorSelfStigma(each.sccs_status, each.available_date_end)}>
                      <a href={"/member/assessment/self_stigma/sccs/"+each.id}>SCCS</a>
                    </div>
                    <div className={this.genColorSelfStigma(each.ismi_status, each.available_date_end)}>
                      <a href={"/member/assessment/self_stigma/ismi/"+each.id}>ISMI</a>
                    </div>
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>}

          {this.state.status_assm.p_status == 16 &&
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Discharge Questionnaires
              </div>
              <div className="small-12 columns month_scroll">
                <div className="small-12 medium-10 columns no-padding-right each_assm_link">

                  <div className={(this.state.discharge_assm.phq9_id > 0 && 'completed each_assm1') ||
                  (this.state.discharge_assm.phq9_id == null && 'due_now each_assm1')}>
                  <a href={"/member/assessment/overview/m/-2"}>PHQ-9</a>
                  </div>

                  <div className={(this.state.discharge_assm.asmr_id > 0 && 'completed each_assm1') ||
                  (this.state.discharge_assm.asmr_id == null && 'due_now each_assm1')}>
                  <a href={"/member/assessment/asrm/m/-2"}>ASRM</a>
                  </div>

                  <div className={(this.state.discharge_assm.gad7_id > 0 && 'completed each_assm1') ||
                  (this.state.discharge_assm.gad7_id == null && 'due_now each_assm1')}>
                  <a href={"/member/assessment/gad7/m/-2"}>GAD-7</a>
                  </div>

                  <div className={(this.state.discharge_assm.psqi_id > 0 && 'completed each_assm1') ||
                  (this.state.discharge_assm.psqi_id == null && 'due_now each_assm1')}>
                  <a href={"/member/assessment/psqi/m/-2"}>PSQI</a>
                  </div>

                  <div className={(this.state.discharge_assm.who5_id > 0 && 'completed each_assm1') ||
                  (this.state.discharge_assm.who5_id == null && 'due_now each_assm1')}>
                  <a href={"/member/assessment/who5/m/-2"}>WHO-5</a>
                  </div>

                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList)
