import React from "react";
import Dashboard from "../components/patients/dashboard/dashboard"
import Profile from "../components/public/profile"
import Assessments from "../components/patients/assessments"

import PatientList from "../components/admin/patient/patient_list"
import WaitList from "../components/admin/patient/wait_list"
import PatientSearch from "../components/admin/patient/patient_search"
import ScreenQuestionaire from "../components/patients/enroll/screen_questionaire"
import AddPatient from "../components/admin/patient/add_patient"
import AddPatientWaitList from "../components/admin/patient/add_patient_waitlist"
import EditMRN from "../components/admin/patient/edit_mrn"
import EditPatientStatus from "../components/admin/patient/edit_patient_status"
import EditPatientWaitList from "../components/admin/patient/edit_patient_waitlist"
import EditPatientName from "../components/admin/patient/edit_patient_name"
import EditEmail from "../components/admin/patient/edit_email"
import PatientChart from "../components/admin/patient/patient_chart/patient_chart"
import PatientViewNote from "../components/admin/patient/patient_view_note"
import PatientMakeNote from "../components/admin/patient/patient_make_note"
import PatientEdit from "../components/admin/patient/patient_edit/patient_edit"
import PatientEditPrimaryClinician from "../components/admin/patient/patient_edit/patient_edit_primary_clinician"
import PatientEditPracStudent from "../components/admin/patient/patient_edit/patient_edit_prac_student"
//import PatientEditAssm from "../components/admin/patient/patient_edit/patient_edit_assm"

import PatientApptList from "../components/admin/patient/patient_chart/patient_appt_list"
import MakeAppointment from "../components/admin/appointment/make_appointment"
import EditAppointment from "../components/admin/appointment/edit_appointment"
import DailySelfMonitoring from "../components/admin/patient/contact_logs/patient_daily_self_monitor"
import PatientLogsOutcome from "../components/admin/patient/contact_logs/patient_logs_outcome"
import ViewAssessment from "../components/admin/patient/patient_chart/view_assessment"
import AssignTeam from "../components/admin/patient/patient_chart/assign_team2"
import ToDoChange from "../components/admin/patient/patient_chart/to_do_list/to_do_change"
import NewTreatmentPlan from "../components/admin/patient/patient_chart/treatment_plan/new_treatment_plan"
import TreatmentPlan from "../components/admin/patient/patient_chart/treatment_plan/treatment_plan"
import TreatmentTargetEdit from "../components/admin/patient/patient_chart/treatment_plan/treatment_target_edit"
import TreatmentPlanHistoryLog from "../components/admin/patient/patient_chart/treatment_plan/treatment_plan_history_log"
import NewTreatmentTarget from "../components/admin/patient/patient_chart/treatment_plan/new_treatment_target"
import NewToDoList from "../components/admin/patient/patient_chart/treatment_plan/new_to_do_list"
import ToDoListEdit from "../components/admin/patient/patient_chart/treatment_plan/to_do_list_edit"
import TeamNotes from "../components/admin/patient/patient_chart/treatment_plan/team_notes"
import ClinicalNotesDetail from "../components/admin/patient/patient_chart/integrations/clinical_notes_detail"

import Mini1 from "../components/admin/assessments/mini1"
import Mini2 from "../components/admin/assessments/mini2"
import Mini3 from "../components/admin/assessments/mini3"
import Mini4 from "../components/admin/assessments/mini4"
import Mini5 from "../components/admin/assessments/mini5"
import Mini6 from "../components/admin/assessments/mini6"
import Mini7 from "../components/admin/assessments/mini7"
import Mini8 from "../components/admin/assessments/mini8"
import Mini9 from "../components/admin/assessments/mini9"
import Mini10 from "../components/admin/assessments/mini10"
import Mini11 from "../components/admin/assessments/mini11"
import Complete from "../components/admin/assessments/complete"
import MiniResult from "../components/admin/assessments/mini_result"
import CgiBD from "../components/admin/assessments/cgi_bd"
import CgiBDview from "../components/admin/assessments/cgi_bd_view"

import Scid1 from "../components/admin/assessments/scid1"
import Scid2 from "../components/admin/assessments/scid2"
import Scid3 from "../components/admin/assessments/scid3"
import Scid4 from "../components/admin/assessments/scid4"
import Scid5 from "../components/admin/assessments/scid5"
import Scid6 from "../components/admin/assessments/scid6"
import Scid7 from "../components/admin/assessments/scid7"
import ScidResult from "../components/admin/assessments/scid_result"

import Scid1_v1 from "../components/admin/assessments/scid1_v1"
import Scid2_v1 from "../components/admin/assessments/scid2_v1"
import Scid3_v1 from "../components/admin/assessments/scid3_v1"
import Scid4_v1 from "../components/admin/assessments/scid4_v1"
import Scid5_v1 from "../components/admin/assessments/scid5_v1"
import Scid6_v1 from "../components/admin/assessments/scid6_v1"
import ScidV1Result from "../components/admin/assessments/scid_v1_result"

import SelfStigmaList from "../components/admin/patient/self_stigma/self_stigma_list"
import NewSelfStigma from "../components/admin/patient/self_stigma/new_self_stigma"
import EditSelfStigma from "../components/admin/patient/self_stigma/edit_self_stigma"
import SelfStigmaTeamList from "../components/admin/patient/self_stigma/self_stigma_team_list"
import ViewAssessmentSelfStigma from "../components/admin/patient/patient_chart/view_assessment_self_stigma"

import CgiBDReport from "../components/admin/patient/reports/cgi_bd"
import CgiBdCn from "../components/admin/patient/reports/cgi_bd_cn"
import CgiBDGroup1List from "../components/admin/patient/reports/cgi_bd_group1_list"

import WorksheetList from "../components/admin/patient/worksheets/worksheet_list"
import NewWorksheet from "../components/admin/patient/worksheets/new_worksheet"

import PatientContract from "../components/patients/patient_contract/patient_contract"
import PatientContractUpdate from "../components/patients/patient_contract/patient_contract_update"
import PatientContractPDF from "../components/patients/patient_contract/patient_contract_pdf"
import PatientContractView from "../components/patients/patient_contract/patient_contract_view"
import FilePDF from "../components/admin/patient/patient_chart/file_pdf"
import ConsentPDF from "../components/admin/patient/patient_chart/consent_pdf"

import ChangeLog from "../components/admin/changelog"
import PatientCleanData from "../components/admin/patient/patient_clean_data"

import CourseMain from "../components/patients/courses/course_main"
import CourseSession from "../components/patients/courses/course_session"
import CourseLibrary from "../components/patients/courses/course_library"
import CoursePersonas from "../components/patients/courses/course_personas"

import UserGuidePDF from "../components/admin/user_guide/user_guide_pdf"

const ADMIN_ROUTES = [
  {   path: "/dashboard",
      key: "dashboard",
      title: "Dashboard",
      exact: true,
      requireAuth: true,
      component: () => <Dashboard />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/profile",
      key: "profile",
      title: "Profile",
      exact: true,
      requireAuth: true,
      component: () => <Profile />,
      showtitle: 'true',
      ficon: 'fi-bookmark',
  },
  {   path: "/assessments",
      key: "assessments",
      title: "Assessments",
      exact: true,
      requireAuth: true,
      component: () => <Assessments />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/wait_list",
      key: "wait_list",
      title: "Wait List",
      exact: true,
      requireAuth: true,
      component: () => <WaitList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_list",
      key: "patient_list",
      title: "Patient List",
      exact: true,
      requireAuth: true,
      component: () => <PatientList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_search",
      key: "patient_search",
      title: "Patient Search",
      exact: true,
      requireAuth: true,
      component: () => <PatientSearch />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/add_patient",
      key: "add_patient",
      title: "Add New Patient",
      exact: true,
      requireAuth: true,
      component: () => <AddPatient />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/add_patient_waitlist",
      key: "add_patient_waitlist",
      title: "Add New Patient",
      exact: true,
      requireAuth: true,
      component: () => <AddPatientWaitList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/screen_questionaire",
      key: "screen_questionaire",
      title: "Patient Screen Questionaire",
      exact: true,
      requireAuth: true,
      component: () => <ScreenQuestionaire />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  // Patient Edit //
  {   path: "/admin/patient_edit/:pid",
      key: "patient_edit",
      title: "Patient Edit",
      exact: true,
      requireAuth: true,
      component: () => <PatientEdit />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/edit_mrn/:pid",
      key: "edit_mrn",
      title: "Edit Patient MRN",
      exact: true,
      requireAuth: true,
      component: () => <EditMRN />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/edit_patient_status/:pid",
      key: "edit_patient_status",
      title: "Edit Patient Status",
      exact: true,
      requireAuth: true,
      component: () => <EditPatientStatus />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/edit_patient_waitlist/:pid",
      key: "edit_patient_waitlist",
      title: "Edit Patient Wait List",
      exact: true,
      requireAuth: true,
      component: () => <EditPatientWaitList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/edit_patient_name/:pid",
      key: "edit_patient_name",
      title: "Edit Patient Name",
      exact: true,
      requireAuth: true,
      component: () => <EditPatientName />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/edit_email/:pid",
      key: "edit_email",
      title: "Edit Patient Email",
      exact: true,
      requireAuth: true,
      component: () => <EditEmail />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_edit_primary_clinician/:pid",
      key: "patient_edit_primary_clinician",
      title: "Patient Edit Primary Clinician",
      exact: true,
      requireAuth: true,
      component: () => <PatientEditPrimaryClinician />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_edit_prac_student/:pid",
      key: "patient_edit_prac_student",
      title: "Patient Edit Practicum Student",
      exact: true,
      requireAuth: true,
      component: () => <PatientEditPracStudent />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  /*{   path: "/admin/patient_edit_assm/:pid",
      key: "patient_edit_assm",
      title: "Patient Edit Assessments",
      exact: true,
      requireAuth: true,
      component: () => <PatientEditAssm />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },*/
  {   path: "/admin/patient_contact_log/:pid",
      key: "patient_edit_assm",
      title: "Patient Logs Outcome",
      exact: true,
      requireAuth: true,
      component: () => <PatientLogsOutcome />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_log_outcome/:pid/:appt_id/:ct_id",
      key: "patient_edit_assm",
      title: "Patient Logs Outcome",
      exact: true,
      requireAuth: true,
      component: () => <PatientLogsOutcome />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  // Close Patient Edit //
  {   path: "/admin/patient_chart/:pid",
      key: "patient_chart",
      title: "Patient Chart",
      exact: true,
      requireAuth: true,
      component: () => <PatientChart />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_chart/:pid/:maintab/:secondtab",
      key: "patient_chart",
      title: "Patient Chart",
      exact: true,
      requireAuth: true,
      component: () => <PatientChart />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_appt_list/:pid",
      key: "patient_appt_list",
      title: "Patient Appointment",
      exact: true,
      requireAuth: true,
      component: () => <PatientApptList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/make_appointment/:pid",
      key: "make_appointment",
      title: "Patient Make Appointment",
      exact: true,
      requireAuth: true,
      component: () => <MakeAppointment />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/edit_appointment/:aid/:pid",
      key: "edit_appointment",
      title: "Patient Edit Appointment",
      exact: true,
      requireAuth: true,
      component: () => <EditAppointment />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment/assign_team/:pid",
      key: "assign_team",
      title: "Clinician/Student/PN",
      exact: true,
      requireAuth: true,
      component: () => <AssignTeam />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/new_team_notes/:pid",
      key: "new_team_notes",
      title: "New Team Meeting Note",
      exact: true,
      requireAuth: true,
      component: () => <TeamNotes />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/clinical_note/:pid/:param/:type/:cid/:appt_date",
      key: "clinical_note",
      title: "Clinical Note Details",
      exact: true,
      requireAuth: true,
      component: () => <ClinicalNotesDetail />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/new_treatment_plan/:pid",
      key: "new_treatment_plan",
      title: "Patient New Treatment Plan",
      exact: true,
      requireAuth: true,
      component: () => <NewTreatmentPlan />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/treatment_plan/:pid/:tid",
      key: "treatment_plan",
      title: "Patient Treatment Plan",
      exact: true,
      requireAuth: true,
      component: () => <TreatmentPlan />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/treatment_target_edit/:pid/:tid/:tg_id",
      key: "treatment_target_edit",
      title: "Patient Treatment Plan",
      exact: true,
      requireAuth: true,
      component: () => <TreatmentTargetEdit />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/to_do_list_edit/:pid/:tid/:mid",
      key: "to_do_list_edit",
      title: "To Do List Item",
      exact: true,
      requireAuth: true,
      component: () => <ToDoListEdit />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/treatment_plan_history_log/:pid/:tpid",
      key: "new_treatment_plan",
      title: "Patient Treatment History Log",
      exact: true,
      requireAuth: true,
      component: () => <TreatmentPlanHistoryLog />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/new_treatment_target/:pid/:tpid",
      key: "new_treatment_target",
      title: "Patient New Treatment Target",
      exact: true,
      requireAuth: true,
      component: () => <NewTreatmentTarget />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/treatment_plan/new_to_do_list/:pid/:tpid",
      key: "new_to_do_list",
      title: "Patient New To Do List",
      exact: true,
      requireAuth: true,
      component: () => <NewToDoList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_view_note/:pid",
      key: "patient_view_note",
      title: "Patient View Note",
      exact: true,
      requireAuth: true,
      component: () => <PatientViewNote />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_make_note/:pid",
      key: "patient_make_note",
      title: "Patient Make Note",
      exact: true,
      requireAuth: true,
      component: () => <PatientMakeNote />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/change_to_do_list/:pid/:td_id",
      key: "change_to_do_list",
      title: "Change TO Do List",
      exact: true,
      requireAuth: true,
      component: () => <ToDoChange />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_daily_self_monitor/:pid/:appt_id/:timepoint",
      key: "patient_daily_self_monitor",
      title: "Patient Daily Self Monitor",
      exact: true,
      requireAuth: true,
      component: () => <DailySelfMonitoring />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/view_assessment/:pid/:assm/:time_type/:timepoint",
      key: "view_assessment",
      title: "View Assessment",
      exact: true,
      requireAuth: true,
      component: () => <ViewAssessment />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini_result/:pid/:timepoint",
      key: "mini result",
      title: "MINI Result",
      exact: true,
      requireAuth: true,
      component: () => <MiniResult />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/v/mini/:pid/:timepoint",
      key: "mini",
      title: "MINI",
      exact: true,
      requireAuth: true,
      component: () => <MiniResult />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid1/:pid/:appt_id/:timepoint",
      key: "scid 1",
      title: "SCID1",
      exact: true,
      requireAuth: true,
      component: () => <Scid1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid2/:pid/:timepoint",
      key: "scid 2",
      title: "SCID2",
      exact: true,
      requireAuth: true,
      component: () => <Scid2 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid3/:pid/:timepoint",
      key: "scid 3",
      title: "SCID3",
      exact: true,
      requireAuth: true,
      component: () => <Scid3 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid4/:pid/:timepoint",
      key: "scid 4",
      title: "SCID4",
      exact: true,
      requireAuth: true,
      component: () => <Scid4 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid5/:pid/:timepoint",
      key: "scid 5",
      title: "SCID5",
      exact: true,
      requireAuth: true,
      component: () => <Scid5 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid6/:pid/:timepoint",
      key: "scid 6",
      title: "SCID6",
      exact: true,
      requireAuth: true,
      component: () => <Scid6 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid7/:pid/:timepoint",
      key: "scid 7",
      title: "SCID7",
      exact: true,
      requireAuth: true,
      component: () => <Scid7 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid_result/:pid/:timepoint",
      key: "scid result",
      title: "SCID Result",
      exact: true,
      requireAuth: true,
      component: () => <ScidResult />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid_v1_result/:pid/:timepoint",
      key: "scid result",
      title: "SCID Result",
      exact: true,
      requireAuth: true,
      component: () => <ScidV1Result />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid1_v1/:pid/:appt_id/:timepoint",
      key: "scid 1",
      title: "SCID1",
      exact: true,
      requireAuth: true,
      component: () => <Scid1_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid2_v1/:pid/:timepoint",
      key: "scid 2",
      title: "SCID2",
      exact: true,
      requireAuth: true,
      component: () => <Scid2_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid3_v1/:pid/:timepoint",
      key: "scid 3",
      title: "SCID3",
      exact: true,
      requireAuth: true,
      component: () => <Scid3_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid4_v1/:pid/:timepoint",
      key: "scid 4",
      title: "SCID4",
      exact: true,
      requireAuth: true,
      component: () => <Scid4_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid5_v1/:pid/:timepoint",
      key: "scid 5",
      title: "SCID5",
      exact: true,
      requireAuth: true,
      component: () => <Scid5_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/scid6_v1/:pid/:timepoint",
      key: "scid 6",
      title: "SCID6",
      exact: true,
      requireAuth: true,
      component: () => <Scid6_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid1_v1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid1_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid2_v1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid2_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid3_v1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid3_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid4_v1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid4_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid5_v1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid5_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid6_v1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid6_v1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid1/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid2/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid2 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid3/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid3 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid4/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid4 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid5/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid5 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid6/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid6 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_scid7/:pid/:task",
      key: "scid_edit",
      title: "SCID Edit",
      exact: true,
      requireAuth: true,
      component: () => <Scid7 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini1/:pid/:appt_id/:timepoint",
      key: "mini 1",
      title: "MINI 1",
      exact: true,
      requireAuth: true,
      component: () => <Mini1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini2/:pid/:timepoint",
      key: "mini 2",
      title: "MINI 2",
      exact: true,
      requireAuth: true,
      component: () => <Mini2 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini3/:pid/:timepoint",
      key: "mini 3",
      title: "MINI 3",
      exact: true,
      requireAuth: true,
      component: () => <Mini3 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini4/:pid/:timepoint",
      key: "mini 4",
      title: "MINI 4",
      exact: true,
      requireAuth: true,
      component: () => <Mini4 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini5/:pid/:timepoint",
      key: "mini 5",
      title: "MINI 5",
      exact: true,
      requireAuth: true,
      component: () => <Mini7 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini6/:pid/:timepoint",
      key: "mini 6",
      title: "MINI 6",
      exact: true,
      requireAuth: true,
      component: () => <Mini8 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini7/:pid/:timepoint",
      key: "mini 7",
      title: "MINI 7",
      exact: true,
      requireAuth: true,
      component: () => <Mini10 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini8/:pid/:timepoint",
      key: "mini 11",
      title: "MINI 11",
      exact: true,
      requireAuth: true,
      component: () => <Mini11 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/mini9/:pid/:timepoint",
      key: "mini 9",
      title: "MINI 9",
      exact: true,
      requireAuth: true,
      component: () => <Mini9 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/complete/:pid/:timepoint",
      key: "complete",
      title: "Complete",
      exact: true,
      requireAuth: true,
      component: () => <Complete />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini1/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini1 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini2/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini2 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini3/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini3 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini4/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini4 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini5/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini7 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini6/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini8 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini7/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini10 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini8/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini11 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/edit_mini9/:pid/:task",
      key: "mini_edit",
      title: "MINI Edit",
      exact: true,
      requireAuth: true,
      component: () => <Mini9 />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/cgi_bd/:pid",
      key: "cgi_bd",
      title: "CGI-BD",
      exact: true,
      requireAuth: true,
      component: () => <CgiBD />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/cgi_bd/view/:pid/:id",
      key: "cgi_bd",
      title: "CGI-BD",
      exact: true,
      requireAuth: true,
      component: () => <CgiBDview />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  // Patient Contract //
  {   path: "/admin/patient_contract/new_contract/:pid",
      key: "new patient contract",
      title: "New Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_contract/edit_patient_contract/:pid/:contract_id/new",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/admin/patient_contract/edit_patient_contract/:pid/:contract_id",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/admin/patient_contract/update_contract/:pid/:contract_id",
      key: "update patient contract",
      title: "Update Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractUpdate />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_contract/view_contract/:pid/:contract_id",
      key: "view patient contract",
      title: "View Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractView />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_contract/patient_contract_pdf/:pid/:contract_id",
      key: "patientcontractpdf",
      title: "Patient Contract PDF",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractPDF />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/admin/consent/:consent/:pid",
      key: "consent",
      title: "Patient Consent",
      exact: true,
      requireAuth: true,
      component: () => <ConsentPDF />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  // MRTF //
  /*{   //path: "/admin/patient_medication/mrtf/:pid/:timepoint/:med_id",
      path: "/admin/patient_medication/mrtf/:pid/:med_id",
      key: "mrtf",
      title: "MRTF",
      exact: true,
      requireAuth: true,
      component: () => <Mrtf />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_medication/add_mrtf/:pid",
      key: "add_mrtf",
      title: "AddMRTF",
      exact: true,
      requireAuth: true,
      component: () => <AddMrtf />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },*/
  {   path: "/changelog",
      key: "changelog",
      title: "changelog",
      exact: true,
      requireAuth: false,
      component: () => <ChangeLog />,
      showtitle: 'true',
  },
  {   path: "/admin/patient_chart/file/:fid",
      key: "patient_chart_file",
      title: "File",
      exact: true,
      requireAuth: true,
      component: () => <FilePDF />,
      showtitle: 'true',
  },
  /* Self Stigma */
  {   path: "/admin/self_stigma/self_stigma_list",
      key: "self_stigma_list",
      title: "Self Stigma",
      exact: true,
      requireAuth: true,
      component: () => <SelfStigmaList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/self_stigma/new_self_stigma",
      key: "new_self_stigma",
      title: "New Self Stigma Assessments",
      exact: true,
      requireAuth: true,
      component: () => <NewSelfStigma />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/self_stigma/edit_self_stigma/:assm_id",
      key: "edit_self_stigma",
      title: "Self Stigma Assessments",
      exact: true,
      requireAuth: true,
      component: () => <EditSelfStigma />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/self_stigma/self_stigma_team_list/:assm_id",
      key: "self_stigma_team_list",
      title: "Self Stigma",
      exact: true,
      requireAuth: true,
      component: () => <SelfStigmaTeamList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/assessments/view_assessment_self_stigma/:assm/:pid/:assm_id",
      key: "view_assessment_self_stigma",
      title: "View Assessment",
      exact: true,
      requireAuth: true,
      component: () => <ViewAssessmentSelfStigma />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/member/intervention/:cid/main",
      key: "intervention",
      title: "Intervention",
      exact: true,
      requireAuth: true,
      component: () => <CourseMain />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/library",
      key: "library",
      title: "Library",
      exact: true,
      requireAuth: true,
      component: () => <CourseLibrary />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/personas",
      key: "personas",
      title: "Personas",
      exact: true,
      requireAuth: true,
      component: () => <CoursePersonas />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/session/:session/:sub_session/:sub_session_page",
      key: "intervention",
      title: "Intervention",
      exact: true,
      requireAuth: true,
      component: () => <CourseSession />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/admin/user_guide",
      key: "user_guide",
      title: "User Guide",
      exact: true,
      requireAuth: true,
      component: () => <UserGuidePDF />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  /* Reports */
  {   path: "/admin/reports/cgi_bd",
      key: "cgi_bd_report",
      title: "CGI-BD Report",
      exact: true,
      requireAuth: true,
      component: () => <CgiBDReport />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/reports/cgi_bd_cn/:cid",
      key: "cgi_bd_cn",
      title: "CGI-BD",
      exact: true,
      requireAuth: true,
      component: () => <CgiBdCn />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/reports/cgi_bd_group1_list",
      key: "cgi_bd_group1_list",
      title: "CGI-BD Report",
      exact: true,
      requireAuth: true,
      component: () => <CgiBDGroup1List />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/clean_data/:type/:pid",
      key: "clean_data",
      title: "Clean Data",
      exact: true,
      requireAuth: true,
      component: () => <PatientCleanData />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  /* Worksheets */
  {   path: "/admin/worksheets/worksheet_list",
      key: "worksheet_list",
      title: "Worksheets",
      exact: true,
      requireAuth: true,
      component: () => <WorksheetList />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/worksheets/new_worksheet",
      key: "new_worksheet",
      title: "Worksheets",
      exact: true,
      requireAuth: true,
      component: () => <NewWorksheet />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
];

export default ADMIN_ROUTES;
