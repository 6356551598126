import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { gad7_1, gad7_2, gad7_questions } from '../variables/assessments/gad7.js'
import { calculateTimepoint1 } from '../../functions/timepoint.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import StepperAssm from './stepper_assm'
import CancelIcon from '@material-ui/icons/Cancel'

class Providers extends Component {
  constructor() {
      super()
      this.state = {
          status: 1,
          message: '',
          tracker_steps: 9,
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'complete_assessments',
          pcp_name: '',
          pcp_contact: '',
          therapist_name: '',
          therapist_contact: '',
          therapist_list: [{ id: 1, name: '', contact: '' }],
          prescriber_name: '',
          prescriber_contact: '',
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint

    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_assessments_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid:  this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      var next_step = 'complete_assessments'
      this.setState({
        next_step: next_step,
      })
    })
    this.getCompletedData(1)

    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/chk_each_assm2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: this.props.match.params.type,
        timepoint: this.props.match.params.timepoint,
        assm: 'providers',
       }
    })
    .then((result) => {
      var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
      if(this.props.match.params.timepoint > calTimepoint.current_month) {
        this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
        this.props.history.push('/member/assessment_list')
      } else if((this.props.match.params.timepoint == calTimepoint.current_trimonth) ||
                (this.props.match.params.timepoint <= -9)) {
        // Current timepoint => Fill the form //
        if(result['data'].m_providers == 0) {
          axios({
            method: 'post',
            url: connectNode + 'patient/assessment/update_skip_step',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: this.props.authReducer.uid,
              type: this.props.match.params.type,
              timepoint: this.props.match.params.timepoint,
              update_step: 'complete_assessments',
             }
          })
          .then((result) => {
            this.props.history.push('/member/assessment/complete_assessments/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          })
        }

        //console.log(result['data'])
        if(result['data'].status !== null) {
          if(result['data'].status == 2) {
            // Completed //
            this.getCompletedData(2)
          } else {
            if(result['data'].status == 1 && result['data'].step !== 'providers') {
              this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            }
          }
        } else {
          // Back up overview //
          this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
        }
      } else {
        // Past timepoint //
        this.getCompletedData(3)
      }
    })
  }

  getCompletedData = (set_status) => {
    //console.log(set_status)
    var gType = ''
    var gTimepoint = 0
    if(set_status == 2 || set_status == 3) {
      gType = this.props.match.params.type
      gTimepoint = this.props.match.params.timepoint
    }
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_providers',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: gType,
        timepoint: gTimepoint,
        frm: 'providers',
       }
    }).then((result) => {
      this.setState({
        assm_status: set_status,
        status: set_status,
        tracker_steps: 8,
        pcp_name: result['data'][0].pcp_name,
        pcp_contact: result['data'][0].pcp_contact,
        prescriber_name: result['data'][0].prescriber_name,
        prescriber_contact: result['data'][0].prescriber_contact,
        therapist_list: this.genTherapists(result['data'][0]),
      })
    })
  }

  genTherapists = (dataList) => {
    var genData = []
    var buff = ''
    if(dataList.therapists) {
      buff = dataList.therapists.split("##")
      buff.map(each => {
        if(each) {
          buff = each.split("::")
          genData.push({
            therapist_name: buff[0].trim(),
            therapist_contact: buff[1].trim(),
          })
        }
      })
    }
    if(genData.length == 0) {
      genData.push({
        therapist_name: '',
        therapist_contact: '',
      })
    }
    return genData
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange4 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange5_2 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    if(getStateVal[id][field] != null || getStateVal[id][field] != undefined) {
      this.setState({
        [name]: getStateVal
      })
    }
  }

  appendInputTherapist = (name, fields) => {
    var newInput = fields[fields.length-1]
    var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, name: '', contact: '',
        }])
      }
    ))
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = this.state.next_step
    if(this.state.status === 1) {
      if(this.state.pcp_name.trim().length == 0 || this.state.pcp_contact.trim().length == 0){
          this.setState({
            message: 'Please provide your PCP information',
            invalid: true,
          })
          window.scrollTo(0, 0)
      } else if(this.state.prescriber_name.trim().length == 0){
          this.setState({
            message: 'Please provide your medication prescriber information',
            invalid: true,
          })
          window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/update_providers',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            pid:  this.props.authReducer.uid,
            timepoint: this.props.match.params.timepoint,
            type: this.props.match.params.type,
          }
        })
        .then((result) => {
          if(result['data'] === next_step) {
            this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
            this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
          }
        })
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {
    //console.log(this.state)
    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Providers
          </div>
          {//this.state.status == 1 &&
          <StepperAssm activeStep={8} assm={'Providers'} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
        <div className="small-12 columns box2_2">
          Below are the names and contact information of your providers from registration.
          If there have been any changes, please update the information below.
          If there have not been any changes, click NEXT.
        </div>
        <div className="small-12 columns box1">
          <div className="title_topic1">

          Primary care provider (PCP)</div>
          <div className="small-12 columns q_pcp">
            <div className="row  ">
              <div className="small-12 medium-2 columns bold top_space1 align_right">
                Name:
              </div>
              <div className="small-12 medium-10 columns">
              <TextField label="" variant="outlined" value={this.state.pcp_name}
              InputProps={{ inputProps: { name: "pcp_name", maxLength: 50 }}} name="pcp_name"
              onChange={(e) => this.handleChange4(e, 'pcp_name')} />
              </div>
            </div>
            <div className="row top_space1">
              <div className="small-12 medium-2 columns bold top_space1 align_right">
                Email/Phone:
              </div>
              <div className="small-12 medium-10 columns">
              <TextField label="" variant="outlined" value={this.state.pcp_contact}
              InputProps={{ inputProps: { name: "pcp_contact", maxLength: 100 }}} name="pcp_contact"
              onChange={(e) => this.handleChange4(e, 'pcp_contact')} />
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns box1">
          <div className="small-12 columns title_topic1 top_space1">Medication prescriber if not PCP (psychiatrist, psychopharmacologist or psychiatric nurse practitioner)</div>
          <div className="small-12 columns q_pcp">
            <div className="row  ">
              <div className="small-12 medium-2 columns bold top_space1 align_right">
                Name:
              </div>
              <div className="small-12 medium-10 columns">
              <TextField label="" variant="outlined" value={this.state.prescriber_name}
              InputProps={{ inputProps: { name: "prescriber_name", maxLength: 50 }}} name="prescriber_name"
              onChange={(e) => this.handleChange4(e, 'prescriber_name')} />
              </div>
            </div>
            <div className="row  ">
              <div className="small-12 medium-2 columns bold top_space1 align_right">
                Email/Phone:
              </div>
              <div className="small-12 medium-10 columns">
              <TextField label="" variant="outlined" value={this.state.prescriber_contact}
              InputProps={{ inputProps: { name: "prescriber_contact", maxLength: 100 }}} name="prescriber_contact"
              onChange={(e) => this.handleChange4(e, 'prescriber_contact')} />
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns box1">
          <div className="small-12 columns title_topic1 top_space1">Therapist outside of FITT-BD</div>
          {this.state.therapist_list.map((val, index) =>
          <div className="small-12 columns q_pcp">
            <div className="row">
              <div className="small-12 medium-2 columns bold top_space1 align_right">
                Name:
              </div>
              <div className="small-12 medium-9 columns">
              <TextField label="" variant="outlined"
              InputProps={{ inputProps: { maxLength: 50 }}}
              onChange={(e) => this.handleChange5_2(e, 'therapist_list', index, 'therapist_name')}
              variant="outlined" value={val.therapist_name} />
              </div>
              <div className="small-12 medium-1 columns">&nbsp;</div>
            </div>
            <div className="row top_space1">
              <div className="small-12 medium-2 columns bold top_space1 align_right">
                Email/Phone:
              </div>
              <div className="small-12 medium-9 columns">
              <TextField label="" variant="outlined"
              InputProps={{ inputProps: { maxLength: 100 }}}
              onChange={(e) => this.handleChange5_2(e, 'therapist_list', index, 'therapist_contact')}
              variant="outlined" value={val.therapist_contact} />
              </div>
              <div className="small-12 medium-1 columns align_left">
              {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('therapist_list', val.id, this.state.therapist_list) } />}
              </div>
              <div className="small-12 columns">
                <div className="sep_line2">&nbsp;</div>
              </div>
            </div>
          </div>)}
          <div className="small-12 columns top_space1">
            <button type="button" className="addInput1"
               onClick={ () => this.appendInputTherapist('therapist_list', this.state.therapist_list) }>
                Add Therapist
            </button>
          </div>
        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Providers)
