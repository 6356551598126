import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, NavLink } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PatientHead3 from "../../patient/patient_head3"
import ReactHtmlParser from 'react-html-parser'

class EditWorksheet extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          name: '',
          selectedFile: '',
          category: 0,
          categoryList: [],
          dataList: [],
          editMode: false,
          wid: 0,
          file_name: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/worksheets/get_category_list_w_edit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { wid: this.props.match.params.wid }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          categoryList: result['data'][0].value,
          name: result['data'][1].value[0].name,
          category: result['data'][1].value[0].category_id,
          wid: result['data'][1].value[0].id,
          file_name: result['data'][1].value[0].original_file_name,
        })
      }
    })

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChangeFile = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.selectedFile == null || this.state.category == 0 || this.state.name.trim() == '') {
      this.setState({
        msg_status: false,
        message: 'Please fill all information',
      })
    } else if(this.state.selectedFile.type != 'application/pdf') {
      console.log(this.state)
      this.setState({
        msg_status: false,
        message: 'Please upload PDF file format',
      })
    } else {
      const formData = new FormData()
      var dataToSubmit = this.state

      formData.append('file', this.state.selectedFile)
      formData.append('udata', JSON.stringify(dataToSubmit))
      axios({
        method: 'post',
        url: connectNode + 'admin/worksheets/edit_worksheet',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      })
      .then((result) => {
        if(result['data'] == this.state.uid) {
          this.props.onLoadLocation('/admin/worksheets/worksheet_list', 'Upload file completed!')
          this.props.history.push('/admin/worksheets/worksheet_list')
        } else {
          this.setState({
            msg_status: false,
            message: 'Something went wrong. Please try again.',
          })
        }
        window.scrollTo(0, 0)
      })
    }
  }

  render() {
    //console.log(this.state)
    return (
      <div className="admin_log_outcome box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Edit Worksheet
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className={(this.state.msg_status && "row warning_msg7") || (!this.state.msg_status && "row warning_msg6")}>
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="new_self_stigma" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 medium-2 columns bold_txt3">Name:</div>
                      <div className="small-12 medium-6 columns no_padding_left_float_left">
                      <TextField id="name" label="" onChange={this.handleChange}
                        variant="outlined" type="text" defaultValue={this.state.name}
                        value={this.state.name}
                        InputProps={{ inputProps: { name: "name" }}} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-2 columns bold_txt3">File:</div>
                      <div className="small-12 medium-6 columns no_padding_left_float_left">
                      <TextField onChange={this.handleChangeFile} variant="outlined"
                      disabled={this.state.completed_status && 'disabled'}
                      type="file" className="w100pc"
                      InputProps={{ inputProps: { name: "selectedFile" }}} />
                      <br /><a href={"/admin/worksheets/worksheet_pdf/"+this.state.wid}>{this.state.file_name}</a>
                      <br /><span className="red1">* PDF file only</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-2 columns bold_txt3">Category:</div>
                      <div className="small-12 medium-4 columns no_padding_left_float_left">
                      <FormControl variant="outlined">
                        <Select value={this.state.category} variant="outlined" name="category"
                          label="" onChange={(e) => this.handleChange(e, 'category')}>
                          {this.state.categoryList.map(each =>
                            <MenuItem key={'c'+each.id} value={each.id}>{each.category_name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                  {this.state.dataList.length > 0 && !this.state.editMode &&
                    <a onClick={() => this.props.history.goBack()} className="go_back">Go Back</a>
                  }
                  {(this.state.dataList.length == 0 || this.state.editMode) &&
                    <span>
                    {this.state.status_submit &&
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    {!this.state.status_submit &&
                    <Button variant="contained" disabled
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    </span>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditWorksheet)
