import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../styles/assets/css/pages/interventions.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import Star from '@material-ui/icons/Star'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CourseNavigation from './course_navigation.jsx'

class CourseMain extends Component {
  constructor() {
      super(  );
      this.state = {
          main_title: '',
          image: '',
          sessions: [],
          stars: 0,
          start_date: '',
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_main_page',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        cid: this.props.match.params.cid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          main_title: result['data'][0][0].main_title,
          image: result['data'][0][0].image,
          sessions: result['data'][0],
          stars: result['data'][1],
          start_date: result['data'][0][0].start_date,
        })
      }
    })
  }
  calculateDayDiff = (date, weeks_available) => {
    var dateDiff =  Math.floor(Math.abs(new Date() - new Date(date)) / (1000 * 60 * 60 * 24))
    //console.log(date, weeks_available + " : " + dateDiff)
    if(weeks_available > 0) {
      if(dateDiff/7 >= weeks_available) {
        return 1
      } else {
        return 2
      }
    } else {
      return 1
    }
    return 2
  }

  render() {
    //console.log(this.state.sessions)
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-7 columns headerPage1">
            <a href={"/member/intervention/"+this.props.match.params.cid+"/main"}>{this.state.main_title}</a>
          </div>
          <div className="small-12 medium-5 columns align_right nav_bar">
            <CourseNavigation />
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== "" &&
              <div className="row warning_msg7">
                <div className="small-12 columns ">
                  <div className="float_left1"><CheckCircleOutlineIcon /></div>
                  <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            </div>}
          <div className="small-12 columns block1">
            <div className="img1"><img src={this.state.image} /></div>
            <div className="small-12 columns course_title_topic3">Sessions</div>
            {this.state.sessions.map(each =>
              this.calculateDayDiff(this.state.start_date, each.weeks_available) == 1 &&
              <div className="title_link1"><a href={"/member/intervention/"+this.props.match.params.cid+"/session/"+each.session+"/1/1"}>{each.session_title}</a></div>
            )}
          </div>
        </div>
      </div>
    </div>
)}}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourseMain)
