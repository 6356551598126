import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/header1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import Email from '@material-ui/icons/Email'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import GetXLSX from '../../functions/get_xlsx.jsx'

import { preferred_pronouns } from '../../patients/variables/general_information.js'

class PatientHead3 extends Component {
  constructor() {
      super();
      this.state = {
          name: '',
          mrn: '',
          registered: '',
          dob: '',
          age: '',
          clinician: '',
          clinician_intake: '',
          navigator: '',
          status: '',
          pronounce: '',
          insurance: '',
          insurance_other: '',
          receive_email: 0,
      }
  }

  componentDidMount() {
    this.getHeader()
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  getHeader = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_head1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
      // this.props.authReducer.uid
    })
    .then((result) => {
        var patient = result['data']
        this.setState({
          name: patient.fullname,
          pronounce: this.findLabelByValue(preferred_pronouns, patient.prefered_pronouns),
          mrn: patient.mrn,
          registered: patient.registered,
          dob: patient.dob,
          age: patient.age,
          clinician: patient.c_name,
          clinician_intake: patient.c_intake_name,
          navigator: patient.navigator_name,
          status: patient.status,
          status_id: patient.status_id,
          best_phone: patient.best_phone,
          phone: patient.phone,
          email: patient.email,
          insurance: patient.insurance,
          insurance_other: patient.insurance_other,
          insur: patient.insur,
          receive_email: patient.receive_email,
        })
      }
    )
  }

  formatDate =  (date) => {
    if(date) {
      var [ year, month, day ] = date.split('-')

      if (month < 10)
          month = '0' + month;
      if (day < 10)
          day = '0' + day;

      return [month, day, year].join('/')
    }
  }

  downloadFile = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/download_patient_information',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        var patient = result['data']
      }
    )
  }

  render() {
    return (
      <div className="row header3">
        <div className="small-12 columns box_inner3">
          <div className="row">
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Name: </span>
              {this.state.name} &nbsp;
              <a href={"/admin/edit_patient_name/"+this.props.match.params.pid} >Edit</a>
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Pronouns: </span> {this.state.pronounce}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Registered: </span> {this.state.registered}
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Date of Birth: </span>
              {this.formatDate(this.state.dob)}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">
              Insurance: </span> {this.state.insur > 0 && this.state.insurance}
              {this.state.insur == 100 && ' [' + this.state.insurance_other + ']'}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Status: </span> {this.state.status}
               &nbsp;
              {this.state.status_id != 1 &&
                <a href={"/admin/edit_patient_status/"+this.props.match.params.pid}>Edit</a>}
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Supervising Clinician: </span>
              {this.state.clinician}
            </div>
            <div className="small-12 medium-4 columns float_left">
              <span className="bold_txt1">Practicum Student: </span>
              {this.state.clinician_intake}
            </div>
            <div className="small-12 medium-4 columns float_left">
              <span className="bold_txt1">Patient Navigator: </span>
              {this.state.navigator} &nbsp;
              <a href={"/admin/treatment/assign_team/"+this.props.match.params.pid} >Edit</a>
            </div>
            {/*this.state.status_id == 7 &&
            <div className="small-12 medium-4 columns bottom-space1 float_left">
              <span className="bold_txt1">Best phone number: </span>
              {this.state.best_phone}
            </div>*/}
          </div>
          <div className="row">
            <div className="small-12 medium-4 columns bottom-space1 float_left">
              <span className="bold_txt1">Email: </span>
              {this.state.email} &nbsp;
              <a href={"/admin/edit_email/"+this.props.match.params.pid} >Edit</a>
            </div>
            <div className="small-12 medium-4 columns bottom-space1 float_left">
              <span className="bold_txt1">Phone: </span>
              {this.state.phone}
            </div>
            <div className="small-12 medium-3 columns">
              <span className="bold_txt1">MRN: </span>
              {this.state.mrn && this.state.mrn} &nbsp;
              <a href={"/admin/edit_mrn/"+this.props.match.params.pid}>Edit</a>
            </div>
            <div className="small-12 medium-1 columns bottom-space1 float_left">
              {/*<a onClick={this.downloadFile}>Download Information</a>*/}
              <GetXLSX path="get_patient_information_xlsx"  />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientHead3)
