import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import '../../../../styles/assets/css/admin/header1.css'
import { loadLocationWithMessage } from '../../../../actions/location'
import { mini_q_a, mini_q_b, mini_q_c, mini_q_d , mini_q_e,
  mini_q_f, mini_q_g, mini_q_h, mini_q_i, mini_q_j1, mini_q_j_meet_criteria,
  mini_q_k, mini_q_l, mini_q_m, mini_q_n, mini_q_o,
  mini_scale2 } from '../../variables/assessments/mini.js'
import { live_where, live_with } from '../../../patients/variables/general_information.js'
import { diagnosed } from '../../../patients/variables/medical_history.js'

class SummaryNotes extends Component {
  constructor() {
      super();
      this.state = {
          name: '',
          diagnoses: '',
          lives: '',
          lives_other: '',
          lives_with: '',
          lives_with_other: '',
          job_status: '',
          pronouns: '',
          hasHave: '',
          medical_diagnoses: '',
          hospitalized: '',
          suicide_attempts: '',
          treatment_team: '',
          major_depressive: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_summary_note',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
      // this.props.authReducer.uid
    })
    .then((result) => {
        var data = result['data']
        var buff_data = result['data']
        //console.log(data)
        if(data.scid_step == 'complete') {
          buff_data.a1 = data.scid_a1
          buff_data.a3 = data.scid_a2
          buff_data.a4 = data.scid_a10
          buff_data.c1 = data.scid_a3
          buff_data.c2 = data.scid_a4
          buff_data.c3 = data.scid_a5
          buff_data.c4 = data.scid_a6
          buff_data.c5 = data.scid_a7
          buff_data.c7 = data.scid_a8
          buff_data.k2 = data.scid_a12
          buff_data.k1 = data.scid_a13
          if(data.scid_b1 == 1 || data.scid_b2 == 1 || data.scid_b3 == 1) {
            buff_data.i1 = 1
          }
          buff_data.d1 = data.scid_c1
          buff_data.e1 = data.scid_c2
          buff_data.f1 = data.scid_c3
          buff_data.g1 = data.scid_c5
          buff_data.h1 = data.scid_c6
          buff_data.l1 = data.scid_c7
          buff_data.m1 = data.scid_c8
          buff_data.m3 = data.scid_c9
        }
        data = buff_data
        this.setState({
          name: data.fullname,
          firstname: this.generateFirstName(data.fullname),
          diagnoses: this.diagnose(data), //this.generateCriterias(data),
          lives: this.findLabelByValue(live_where, data.where_you_live),
          lives_other: data.where_you_live_other,
          lives_with: this.findLabelByValue(live_with, data.who_live_with),
          lives_with_other: data.who_live_withe_other,
          job_status: this.generateJobStatus(data.currently_employed),
          pronouns: this.generatePronouns(data.prefered_pronouns, data.prefered_pronouns_other),
          pronouns2: this.generatePronouns2(data.prefered_pronouns, data.prefered_pronouns_other),
          hasHave: this.generateHasHave(data.prefered_pronouns),
          medical_diagnoses: this.generateMedicalDiagnoses(data),
          hospitalized: this.generateHospitalized(data.prev_hosp_psy_reason),
          suicide_attempts: this.generateSuicide(data.suicide_attp),
          //major_depressive: this.diagnose(data)
        })
      }
    )
  }

  diagnose = (data) => {
    var txt = ''
    //console.log('A: '+this.calculateMajorDepressive(data.a1, data.a2, data.a3, data.a4, data.a5, data.a6, data.c5, data.c6, data.c7, data.c8, data.c9, data.c10))
    txt += this.chkEmptyAddComma(txt, this.calculateMajorDepressive(data.a1, data.a2, data.a3, data.a4, data.a5, data.a6, data.c5, data.c6, data.c7, data.c8, data.c9, data.c10))
    //console.log('B: '+this.calculateSuicidality(data.b1, data.b2))
    txt += this.chkEmptyAddComma(txt, this.calculateSuicidality(data.b1, data.b2))
    //console.log('C: '+this.calculateBipolar(data.c5, data.c6, data.c7, data.c8, data.c9, data.c10))
    txt += this.chkEmptyAddComma(txt, this.calculateBipolar(data.c5, data.c6, data.c7, data.c8, data.c9, data.c10))
    //console.log('D: '+this.calculatePanic(data.d1, data.d2))
    txt += this.chkEmptyAddComma(txt, this.calculatePanic(data.d1, data.d2))
    //console.log('E: '+this.calculateAgoraphobia(data.e1))
    txt += this.chkEmptyAddComma(txt, this.calculateAgoraphobia(data.e1))
    //console.log('F: '+this.calculateSocial(data.f1))
    txt += this.chkEmptyAddComma(txt, this.calculateSocial(data.f1))
    //console.log('G: '+this.calculateObessive(data.g1))
    txt += this.chkEmptyAddComma(txt, this.calculateObessive(data.g1))
    //console.log('H: '+this.calculatePTSD(data.h1))
    txt += this.chkEmptyAddComma(txt, this.calculatePTSD(data.h1))
    //console.log('I1: '+this.calculateAlcoholDep(data.i1, data.i3))
    txt += this.chkEmptyAddComma(txt, this.calculateAlcoholDep(data.i1, data.i3))
    //console.log('I2: '+this.calculateAlcoholAbuse(data.i2, data.i4))
    txt += this.chkEmptyAddComma(txt, this.calculateAlcoholAbuse(data.i2, data.i4))
    //console.log('J1: '+this.calculateSubstanceDep(data.j1))
    txt += this.chkEmptyAddComma(txt, this.calculateSubstanceDep(data.j1))
    //console.log('J2: '+this.calculateSubstanceAbuse(data.j2))
    txt += this.chkEmptyAddComma(txt, this.calculateSubstanceAbuse(data.j2))
    //console.log('K1: '+this.calculatePsychotic(data.k1, data.k2))
    txt += this.chkEmptyAddComma(txt, this.calculatePsychotic(data.k1, data.k2))
    //console.log('K2: '+this.calculateMoodDisorder(data.k3, data.k4))
    txt += this.chkEmptyAddComma(txt, this.calculateMoodDisorder(data.k3, data.k4))
    //console.log('L: '+this.calculateAnorexia(data.l1))
    txt += this.chkEmptyAddComma(txt, this.calculateAnorexia(data.l1))
    //console.log('M1: '+this.calculateBulimia(data.m1))
    txt += this.chkEmptyAddComma(txt, this.calculateBulimia(data.m1))
    //console.log('M2: '+this.calculateBinge(data.m2))
    txt += this.chkEmptyAddComma(txt, this.calculateBinge(data.m2))
    //console.log('N: '+this.calculateGAD(data.n1))
    txt += this.chkEmptyAddComma(txt, this.calculateGAD(data.n1))
    //console.log('O: '+this.calculateMedical(data.o1))
    txt += this.chkEmptyAddComma(txt, this.calculateMedical(data.o1))
    //console.log(txt)
    return txt
  }

  calculateMajorDepressive = (a1, a2, a3, a4, a5, a6, c5, c6, c7, c8, c9, c10) => {
    if(c5 == 1 || c6 == 1 || c7 == 1 || c8 == 1 || c9 == 1 || c10 == 1) { // C override A
      return ''
    }
    if(a1 == 1 || a2 == 1 || a3 == 1) {
      return ''
    }
    if(a4 == 1 || a5 == 1 || a6 == 1) {
      return 'Major Depressive Disorder'
    }
    return ''
  }

  calculateSuicidality = (b1, b2) => {
    var txt = ''
    if(b1 == 1) {
      txt += 'Suicidality (current)'
    }
    txt += this.chkEmptyAddComma(txt, this.findLabelByValue(mini_scale2, b2) + ' suicidality risk level')
    return txt
  }

  calculateBipolar = (c5, c6, c7, c8, c9, c10) => {
    var txt = ''
    if(c5 == 1 || c6 == 1) {
      txt += 'Bipolar Disorder I'
    }
    if(c7 == 1 || c8 == 1) {
      txt += this.chkEmptyAddComma(txt, 'Bipolar Disorder II')
    }
    if(c9 == 1 || c10 == 1) {
      txt += this.chkEmptyAddComma(txt, 'Bipolar Disorder NOS')
    }
    return txt
  }

  calculatePanic = (d1, d2) => {
    if(d2 == 1) {
      return 'panic disorder'
    }
    if(d1 == 1) {
      return 'panic disorder (Current)'
    }
    return ''
  }

  calculateAgoraphobia = (e1) => {
    if(e1 == 1) {
      return 'Agoraphobia'
    }
    return ''
  }

  calculateSocial = (f1) => {
    if(f1 == 1) {
      return 'Social Phobia/Social Anxiety Disorder (current)'
    }
    return ''
  }

  calculateObessive = (g1) => {
    if(g1 == 1) {
      return 'Obessive-Compulsive Disorder (current)'
    }
    return ''
  }

  calculatePTSD = (h1) => {
    if(h1 == 1) {
      return 'Posttraumatic Stress Disorder (current)'
    }
    return ''
  }

  calculateAlcoholDep = (i1, i3) => {
    var txt = ''
    if(i3 == 1) {
      txt += 'Alcohol dependence (lifetime)'
    } else if(i3 != 1 && i1 == 1) {
      txt += 'Alcohol dependence (past 12 months)'
    }
    return txt
  }

  calculateAlcoholAbuse = (i2, i4) => {
    var txt = ''
    if(i4 == 1) {
      txt += 'Alcohol abuse (lifetime)'
    } else if(i4 != 1 && i2 == 1) {
      txt += 'Alcohol abuse (past 12 months)'
    }
    return txt
  }

  calculateSubstanceDep = (j1) => {
    if(j1 == 1) {
      return 'Substance dependence stimulants (past 12 months)'
    }
    return ''
  }

  calculateSubstanceAbuse = (j2) => {
    if(j2 == 1) {
      return 'Substance abuse stimulants (past 12 months)'
    }
    return ''
  }

  calculatePsychotic = (k1, k2) => {
    var txt = ''
    if(k1 == 1) {
      return 'Psychotic disorders (lifetime)'
    } else if(k2 == 1) {
      return 'Psychotic disorders (current)'
    }
    return ''
  }

  calculateMoodDisorder = (k3, k4) => {
    var txt = ''
    if(k3 == 1) {
      return 'Mood disorders with psychotic features (lifetime)'
    } else if(k4 == 1) {
      return 'Mood disorders with psychotic features (current)'
    }
    return ''
  }

  calculateAnorexia = (l1) => {
    if(l1 == 1) {
      return 'Anorexia nervosa (current)'
    }
    return ''
  }

  calculateBulimia = (m1) => {
    if(m1 == 1) {
      return 'Bulimia nervosa (current)'
    }
    return ''
  }

  calculateBinge = (m2) => {
    if(m2 == 1) {
      return 'Anorexia nervosa, Binge eating/purging type (current)'
    }
    return ''
  }

  calculateGAD = (n1) => {
    if(n1 == 1) {
      return 'Generalized Anxiety Disorder (current)'
    }
    return ''
  }

  calculateMedical = (o1) => {
    if(o1 == 1) {
      return 'Medical, organic, drug cause ruled out'
    }
    return ''
  }

  chkEmptyAddComma = (txt, additional) => {
    if(txt.length > 0 && additional.length > 0) {
      return ', '+ additional
    } else if(txt.length == 0 && additional.length > 0) {
      return additional
    }
    return ''
  }

  generateMedicalDiagnoses = (val) => {
    var field = ''
    var arr_txt = []
    diagnosed.map((d, index) => {
      field = 'diagnosed_' + d.value
      if(val[field] == 1) {
        arr_txt.push(d.label)
      }
    })
    var txt = ''
    if(arr_txt.length > 0) {
      arr_txt.map((c, i) => {
        if(i > 0 && i != arr_txt.length-1) {
          txt += ", "
        }
        if(i == arr_txt.length-1 && arr_txt.length != 1) {
          txt += " and "
        }
        txt += c
      })
    } else {
      txt = 'None'
    }
    return txt
  }

  generateFirstName = (val) => {
    var firstname = val.split(" ")
    return firstname[0]
  }

  generateCriterias = (val) => {
    var txt = ''
    var criterias = []
    var arr = ['a1', 'a2', 'a3', 'a4', 'a5', 'b1',
               'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10',
               'd1', 'd2', 'e1', 'f1', 'f2', 'f3', 'g1', 'h1', 'i1', 'i2', 'i3',
               'j1', 'j2', 'k1', 'k2', 'k3', 'k4', 'l1', 'm1', 'm2', 'n1', 'o1']
    arr.map(mini => {
      if(val[mini] == 1) { // meet criteria
        var mini_type = mini.substr(0,1)
        var mini_number = mini.substring(1)
        //console.log(mini+': '+val[mini] + " => " + mini_type + ": "+ mini_number)
        if(txt.length > 0) {
          txt += ', '
        }
        if(mini_type == 'a') {
          criterias.push(this.findLabelByValue(mini_q_a, mini_number))
          //txt += this.findLabelByValue(mini_q_a, mini_number)
        } else if(mini_type == 'b') {
          criterias.push(this.findLabelByValue(mini_q_b, mini_number))
          //txt += this.findLabelByValue(mini_q_b, mini_number)
        } else if(mini_type == 'c') {
          criterias.push(this.findLabelByValue(mini_q_c, mini_number))
          //txt += this.findLabelByValue(mini_q_c, mini_number)
        } else if(mini_type == 'd') {
          criterias.push(this.findLabelByValue(mini_q_d, mini_number))
          //txt += this.findLabelByValue(mini_q_d, mini_number)
        } else if(mini_type == 'e') {
          criterias.push(this.findLabelByValue(mini_q_e, mini_number))
          //txt += this.findLabelByValue(mini_q_e, mini_number)
        } else if(mini_type == 'f') {
          criterias.push(this.findLabelByValue(mini_q_f, mini_number))
          //txt += this.findLabelByValue(mini_q_f, mini_number)
        } else if(mini_type == 'g') {
          criterias.push(this.findLabelByValue(mini_q_g, mini_number))
          //txt += this.findLabelByValue(mini_q_g, mini_number)
        } else if(mini_type == 'h') {
          criterias.push(this.findLabelByValue(mini_q_h, mini_number))
          //txt += this.findLabelByValue(mini_q_h, mini_number)
        } else if(mini_type == 'i') {
          criterias.push(this.findLabelByValue(mini_q_i, mini_number))
          //txt += this.findLabelByValue(mini_q_i, mini_number)
        } else if(mini_type == 'j') {
          criterias.push(this.findLabelByValue(mini_q_j1, mini_number))
          //txt += this.findLabelByValue(mini_q_j, mini_number)
        } else if(mini_type == 'k') {
          criterias.push(this.findLabelByValue(mini_q_k, mini_number))
          //txt += this.findLabelByValue(mini_q_k, mini_number)
        } else if(mini_type == 'l') {
          criterias.push(this.findLabelByValue(mini_q_l, mini_number))
          //txt += this.findLabelByValue(mini_q_l, mini_number)
        } else if(mini_type == 'm') {
          criterias.push(this.findLabelByValue(mini_q_m, mini_number))
          //txt += this.findLabelByValue(mini_q_m, mini_number)
        } else if(mini_type == 'n') {
          criterias.push(this.findLabelByValue(mini_q_n, mini_number))
          //txt += this.findLabelByValue(mini_q_n, mini_number)
        } else if(mini_type == 'o') {
          criterias.push(this.findLabelByValue(mini_q_o, mini_number))
          //txt += this.findLabelByValue(mini_q_o, mini_number)
        }
      }
    })
    //console.log(criterias)
    criterias.map((c, i) => {
      if(i > 0 && i != criterias.length-1) {
        txt += ", "
      }
      if(i == criterias.length-1 && criterias.length != 1) {
        txt += " and "
      }
      txt += c
    })
    return txt
  }

  generateJobStatus = (val) => {
    if(val == 1) {return 'Employed'}
    else if(val == 2) {return 'Unemployed'}
  }

  generateHospitalized = (val) => {
    if(val == 1) {return 'has previously'}
    else if(val == 2) {return 'has not'}
  }

  generateSuicide = (val) => {
    if(val == 1) {return 'has a'}
    else if(val == 2) {return 'has no'}
  }

  generatePronouns = (val, other) => {
    if(val == 1) {return 'She'}
    else if(val == 2) {return 'He'}
    else if(val == 3) {return 'They'}
    else if(val == 4) {return 'She/They'}
    else if(val == 5) {return 'He/They'}
    else if(val == 6) {return other}
  }

  generatePronouns2 = (val, other) => {
    if(val == 1) {return 'Her'}
    else if(val == 2) {return 'His'}
    else if(val == 3) {return 'Their'}
    else if(val == 4) {return 'Her/Their'}
    else if(val == 5) {return 'His/Their'}
    else if(val == 6) {return other}
  }

  generateHasHave = (val) => {
    if(val == 1 || val == 2) {return 'has'}
    else if(val == 3 || val == 4 || val == 5 || val == 6) {return 'have'}
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value == searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    //console.log(this.state)
    return (
      <div className="row header3 top_space2">
        <div className="small-12 columns box_inner3">
          <div className="row">
            <div className="small-12 columns">
            <p>At registration, {this.state.name} has (diagnoses)/(a diagnosis) of {this.state.diagnoses},
            lives at "{this.state.lives}{this.state.lives_other && ' ['+this.state.lives_other+']'}"
            "{this.state.lives_with}{this.state.lives_with_other && ' ['+this.state.lives_with_other+']'}"
            and is "{this.state.job_status}".&nbsp;
            {this.state.pronouns} {this.state.hasHave} the following medical diagnoses:&nbsp;
            {this.state.medical_diagnoses}</p>

            <p>{this.state.firstname} "{this.state.hospitalized}" been
            hospitalized for psychiatric reasons
            and "{this.state.suicide_attempts}" previous history of suicide attempts.</p>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SummaryNotes)
