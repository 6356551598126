import React from "react"

import AppointmentList from "../components/patients/appointment/appointment_list"
import AssessmentList from "../components/patients/assessments/assessment_list"

import Phq4 from "../components/patients/assessments/phq4"
import Who5 from "../components/patients/assessments/who5"
import Mtf from "../components/patients/assessments/mtf"
import Providers from "../components/patients/assessments/providers"
//import Covid from "../components/patients/assessments/covid"
import CompleteAssessments from "../components/patients/assessments/complete_assessments"

import Sccs from "../components/patients/assessments/sccs"
import Ismi from "../components/patients/assessments/ismi"
import CompleteSelfStigma from "../components/patients/assessments/complete_self_stigma"

import Profile from "../components/public/profile"
import Assessments from "../components/patients/assessments"

import CourseMain from "../components/patients/courses/course_main"
import CourseSession from "../components/patients/courses/course_session"
import CourseLibrary from "../components/patients/courses/course_library"
import CoursePersonas from "../components/patients/courses/course_personas"

const PATIENTS_ROUTES = [
  {   path: "/member/assessment_list",
      key: "assessment_list",
      title: "Assessment List",
      exact: true,
      requireAuth: true,
      component: () => <AssessmentList />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/phq4/:type/:timepoint",
      key: "phq4",
      title: "PHQ4",
      exact: true,
      requireAuth: true,
      component: () => <Phq4 />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   //path: "/member/assessment/who5/:timepoint",
      path: "/member/assessment/who5/:type/:timepoint",
      key: "who5",
      title: "WHO-5",
      exact: true,
      requireAuth: true,
      component: () => <Who5 />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/providers/:type/:timepoint",
      key: "providers",
      title: "Providers",
      exact: true,
      requireAuth: true,
      component: () => <Providers />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/mtf/:type/:timepoint",
      key: "mtf",
      title: "MTF",
      exact: true,
      requireAuth: true,
      component: () => <Mtf />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  /*{   path: "/member/assessment/covid/:type/:timepoint",
      key: "covid",
      title: "COVID",
      exact: true,
      requireAuth: true,
      component: () => <Covid />,
      showtitle: 'true',
      ficon: 'fi-home',
  },*/
  {   path: "/member/assessment/complete_assessments/:type/:timepoint",
      key: "complete_assessments",
      title: "complete_assessments",
      exact: true,
      requireAuth: true,
      component: () => <CompleteAssessments />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/profile",
      key: "profile",
      title: "Profile",
      exact: true,
      requireAuth: true,
      component: () => <Profile />,
      showtitle: 'true',
      ficon: 'fi-bookmark',
  },
  {   path: "/assessments",
      key: "assessments",
      title: "Assessments",
      exact: true,
      requireAuth: true,
      component: () => <Assessments />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/member/appointment_list",
      key: "appointment_list",
      title: "Appointment List",
      exact: true,
      requireAuth: true,
      component: () => <AppointmentList />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/self_stigma/sccs/:assm_id",
      key: "sccs",
      title: "Self-Concept Clarity Scale",
      exact: true,
      requireAuth: true,
      component: () => <Sccs />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/self_stigma/ismi/:assm_id",
      key: "ismi",
      title: "Internalized Stigma of Mental Illness Inventory (ISMI)",
      exact: true,
      requireAuth: true,
      component: () => <Ismi />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/self_stigma/complete/:assm_id",
      key: "complete",
      title: "Complete",
      exact: true,
      requireAuth: true,
      component: () => <CompleteSelfStigma />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/main",
      key: "intervention",
      title: "Intervention",
      exact: true,
      requireAuth: true,
      component: () => <CourseMain />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/library",
      key: "library",
      title: "Library",
      exact: true,
      requireAuth: true,
      component: () => <CourseLibrary />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/personas",
      key: "personas",
      title: "Personas",
      exact: true,
      requireAuth: true,
      component: () => <CoursePersonas />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/intervention/:cid/session/:session/:sub_session/:sub_session_page",
      key: "intervention",
      title: "Intervention",
      exact: true,
      requireAuth: true,
      component: () => <CourseSession />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
];

export default PATIENTS_ROUTES;
