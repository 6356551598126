import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/admin/list2.css'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {Link} from "react-router-dom"

class AdditionalInfo extends Component {
  constructor() {
    super()
    this.state = {
        msg_status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        selectedFile: null,
        file_list: [],
        name: '',
        uid: 0, //this.props.authReducer.uid,
        pid: 0, //this.props.match.params.pid,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    // Get Files //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_files',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          file_list: result['data'],
        })
      }
    })
  }

  handleChange2 = (e, name) => {
    //console.log(name+': '+e.target.value)
    this.setState({
      [name]: e.target.value,
    })
  }

  handleChangeFile = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
      uid: this.props.authReducer.uid,
      pid: this.props.match.params.pid,
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.selectedFile == null ) {
      this.setState({
        msg_status: false,
        message: 'Please fill all information',
      })
    } else if(this.state.selectedFile.type != 'application/pdf') {
      this.setState({
        msg_status: false,
        message: 'Please upload PDF file format',
      })
    } else {
      var pid = this.props.match.params.pid
      const formData = new FormData()
      var dataToSubmit = this.state

      formData.append('file', this.state.selectedFile)
      formData.append('udata', JSON.stringify(dataToSubmit))
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/add_files',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        //body: this.state, //this.state,
        data: formData, //dataToSubmit,
      })
      .then((result) => {
        if(result['data'] == this.state.pid) {
          this.setState({
            msg_status: true,
            message: 'Upload file completed!',
            selectedFile: null,
            name:'',
            selectedFile: null,
          })
          this.getData()
        } else {
          this.setState({
            msg_status: false,
            message: 'Something went wrong. Please try again.',
          })
        }
        window.scrollTo(0, 0)
      })
    }
  }

  render() {
    return (
    <div className="general_information">
      <div className="row">
        <div className="small-12 columns">
          <div className="title1">Additional Information</div>
        </div>
        { this.state.message !== '' &&
        <div className="small-12 columns">
          <div className={(!this.state.msg_status && 'small-12 columns warning_msg6') || (this.state.msg_status && 'small-12 columns warning_msg7') }>
            <div className="float_left1">
              {!this.state.msg_status && <WarningIcon />}
              {this.state.msg_status && <CheckCircleOutlineIcon />}
            </div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <div className="small-12 columns">
          <div className="row space_bottom2 top_space1">
            <div className="small-12 columns">
              <div className="small-12 medium-2 columns bold_txt2 text_align_right">Name:</div>
              <div className="small-12 medium-6 columns float_left">
                <TextField onChange={(e) => this.handleChange2(e, 'name')} variant="outlined"
                disabled={this.state.completed_status && 'disabled'}
                type="text" className="w100pc" value={this.state.name}
                InputProps={{ inputProps: { name: "name", maxLength: 250 }}} />
              </div>
            </div>
          </div>
          <div className="row space_bottom2 top_space1">
            <div className="small-12 columns">
              <div className="small-12 medium-2 columns bold_txt2 text_align_right">File:</div>
              <div className="small-12 medium-6 columns float_left">
                <TextField onChange={this.handleChangeFile} variant="outlined"
                disabled={this.state.completed_status && 'disabled'}
                type="file" className="w100pc"
                InputProps={{ inputProps: { name: "selectedFile" }}} />
                <br /><span className="red1">* PDF file only</span>
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="small-12 medium-2 columns show-for-medium">&nbsp;</div>
            <div className="small-12 medium-6 columns float_left no_padding_left">
            {!this.state.completed_status &&
              <Button variant="contained" color="primary"
                 onClick={this.handleSubmit} type="submit">
                  Upload
              </Button>}
            </div>
          </div>

          <div className="small-12 columns top_space1">
          <table className="table_data1 top_space1">
            <tr>
              <th>#</th>
              <th>File</th>
              <th>Uploaded</th>
            </tr>
          {this.state.file_list.map((each, index) =>
            <tr>
              <td>{index + 1}</td>
              <td>
              <Link to={'/admin/patient_chart/file/'+each.id} target="_blank">
                {each.name}</Link>
              </td>
              <td>{each.uploaded_date}</td>
            </tr>
          )}
          </table>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AdditionalInfo)
