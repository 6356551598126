import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { ders18_questions, ders18_choices, ders18_choices2 } from '../variables/assessments/ders18.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

import { baseline_steps } from '../variables/baseline.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { calculateTimepoint1, calculateTimepoint3 } from '../../functions/timepoint.js'
import StepperAssm from './stepper_assm'

class Der18 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: 1,
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'psqi',
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
          q6: 0,
          q7: 0,
          q8: 0,
          q9: 0,
          q10: 0,
          q11: 0,
          q12: 0,
          q13: 0,
          q14: 0,
          q15: 0,
          q16: 0,
          q17: 0,
          q18: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    if(timepoint == -1) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_baseline_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { uid: this.props.authReducer.uid }
      })
      .then((result) => {
          if(result['data'] !== 'ders18') {
            this.props.history.push('/member/assessment/'+result['data']+'/'+type+'/'+timepoint)
          }
        }
      )
    } else {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
         }
      })
      .then((result) => {
        var calTimepoint = calculateTimepoint3(result['data'].timeline_start)
        if(this.props.match.params.timepoint > calTimepoint.current_sixmonth) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_sixmonth) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].status == 1 && result['data'].step !== 'ders18') {
              this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            }
            if(result['data'].status == 2) {
              // Completed //
              this.getCompletedData(2)
            }
          } else {
            this.setState({
              next_step: 'complete_assessments',
            })
          }
        } else {
          // Past timepoint //
          this.getCompletedData(3)
        }
        this.setState({
          next_step: 'complete_assessments',
        })
      })
    }
  }

  getCompletedData = (status) => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: this.props.match.params.type,
        timepoint: this.props.match.params.timepoint,
        frm: 'ders18',
       }
    }).then((result) => {
      if(result['data'].q1 > 0) {
        this.setState({
          status: status,
          next_step: 'complete_assessments',
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          q9: result['data'].q9,
          q10: result['data'].q10,
          q11: result['data'].q11,
          q12: result['data'].q12,
          q13: result['data'].q13,
          q14: result['data'].q14,
          q15: result['data'].q15,
          q16: result['data'].q16,
          q17: result['data'].q17,
          q18: result['data'].q18,
          next_step: 'complete_assessments',
        })
      }
      //console.log(this.state)
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = 'psqi'
    if(type == 'y' || type == 'sm') {
      next_step = 'complete_assessments'
    }
    if(this.state.status === 1) {
      if(this.state.q1 === 0 || this.state.q2 === 0 || this.state.q3 === 0 ||
        this.state.q4 === 0 || this.state.q5 === 0 || this.state.q6 === 0 ||
        this.state.q7 === 0 || this.state.q8 === 0 || this.state.q9 === 0 ||
        this.state.q10 === 0 || this.state.q11 === 0 || this.state.q12 === 0 ||
          this.state.q13 === 0 || this.state.q14 === 0 || this.state.q15 === 0 ||
          this.state.q16 === 0 || this.state.q17 === 0 || this.state.q18 === 0) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_ders18',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            type: this.props.match.params.type,
            timepoint: this.props.match.params.timepoint,
          }
        })
        .then((result) => {
            if(result['data'] === next_step) {
              this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+this.props.match.params.timepoint)
              this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+this.props.match.params.timepoint)
            }
          }
        )
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }

  }

  render() {
    //console.log(this.state)
    return (
      <div className="assm_ders18 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          Difficulties in Emotion Regulation Scale (DERS-18)
          </div>
          {this.props.match.params.timepoint < 0 &&
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={6}>
              {baseline_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box2_2">
            <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
          </div>
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-7 columns">&nbsp;</div>
              {ders18_choices.map(each =>
                <div className="medium-1 columns headerResize1" key={'d'+each.value}>
                  {each.label}
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>
            <div className="scroll_y_2">
            {ders18_questions.map(eachQ =>
              <div className="row" key={"der"+eachQ.value}>
                <div className="small-12 medium-7 columns">
                  {eachQ.value}. {eachQ.label}
                </div>
                {ders18_choices.map(each =>
                  <div className="small-12 medium-1 columns" key={'de'+eachQ.value+each.value}>
                    <input type="radio" name={"q"+eachQ.value}
                    checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    value={each.value} onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>
                )}
                {eachQ.value < 18 &&
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>}
              </div>
            )}
            </div>
        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  //console.log("timepoint: "+this.props.match.params.timepoint)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Der18)
