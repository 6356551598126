import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink} from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { loadLocationWithMessage } from '../../../../actions/location'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../../functions/pagination'
import ReactHtmlParser from 'react-html-parser'
import { filter_patient_status } from '../variables/patient_1.js'
import Spinner from 'react-spinner-material'

class CgiBDGroup1List extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: [],
          pageOfItems: [],
          cn_list: [],
          cn_selected: 0,
          time_selected: 0,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/reports/get_cn_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {  }
    })
    .then((result) => {
      this.setState({
        cn_list: result['data'],
      })
    })
    .then((result) => {
      this.getDataList()
    })
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/reports/cgi_bd_list_cn_group1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cn_selected: this.state.cn_selected,
        time_selected: this.state.time_selected,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        dataItems: this.getDataGroup1(result['data']),
      })
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  getDataGroup1 = (data) => {
    var buff_week = 0
    var arr = []
    data.map(each => {
      buff_week = each.num_week
      arr.push({
        fullname: each.fullname,
        cnt: each.cnt,
        start_date: this.convertDateFormat2(each.start_date),
        end_date: this.convertDateFormat2(each.end_date),
      })
    })
    return arr
  }

  convertDateFormat2 = (date) => {
    var buff2 = date.split('T')
    var buff = buff2[0].split('-')
    return buff[1]+'/'+buff[2]+'/'+buff[0]
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  render() {
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-6 columns headerPage3">
            CGI-BD: Non-Group Appointments
          </div>
          <div className="small-12 medium-4 columns float_left2">
          <FormControl variant="outlined" className="">
            <Select value={this.state.cn_selected} variant="outlined" label=""
              onChange={(e) => this.handleChange2(e, 'cn_selected')} >
            <MenuItem value="0">All clinicians</MenuItem>
            {this.state.cn_list.map(each =>
              <MenuItem key={'cn'+each.uid} value={each.uid}>{each.fullname}</MenuItem>
            )}
            </Select>
          </FormControl>
          </div>
          <div className="small-12 medium-2 columns float_left2 no_padding_left">
          <FormControl variant="outlined" className="">
            <Select value={this.state.time_selected} variant="outlined" label=""
              onChange={(e) => this.handleChange2(e, 'time_selected')} >
              <MenuItem value="0">Entire time</MenuItem>
              <MenuItem value="1">Last 1 month</MenuItem>
              <MenuItem value="2">Last 3 months</MenuItem>
              <MenuItem value="3">Last 6 months</MenuItem>
            </Select>
          </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>}
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Clinician</th>
                    <th>Date</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading1"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
                <tr key={item.id}>
                  <td>{item.fullname}</td>
                  <td>{item.start_date} - {item.end_date}</td>
                  <td>{item.cnt}</td>
                </tr>
              )}

              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="9">
                    <Pagination items={this.state.dataItems} pageSize={30} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CgiBDGroup1List)
