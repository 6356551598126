import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink} from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { loadLocationWithMessage } from '../../../../actions/location'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../../functions/pagination'
import ReactHtmlParser from 'react-html-parser'
import { filter_patient_status } from '../variables/patient_1.js'
import Spinner from 'react-spinner-material'
import Graph1 from './graph1'
class CgiBDReport extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          data_group1: [],
          data_group2: [],
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    // Data Group 1 //
    axios({
      method: 'post',
      url: connectNode + 'admin/reports/cgi_bd_list_cn_group1_1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {  }
    })
    .then((result) => {
      this.setState({
        data_group1: this.getDataGroup1(result['data'][0].value),
        data_group2: result['data'][1].value,
      })
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  getDataGroup1 = (data) => {
    var buff_week = 0
    var arr = []
    data.map(each => {
      if(each.num_week >= buff_week) {
        buff_week = each.num_week
        arr.push({
          fullname: each.fullname,
          cnt: each.cnt,
          start_date: this.convertDateFormat2(each.start_date),
          end_date: this.convertDateFormat2(each.end_date),
        })
      }
    })
    return arr
  }

  convertDateFormat = (date) => {
    var buff2 = date.split(' ')
    var buff = buff2[0].split('-')
    return buff[1]+'/'+buff[2]+'/'+buff[0]+", "+buff2[1].substr(0, 5)
  }

  convertDateFormat2 = (date) => {
    var buff2 = date.split('T')
    var buff = buff2[0].split('-')
    return buff[1]+'/'+buff[2]+'/'+buff[0]
  }

  render() {
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-12 columns headerPage1">
            Reports
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns txt_title1">
            CGI-BD
          </div>
          {this.state.data_group1.length > 0 &&
          <div className="small-12 columns box_border6_1">
          <div className="small-9 columns bold">Non-Group, Medication management and Psychopharm Consult Appointments</div>
          <div className="small-3 columns text_align_right"><a href="./cgi_bd_group1_list">See all</a></div>
          <table className="table_data1">
            <thead className="table_head">
              <tr>
                <th>Clinician</th>
                <th>Total {this.state.data_group1[0].start_date} - {this.state.data_group1[0].end_date}</th>

              </tr>
            </thead>
          {this.state.data_group1.map(each =>
            <tr>
              <td>{each.fullname}</td>
              <td>{each.cnt}</td>
            </tr>
          )}
          </table>
          <div className="small-12 columns bold">Non-Group, Medication management and Psychopharm Consult Appointments: Weekly Summary</div>
          <table className="table_data1">
            <thead className="table_head">
              <tr>
                <th>Week</th>
                <th>Total</th>

              </tr>
            </thead>
          {this.state.data_group2 && this.state.pageOfItems.map(each =>
            <tr>
              <td>{this.convertDateFormat2(each.start_date)} - {this.convertDateFormat2(each.end_date)}</td>
              <td>{each.cnt}</td>
            </tr>
          )}
          {this.state && this.state.data_group2 &&
            <tr className="pagination_block">
              <td colSpan="2">
                <Pagination items={this.state.data_group2} pageSize={12} onChangePage={this.onChangePage} />
              </td>
            </tr>
          }
          </table>
          <div className="small-12 columns">
              <Graph1 data_group={this.state.data_group2} pid={this.props.authReducer.uid} update_graph={this.state.update_graph} />
          </div>
          </div>}

          {/*<div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>}
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Clinician</th>
                    <th>Total Last Week</th>
                    <th>Detail</th>
                    <th>Last CGI-BD Created</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading1"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
                <tr key={item.id}>
                  <td>{item.fullname}</td>
                  <td>-</td>
                  <td><a href={"/admin/reports/cgi_bd_cn/"+item.uid}>Detail</a></td>
                  <td>{this.convertDateFormat(item.created_datetime)}</td>
                </tr>
              )}

              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="9">
                    <Pagination items={this.state.dataItems} pageSize={30} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CgiBDReport)
