import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'
//import Cookies from 'js-cookie'

//mport InputAdornment from '@material-ui/core/InputAdornment'
//import MailOutlineIcon from '@material-ui/icons/MailOutline'
//import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
//import { makeStyles } from "@material-ui/core/styles";
import { role, signs_of_depression, signs_of_mania, action,
         maintain_action } from '../variables/patient_contract.js'
import SignatureCanvas from 'react-signature-canvas'

class PatientContract extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          fullname: '',
          progress: 1,
          treatment_goal: [{
            id: 1,
            text: ''
          }],
          support_team: [{
            id: 1,
            role: 0,
            name: '',
            email: '',
          }],
          signs_of_depression: [{
            id: 1,
            val: 0,
            text: '',
          }],
          signs_of_mania: [{
            id: 1,
            val: 0,
            text: '',
          }],
          support_dep_mania: [{
            id: 1,
            action: 0,
            support: 0,
            text: '',
          }],
          support_maintain: [{
            id: 1,
            action: 0,
            support: 0,
            text: '',
          }],
          suicide_contact: [{
            id: 1,
            support: 0,
          }],
          //signature: null,
          patient_name: '',
          patient_date: new Date().toISOString().substring(0, 10),
          clinician_date: new Date().toISOString().substring(0, 10),
          cookieTimeout: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
          enable_signature: false,
          new_version: false
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = 0
    var get_uri = this.props.location.pathname
    let new_version = false;
    this.setState({ new_version: this.props.location.pathname.endsWith("new") }, () => { new_version = this.state.new_version; });
    var sep_uri = get_uri.split("/")
    if(this.props.authReducer.role == 5) {
      pid = this.props.authReducer.uid
      // check first version exist //
      get_uri = sep_uri[2]
    } else {
      pid = this.props.match.params.pid
      get_uri = sep_uri[3]
    }

    // Check completed mini assessments //
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_completed_mini_status', //'patient/assessment/get_baseline_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: pid }
    })
    .then((result) => {
      if((result['data'].mini_step != 'complete' && result['data'].scid_step != 'complete'&& result['data'].scid_v1_step != 'complete') || new_version) {
        var get_uri = this.props.location.pathname
        var sep_uri = get_uri.split("/")
        get_uri = sep_uri[2]
        var get_uri2 = sep_uri[3]
        if (!new_version) {
          if(get_uri2 == 'edit_patient_contract') {
            this.props.onLoadLocation('patient_chart', 'Please complete initial assessments before working on patient contract.')
            this.props.history.push('/admin/patient_chart/'+this.props.match.params.pid+'/3/1')
          } else {
            if(this.props.authReducer.role == 5) {
              this.props.onLoadLocation('patient_contract_list', 'Please complete initial assessments before working on patient contract.')
              this.props.history.push('/member/patient_contract_list')
            } else {
              this.props.onLoadLocation('patient_chart', 'Please complete initial assessments before working on patient contract.')
              this.props.history.push('/admin/patient_chart/'+this.props.match.params.pid+'/3/1')
            }
          }
        }
      }
    })
    // check regular patient contract //
    axios({
      method: 'post',
      url: connectNode + 'patient/enroll/get_patient_contract_by_pid',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        get_uri: get_uri,
        role: this.props.authReducer.role,
        contract_id: this.props.match.params.contract_id},
    })
    .then((result) => {
      var getData = {
          status: false,
          message: '',
          fullname: '',
          progress: 1,
          treatment_goal: [{
            id: 1,
            text: ''
          }],
          support_team: [{
            id: 1,
            role: 0,
            name: '',
            email: '',
          }],
          signs_of_depression: [{
            id: 1,
            val: 0,
            text: '',
          }],
          signs_of_mania: [{
            id: 1,
            val: 0,
            text: '',
          }],
          support_dep_mania: [{
            id: 1,
            action: 0,
            support: 0,
            text: '',
          }],
          support_maintain: [{
            id: 1,
            action: 0,
            support: 0,
            text: '',
          }],
          suicide_contact: [{
            id: 1,
            support: 0,
          }],
          //signature: null,
          patient_name: result['data'].fullname,
          patient_date: new Date().toISOString().substring(0, 10),
          clinician_date: new Date().toISOString().substring(0, 10),
          uid: this.props.authReducer.uid,
          pid: pid,
      }
      if(get_uri == 'edit_patient_contract' && result['data'] != -1){
        getData = {
          ...result['data'],
          clinician_date: new Date().toISOString().substring(0, 10),
          patient_date: new Date().toISOString().substring(0, 10),
          contract_version: this.props.match.params.contract_id,
          u_role: this.props.authReducer.role,
          patient_name: result['data'].fullname,
          uid: this.props.authReducer.uid,
          pid: pid,
        }
        if(result['data'].treatment_goal.length == 0) {
          getData = {
            ...getData,
            treatment_goal: [{
              id: 1,
              text: ''
            }],
          }
        }
        if(result['data'].support_team.length == 0) {
          getData = {
            ...getData,
            support_team: [{
              id: 1,
              role: 0,
              name: '',
              email: '',
            }],
          }
        }
        if(result['data'].signs_of_mania.length == 0) {
          getData = {
            ...getData,
            signs_of_mania: [{
              id: 1,
              val: 0,
              text: '',
            }],
          }
        }
        if(result['data'].support_dep_mania.length == 0) {
          getData = {
            ...getData,
            support_dep_mania: [{
              id: 1,
              action: 0,
              support: 0,
              text: '',
            }],
          }
        }
        if(result['data'].support_maintain.length == 0) {
          getData = {
            ...getData,
            support_maintain: [{
              id: 1,
              action: 0,
              support: 0,
              text: '',
            }],
          }
        }
        if(result['data'].suicide_contact.length == 0) {
          getData = {
            ...getData,
            suicide_contact: [{
              id: 1,
              support: 0,
            }],
          }
        }
        if(result['data'].signs_of_depression.length == 0) {
          getData = {
            ...getData,
            signs_of_depression: [{
              id: 1,
              val: 0,
              text: '',
            }],
          }
        }
        console.log(getData)
        if(get_uri == 'new_contract') {
          getData = {
            ...getData,
            u_role: this.props.authReducer.role,
            uid: this.props.authReducer.uid,
            pid: pid,
            patient_name: result['data'].fullname,
          }
        }
        /*if(this.props.authReducer.role == 3) {
          getData = {
            ...getData,
            enable_signature: true,
          }
        }*/
      } else if(result['data'] != -1) {
        getData = {
          ...result['data'],
          pid: pid,
          clinician_date: new Date().toISOString().substring(0, 10),
          patient_date: new Date().toISOString().substring(0, 10) }
        if(get_uri == 'new_contract') {
          getData = {...getData, clinician_name: ''}
        }
      }
      this.setState(getData)
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })

    /*Cookies.set(name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })*/
  }

  handleChange4 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })

    /*Cookies.set(name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })*/
  }

  handleSubmitSignature = (e) => {
    e.preventDefault()
    this.setState({
      enable_signature: true,
    })
  }

  handleSubmit = (e, click_status) => {
    e.preventDefault()
    var fields = [{
      field: 'treatment_goal',
      valSet: [{name: 'text', empVal: ''}]
    }, {
      field: 'support_team',
      valSet: [{name: 'role', empVal: 0}, {name: 'name', empVal: ''}, {name: 'email', empVal: ''}]
    }, {
      field: 'signs_of_depression',
      valSet: [{name: 'val', empVal: 0}]
    }, {
      field: 'signs_of_mania',
      valSet: [{name: 'val', empVal: 0}]
    }, {
      field: 'support_dep_mania',
      valSet: [{name: 'action', empVal: 0}]
    }, {
      field: 'support_maintain',
      valSet: [{name: 'action', empVal: 0}]
    }, {
      field: 'suicide_contact',
      valSet: [{name: 'support', empVal: 0}]
    }]

    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    get_uri = sep_uri[2]
    var get_uri2 = sep_uri[3]

    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    get_uri = sep_uri[sep_uri.length-1]

    var status_submit = false

    var signature = this.refs.mySignature
    var base64img = ''
    const formData = new FormData()
    var go_backend = false

    if(click_status == 'submit') {
      // Submit //
      base64img = signature.toDataURL("image/png")
      if(signature.isEmpty()) {
        this.setState({
          message: 'Please sign your signature',
        })
        window.scrollTo(0, 0)
      } else {
        status_submit = true
        formData.append('file', base64img)
        go_backend = true
      }
    } else {
      // Save //
      go_backend = true
    }

    var dataToSubmit = this.state
    dataToSubmit.update_status = click_status

    formData.append('pid', this.state.pid)
    formData.append('uid', this.props.authReducer.uid)

    formData.append('treatment_goal', this.state.treatment_goal)

    formData.append('u_role', this.props.authReducer.role)
    formData.append('patient_name', this.state.patient_name)
    formData.append('patient_date', this.state.patient_date)
    formData.append('clinician_date', this.state.clinician_date)

    formData.append('udata', JSON.stringify(dataToSubmit))
    formData.append('patient_contract_type', get_uri)
    formData.append('file', base64img)

    const edit_patient_contract_function = (changeUdata = false) => {
      if (changeUdata) {
       dataToSubmit = this.state
       formData.set('udata', JSON.stringify(dataToSubmit))
      }
      axios({
        method: 'post',
        url: connectNode + 'patient/enroll/edit_patient_contract',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
          //'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(this.state), //this.state,
        data: formData, //dataToSubmit,
      })
      .then((result) => {
        if(result['data'] == this.state.pid) {
          // Remove cookie //
          //this.removePcCk()
          if(get_uri2 == 'edit_patient_contract' && !this.state.new_version) {
            this.props.onLoadLocation('patient_chart', 'Edited patient contract successfully!')
            this.props.history.push('/admin/patient_chart/'+this.state.pid+'/3/1')
          } else {
            if(click_status == 'save') {
              this.props.onLoadLocation('patient_chart', 'Saved patient contract successfully!')
            } else {
              this.props.onLoadLocation('patient_chart', 'Submitted patient contract successfully!')
            }
            //this.props.onLoadLocation('patient_contract_list', 'Edited patient contract successfully!')
            get_uri2 == 'edit_patient_contract' ?
              this.props.history.push('/admin/patient_chart/'+this.state.pid+'/3/1')
              : this.props.history.push('/member/patient_contract_list')
          }
        }
      })
    };

    const add_patient_contract_function = (submit) => {
      axios({
        method: 'post',
        url: connectNode + 'patient/enroll/add_patient_contract',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data' //'application/json;charset=UTF-8'
          //'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(this.state), //this.state,
        data: formData,
        /*{
          data: this.state,
          update_status: click_status,
        }*/
      })
      .then((result) => {
          if(result['data'] == this.state.pid) {
            if (submit) {
              axios({
                method: 'post',
                url: connectNode + 'patient/enroll/get_latest_version_patient_contract',
                headers: {
                  'Accept': 'application/json',
                  // 'Content-Type': 'multipart/form-data' //'application/json;charset=UTF-8'
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { pid: this.state.pid }
              })
              .then((latest_contract) => {
                this.setState({ contract_id: latest_contract.data['id'], contract_version: latest_contract.data['contract_version'].toString() },
                () => edit_patient_contract_function(true));
              });
            } else {
              if(this.props.authReducer.role == 5) {
                this.props.onLoadLocation('patient_contract_list', 'Created new version of patient contract successfully!')
                this.props.history.push('/member/patient_contract_list')
              } else {
                this.props.onLoadLocation('patient_contract_list', 'Created new version of patient contract successfully!')
                this.props.history.push('/admin/patient_chart/'+this.state.pid+'/3/1')
              }
            }
          }
        }
      )
    };

    if(go_backend) {
      var get_uri = this.props.location.pathname
      var sep_uri = get_uri.split("/")
      get_uri = sep_uri[2]
      get_uri2 = sep_uri[3]
      if((get_uri == 'edit_patient_contract' || get_uri2 == 'edit_patient_contract') && !this.state.new_version) {
        edit_patient_contract_function();
      } else {
        add_patient_contract_function(click_status == 'submit' && this.state.new_version);
      }
    }

  }

  /*removePcCk = () => {
    Cookies.remove('treatment_goal')
    Cookies.remove('support_team')
    Cookies.remove('signs_of_mania')
    Cookies.remove('signs_of_depression')
    Cookies.remove('support_dep_mania')
    Cookies.remove('support_maintain')
    Cookies.remove('suicide_contact')
  }*/

  appendInput = (name, fields) => {
    var newInput = fields[fields.length-1] + 1 //`${name}_${fields.length}`
    this.setState(prevState => ({ [name]: prevState[name].concat([newInput]) }))
  }

  removeInput = (name, id, fields) => {
    const index = fields.indexOf(id);
    if (index > 0) {
      fields.splice(index, 1);
    }
    this.setState({ [name]: fields })
  }

  appendInputSupportTeam = (name, fields) => {
    var field_count = fields.length
    if(fields.length > 0) {
      field_count = field_count - 1
    }
    var newInput = fields[field_count]

    var newID = 1
    if(fields.length > 0) {
      newID = newInput.id + 1
    }
    //var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newID, role: 0, name: '', email: ''
        }])
      }
    ))
  }

  removeInputSupportTeam = (name, id, fields) => {
    const index = fields.indexOf(id);
    if (index > 0) {
      fields.splice(index, 1);
    }
    this.setState({ [name]: fields })
  }

  appendInputSet1 = (name, fields) => {
    var field_count = fields.length
    if(fields.length > 0) {
      field_count = field_count - 1
    }
    var newInput = fields[field_count]

    var newID = 1
    if(fields.length > 0) {
      newID = newInput.id + 1
    }
    var newInputID = newID
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, val: 0, text: ''
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  appendInputSet2 = (name, fields) => {
    if(fields.length < 4) {
      console.log(name)
      console.log(fields)
      var newInput = fields[fields.length-1]
      var newInputID = newInput.id + 1
      this.setState(prevState => (
        { [name]: prevState[name].concat([{
            id: newInputID, text: ''
          }])
        }
      ))
    }
  }

  appendInputSet3 = (name, fields) => {
    var field_count = fields.length
    if(fields.length > 0) {
      field_count = field_count - 1
    }
    var newInput = fields[field_count]

    var newID = 1
    if(fields.length > 0) {
      newID = newInput.id + 1
    }
    var newInputID = newID
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, action: 0, support: 0, text: '',
        }])
      }
    ))
  }

  transformDate = (getDate) => {
    var buff = getDate.split("/")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[2]+'-'+buff[0]+'-'+buff[1]
    }
    return toDate
  }

  render() {
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    get_uri = sep_uri[2]
    var get_uri2 = sep_uri[3]
    console.log(this.state.treatment_goal)
    return (
    <div className="patient_contract box_border4">
      <div className="row">
        <div className="small-12 columns headerPage1">
          FITT-BD Patient Contract
          {(get_uri == 'edit_patient_contract' || get_uri2 == 'edit_patient_contract') && ' '}
        </div>
      </div>
      { this.state.message !== '' &&
      <div className="row warning_msg6">
        <div className="small-12 columns ">
          <div className="float_left1"><WarningIcon /></div>
          <div className="txt_warning_msg1">{ this.state.message }</div>
        </div>
      </div>
      /*this.props.authReducer.role == 5 &&*/}
        {(this.state.progress == 1 || (this.state.progress == 2 && get_uri == 'update_patient_contract')
         || (this.state.contract_version > 1 && get_uri2 == 'new_contract') || this.state.new_version) &&
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border3 no_top_space">
              <form id="patient_contract" >
                {get_uri2 !== 'edit_patient_contract' &&
                <div className="row">
                  <div className="small-12 columns yellow_box1">
                    <p><b>Instructions for completing the Patient Contract:</b></p>
                    <p>The patient contract is an integral part of the FITT-BD program.
                    If possible, please complete it within the next seven days.
                    If you want to take a break while you are working on the contract,
                    remember to click on the SAVE button before leaving the page.
                    You may then return to this page by clicking on the Patient Contract
                    link on your dashboard. DO NOT CLICK SUBMIT UNTIL YOU ARE DONE.</p>
                    <p>When you have completed the contract, click on the SUBMIT button. Your clinician will then review the contract and discuss it with you at your next meeting.</p>
                  </div>
                </div>}
                <div className="row">
                  <div className="small-12 columns">
                    <p><b>AS A PATIENT</b> at the FITT-BD clinic,
                    I, {this.state.fullname}, voluntarily accept the following
                    Patient Contract to guide my care with the support of
                    my treatment team. I understand that this contract can be
                    added to or otherwise modified as my treatment evolves
                    over time.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="small-12 columns box1 treatment_goal">
                    <p className="title_topic1"><span className="bullet_dot">•</span> Treatment Goals</p>
                    <p className="p_bottom_025">
                    During the time I am a patient at the FITT-BD Clinic, I will work towards the following goals:</p>
                    <br />Please write in at least two and up to four goals, starting with your most important goals:<br />
                    <div id="dynamicInput">
                       {this.state.treatment_goal.map((val, index) =>
                         <div key={"treatment_goal"+val.id} >
                          <TextField id={"treatment_goal"+val.id} label={(index+1)+'.'}
                          onChange={(e) => this.handleChange3(e, 'treatment_goal', index, 'text')}
                          value={val.text}
                          variant="outlined" inputProps={{ maxLength: 200 }} />
                          {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('treatment_goal', val.id, this.state.treatment_goal) } />}
                        </div>
                      )}
                   </div>
                   {this.state.treatment_goal.length < 4 &&
                   <div>
                      <button type="button" className="addInput1"
                        onClick={ () => this.appendInputSet2('treatment_goal', this.state.treatment_goal) }>
                         Click HERE to add more goals
                      </button>
                  </div>}
                </div>
              </div>
              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1"><span className="bullet_dot">•</span> Support Team</p>
                  <p>
                  My support team can include any of my providers, as well as family members,
                  friends, and others who I trust with my care.</p>
                  <p>I have chosen the following as members of my support team:</p>
                  <p>Choose "ROLE" from drop-down menu. You may choose as many as you wish.
                  Write in names and phone numbers or email addresses.</p>
                  <div id="dynamicInput">
                    {this.state.support_team.map((val, index) =>
                    <div className="row" key={"sp"+val.id} >
                      <div className="small-12 medium-4 columns">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Role</InputLabel>
                          <Select value={this.state.support_team[index].role} onChange={(e) => this.handleChange4(e, 'support_team', index, 'role')} label="Role">
                            {role.map(each =>
                              <MenuItem key={"spit"+val.id+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="small-12 medium-4 columns">
                        <TextField label="Name" onChange={(e) => this.handleChange3(e, 'support_team', index, 'name')}
                        variant="outlined" inputProps={{maxLength: 60}} value={this.state.support_team[index].name}/>
                        {/*value={this.state.support_team[val.id].name}*/}
                      </div>
                      <div className="small-12 medium-4 columns">
                        <TextField label="Email address/phone number" name={"spt"+val.id} onChange={(e) => this.handleChange3(e, 'support_team', index, 'email')} variant="outlined" inputProps={{ maxLength: 60 }} className="supprt_team_email" value={this.state.support_team[index].email} />
                        {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('support_team', val.id, this.state.support_team) } />}
                      </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button type="button" className="addInput1"
                       onClick={ () => this.appendInputSupportTeam('support_team', this.state.support_team) }>
                        Click HERE to add another support person
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns monitor_my_mood box1">
                  <p className="title_topic1"><span className="bullet_dot">
                  •</span> In order to monitor my moods, I will watch out for the following signs:</p>
                  <p>Choose as many as you wish from the drop-down menus and/or write in your own.</p>
                  <p className="p_bottom_025">
                    <b><ArrowRightIcon className="icon_1" /> <span className="title_name1">Signs of Depression</span></b>
                  </p>
                  <div id="dynamicInput" className="signs_of_depression">
                    {this.state.signs_of_depression.map((val, index) =>
                    <div className="row" key={"sod"+val.id} >
                      <div className="small-12 medium-6 columns">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Signs of Depression</InputLabel>
                          <Select value={this.state.signs_of_depression[index].val} onChange={(e) => this.handleChange4(e, 'signs_of_depression', index, 'val')}
                            label="Signs of Depression" >
                            {signs_of_depression.map(each =>
                              <MenuItem key={"sodit"+val.id+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        </div>
                        <div className="small-12 medium-6 columns">
                          { this.state.signs_of_depression[index].val > 0 &&
                          <TextField label="Specify" onChange={(e) => this.handleChange3(e, 'signs_of_depression', index, 'text')}
                          variant="outlined" inputProps={{ maxLength: 200 }}
                          value={val.text} className="signs_of_depression_text" />}
                          {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('signs_of_depression', val.id, this.state.signs_of_depression) } />}
                        </div>
                      </div>
                    )}
                    </div>
                    <div className="relative_float_left">
                      <button type="button" className="addInput1"
                         onClick={ () => this.appendInputSet1('signs_of_depression', this.state.signs_of_depression) }>
                          Add Signs of Depression
                      </button>
                    </div>
                    <div className="line1">&nbsp;</div>
                    <p className="p_bottom_025">
                      <b><ArrowRightIcon className="icon_1" /> <span className="title_name1">Signs of (Hypo)mania</span></b>
                    </p>
                    <div id="dynamicInput" className="signs_of_depression">
                      {this.state.signs_of_mania.map((val, index) =>
                      <div className="row" key={"som"+val.id} >
                        <div className="small-12 medium-6 columns">
                          <FormControl variant="outlined" >
                            <InputLabel id="demo-customized-select-label">Signs of (Hypo)mania</InputLabel>
                            <Select value={this.state.signs_of_mania[index].val} onChange={(e) => this.handleChange4(e, 'signs_of_mania', index, 'val')}
                              label="Signs of (Hypo)mania">
                              {signs_of_mania.map(each =>
                                <MenuItem key={"somit"+val.id+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="small-12 medium-6 columns">
                          { this.state.signs_of_mania[index].val > 0 &&
                          <TextField id="" label="Specify" value={val.text}
                          onChange={(e) => this.handleChange3(e, 'signs_of_mania', index, 'text')}
                          variant="outlined" inputProps={{ maxLength: 200 }} className="signs_of_depression_text" />}
                          {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('signs_of_mania', val.id, this.state.signs_of_mania) } />}
                        </div>
                      </div>
                      )}
                    </div>
                    <div className="relative_float_left">
                      <button type="button" className="addInput1"
                         onClick={ () => this.appendInputSet1('signs_of_mania', this.state.signs_of_mania) }>
                          Add Signs of (Hypo)mania
                      </button>
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team_member box1">
                  <p className="title_topic1"><span className="bullet_dot">
                  •</span> If I notice signs of depression or mania, I will:</p>
                  <p className="p_bottom_025">
                  Choose as many as you wish from the drop-down menu and/or write in your own.</p>
                  <p>If a person on your support team can help, please choose that person
                  from the drop-down menu of support-team members.</p>
                  <div id="dynamicInput" className="signs_dep_mania">
                    {this.state.support_dep_mania.map((val, index) =>
                    <div className="row" key={"adm"+val.id} >
                      <div className="small-12 medium-6 columns">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Action</InputLabel>
                          <Select value={this.state.support_dep_mania[index].action} onChange={(e) => this.handleChange4(e, 'support_dep_mania', index, 'action')}
                            label="Action" >
                            {action.map(each =>
                              <MenuItem key={"admit"+val.id+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="small-12 medium-6 columns support_person">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Support Person</InputLabel>
                          <Select value={this.state.support_dep_mania[index].support} onChange={(e) => this.handleChange4(e, 'support_dep_mania', index, 'support')}
                            label="Support Person" >
                              <MenuItem value={0}>-</MenuItem>
                            {this.state.support_team.map(each =>
                              <MenuItem key={"spmania"+each.id} value={each.id}>{each.name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('support_dep_mania', val.id, this.state.support_dep_mania) } />}
                      </div>
                      { this.state.support_dep_mania[index].action === 13 &&
                      <div className="small-12 columns top_space">
                        <TextField id="" label="Specify hospital"
                        onChange={(e) => this.handleChange3(e, 'support_dep_mania', index, 'text')}
                        variant="outlined" inputProps={{ maxLength: 200 }}
                        value={this.state.support_dep_mania[index].text}
                        className="signs_of_depression_text" />
                      </div>}
                      { this.state.support_dep_mania[index].action === 14 &&
                      <div className="small-12 columns top_space">
                        <TextField id="" label="Specify" value={this.state.support_dep_mania[index].text}
                        onChange={(e) => this.handleChange3(e, 'support_dep_mania', index, 'text')} variant="outlined" inputProps={{ maxLength: 200 }} className="signs_of_depression_text" />
                      </div>}
                    </div>
                    )}
                  </div>
                  <div className="relative_float_left">
                    <button type="button" className="addInput1"
                       onClick={ () => this.appendInputSet3('support_dep_mania', this.state.support_dep_mania) }>
                        Add Action
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team_member box1">
                  <p className="title_topic1">
                  <span className="bullet_dot">•</span>
                  To maintain my mood in a desired range throughout my treatment program, I will:</p>
                  <p className="p_bottom_025">
                  Choose as many as you wish from the drop-down menu and/or write in your own.</p>
                  <p>If a person on your support team can help, please choose that person from the drop-down menu of support-team members.</p>
                  <div id="dynamicInput" className="signs_maintain">
                    {this.state.support_maintain.map((val, index) =>
                    <div className="row" key={"am"+val.id} >
                      <div className="small-12 medium-6 columns">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Action</InputLabel>
                          <Select value={this.state.support_maintain[index].action} onChange={(e) => this.handleChange4(e, 'support_maintain', index, 'action')}
                            label="Action" >
                            {maintain_action.map(each =>
                              <MenuItem key={"mtait"+val.id+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="small-12 medium-6 columns support_person">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Support Person</InputLabel>
                          <Select value={this.state.support_maintain[index].support} onChange={(e) => this.handleChange4(e, 'support_maintain', index, 'support')}
                            label="Support Person" >
                              <MenuItem value={0}>-</MenuItem>
                            {this.state.support_team.map(each =>
                              <MenuItem key={"amtit"+each.id} value={each.id}>{each.name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('support_maintain', val.id, this.state.support_maintain) } />}
                      </div>
                      { this.state.support_maintain[index].action === 9 &&
                      <div className="small-12 columns top_space">
                        <TextField id="" label="Specify" value={this.state.support_maintain[index].text}
                        onChange={(e) => this.handleChange3(e, 'support_maintain', index, 'text')} variant="outlined" inputProps={{ maxLength: 200 }} className="signs_of_depression_text" />
                      </div>}
                    </div>
                    )}
                  </div>
                  <div className="relative_float_left">
                    <button type="button" className="addInput1"
                       onClick={ () => this.appendInputSet3('support_maintain', this.state.support_maintain) }>
                        Add Action
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns suicide_contact box1">
                  <p className="title_topic1_1">
                  <span className="bullet_dot">•</span>
                  If I have strong suicidal thoughts, I will contact my clinician and support person</p>
                  <div id="dynamicInput" className="suicide_contact">
                    {this.state.suicide_contact.map((val, index) =>
                    <div className="row" key={"scc"+val.id} >
                      <div className="small-12 columns">
                        <FormControl variant="outlined" >
                          <InputLabel id="demo-customized-select-label">Support Person</InputLabel>
                          <Select value={val.support}
                            onChange={(e) => this.handleChange4(e, 'suicide_contact', index, 'support')}
                            label="Support Person" >
                              <MenuItem value={0}></MenuItem>
                            {this.state.support_team.map(each =>
                              <MenuItem key={"sccit"+each.id} value={each.id}>{each.name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns box1">
                  <p className="title_topic1_1"><span className="bullet_dot">•</span>
                  If I fear I will act on suicidal thoughts, I will report to the emergency room.</p>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns">
                  <p><b>AS TREATMENT TEAM MEMBERS</b> for {this.state.fullname}, we agree to:</p>
                  <div>
                    <ul>
                      <li>Commit to partner with and support {this.state.fullname} in reaching [her/his/their] chosen goals.</li>
                      <li>Commit to working with {this.state.fullname} as an "expert by experience" and equal partner in making decisions about treatment. </li>
                      <li>Make every effort to arrive on time for all appointments.</li>
                      <li>Reply to any contact or correspondence from {this.state.fullname} within two business days.</li>
                    </ul>
                  </div>
                  <p className="margin_top_2rem">
                    We agree to the terms of this contract, which we have read and understand as our responsibilities as a patient/support person/treatment team member at the FITT-BD.
                  </p>
                </div>
              </div>

              {this.state.enable_signature == true &&
              <div className="row">
                <div className="small-12 medium-6 columns show-for-medium">&nbsp;</div>
                <div className="small-12 medium-6 columns show-for-medium yellow1">
                  Please add signature and click Submit again.
                </div>
              </div>
              }

              <div className="row signature">
                <div className="small-12 medium-6 columns show-for-medium">&nbsp;</div>
                {this.state.enable_signature == true &&
                <div className="small-12 medium-6 columns show-for-medium">
                  <div className="relative_float_left">
                  { (get_uri == 'edit_patient_contract' || get_uri == 'new_contract' || get_uri == 'update_patient_contract') &&
                  <TextField id="patient_name" label="Patient Name" onChange={this.handleChange}
                  variant="outlined" value={this.state.patient_name}
                  InputProps={{ maxLength: 60, inputProps: { name: "patient_name" } }} />}
                  { (get_uri2 == 'edit_patient_contract' || get_uri2 == 'new_contract') &&
                  <TextField id="clinician_name" label="Clinician Name" onChange={this.handleChange}
                  variant="outlined" value={this.state.clinician_name}
                  InputProps={{ maxLength: 60, inputProps: { name: "clinician_name" } }} />}
                  </div>
                  <div className="row title_signature">
                    <div className="small-6 columns">
                    {this.props.authReducer.role == 5 && 'Patient Signature'}
                    {this.props.authReducer.role >=1 && this.props.authReducer.role <=3 && 'Clinician Signature'}
                    </div>
                    <div className="small-6 columns align-right">
                      <button type="button" className="clear_button"
                         onClick={ () => this.refs.mySignature.clear() }>
                          Clear
                      </button>
                    </div>
                  </div>
                  <div className="relative_float_left">
                      <SignatureCanvas penColor='#555555' canvasProps={{className: 'signatureCanvas'}}
                       ref="mySignature" />
                  </div>
                  <div className="relative_float_left">
                  { (get_uri == 'edit_patient_contract' || get_uri == 'new_contract' || get_uri2 == 'new_contract') &&
                    <TextField id="patient_date" label="Date" onChange={this.handleChange2}
                    variant="outlined" type="date"
                    format={'MM/DD/YYYY'}
                    value={this.transformDate(this.state.patient_date)}
                    InputProps={{ inputProps: { name: "patient_date" }}} />
                  }
                  { (get_uri2 == 'edit_patient_contract') &&
                    <TextField id="clinician_date" onChange={this.handleChange2}
                    variant="outlined" type="date"
                    format={'MM/DD/YYYY'}
                    value={this.state.clinician_date}
                    InputProps={{ inputProps: { name: "clinician_date" }}} />
                  }{ get_uri == 'update_patient_contract' &&
                    <TextField id="patient_date" label="Date" onChange={this.handleChange2}
                    variant="outlined" type="date"
                    defaultValue={this.transformDate(this.state.patient_date)}
                    InputProps={{ inputProps: { name: "patient_date" }}} />
                  }
                  </div>
                </div>}
              </div>

              <div className="row top_space">
                <div className="small-12 columns align_right">
                  {(this.props.authReducer.role == 5 || this.props.authReducer.role == 2 || this.props.authReducer.role == 3 || this.state.new_version) &&
                  <Button variant="contained" color="primary" disableElevation
                   onClick={(e) => this.handleSubmit(e, 'save')} type="submit" className="submit_1 margin-right1">
                    Save
                  </Button>}

                  {(((this.props.authReducer.role == 2 || this.props.authReducer.role == 3) && !this.state.new_version) ||
                    this.props.authReducer.role == 5)
                    && this.state.enable_signature == false &&
                  <Button variant="contained" color="primary" disableElevation
                   onClick={(e) => this.handleSubmitSignature(e)} type="button" className="submit_1">
                    Submit
                  </Button>}

                  {this.state.enable_signature == true &&
                    <Button variant="contained" color="primary" disableElevation
                   onClick={(e) => this.handleSubmit(e, 'submit')} type="submit" className="submit_1">
                    Submit
                  </Button>}
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>}
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientContract)
