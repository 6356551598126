import React, { Component } from 'react'
import '../../../styles/assets/css/pages/stepper1.css'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { baseline_steps, discharge_steps } from '../variables/baseline.js'
import { weekly_steps, monthly_steps, trimonthly_steps, yearly_steps } from '../variables/assessments.js'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

class StepperAssm extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          tracker_type: 1,
          day_mood: 1,
          day_sleep: 1,
          day_medication: 1,
          day_exercise: 1,
          day_anxiety: 1,
          week_phq4: 1,
          week_who5: 1,
          month_phq9: 1,
          month_asrm: 1,
          month_gad7: 1,
          month_psqi: 1,
          month_mtf: 1,
          three_month_covid: 1,
          twelve_month_well_being: 1,
          twelve_month_ders: 1,
          steps: [],
          previous_steps: [],
          activeStep: 0,
          previous_activeStep: 0,
      }
  }

  componentDidMount() {
    var steps = []
    if(this.props.timepoint == -1) {
      steps = baseline_steps
      this.setState({
        steps: steps,
        activeStep: this.props.activeStep,
      })
    } else if(this.props.timepoint == -2) {
      steps = discharge_steps
      this.setState({
        steps: steps,
        activeStep: this.props.activeStep,
      })
    } else {
      if(this.props.type == 'w') {
        steps = this.getAssmStatus()
      } else if(this.props.type == 'm') {
        steps = this.getAssmStatus()
      } else if(this.props.type == 'tm') {
        steps = this.getAssmStatus()
      } else if(this.props.type == 'y') {
        steps = this.getAssmStatus()
      }
    }
  }

  getAssmStatus = () => {
    var arr_tp = []
    var activeStep = this.props.activeStep
    // Check enable tracker //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_assessments_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid:  this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(typeof result['data'].uid != undefined && this.props.type == 'w') {
        arr_tp = ['WHO-5']
      } else if (this.props.type == 'm') {
        arr_tp = ['Overview']
        if(typeof result['data'].uid != undefined ) {
          if(result['data'].m_phq9 == 1) {
            arr_tp.push('PHQ-9')
          }
          if(result['data'].m_asrm == 1) {
            arr_tp.push('ASRM')
          }
          if(result['data'].m_gad7 == 1) {
            arr_tp.push('GAD-7')
          }
          if(result['data'].m_psqi == 1) {
            arr_tp.push('PSQI')
          }
          if(result['data'].m_who5 == 1) {
            arr_tp.push('WHO-5')
          }
          if(result['data'].m_mtf == 1) {
            arr_tp.push('MTF')
          }
        } else {
          arr_tp = ['Overview', 'PHQ-9', 'ASRM', 'GAD-7', 'PSQI', 'WHO-5', 'MTF',]
        }
        activeStep = arr_tp.indexOf(this.props.assm)
      } else if(typeof result['data'].uid != undefined && this.props.type == 'tm') {
        arr_tp = []
        if(typeof result['data'].uid != undefined ) {
          if(result['data'].twelve_m_well_being == 1) {
            arr_tp.push('PWB')
          }
          if(result['data'].m_providers== 1) {
            arr_tp.push('Providers')
          }
        } else {
          arr_tp = ['PWB', 'Providers']
        }
        activeStep = arr_tp.indexOf(this.props.assm)
      } else if(typeof result['data'].uid != undefined && this.props.type == 'y') {
        arr_tp = ['DERS-18']
      }
      //console.log((this.props.assm))
      //console.log(arr_tp)
      this.setState({
        steps: arr_tp,
        activeStep: activeStep,
      })

      // Check if completed more assm than the enable tracker //
      //console.log(this.props.timepoint)
      /*if(this.props.type == 'm') {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/get_assm_data_whole',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            timepoint: this.props.timepoint,
            type: this.props.type,
           }
        })
        .then((result) => {
          if(result['data'].status == 2 ) {
            arr_tp = ['Overview']
            if(result['data'].phq9_id > 0) {
              arr_tp.push('PHQ-9')
            }
            if(result['data'].asrm_id > 0) {
              arr_tp.push('ASRM')
            }
            if(result['data'].gad7_id > 0) {
              arr_tp.push('GAD-7')
            }
            if(result['data'].psqi_id > 0) {
              arr_tp.push('PSQI')
            }
            if(result['data'].who5_id > 0) {
              arr_tp.push('WHO-5')
            }
            if(result['data'].pcp_name != '') {
              arr_tp.push('Providers')
            }
            if(result['data'].mtf_id > 0) {
              arr_tp.push('MTF')
            }
            activeStep = arr_tp.indexOf(this.props.assm)
            //console.log(arr_tp)
            this.setState({
              steps: arr_tp,
              activeStep: activeStep,
            })
          }
        })
      }*/
    })
  }

  render() {
    return (
      <div className="small-12 columns stepper">
        <Stepper alternativeLabel nonLinear activeStep={this.state.activeStep || this.state.previous_activeStep}>
          {this.state.steps && this.state.steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StepperAssm)
