import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import '../../../styles/assets/css/pages/form4.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { calculateTimepoint1 } from '../../functions/timepoint.js'
import StepperAssm from './stepper_assm'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { q5_questions, q5_choices, q6_choices, q7_choices, q9_choices, q10_choices,
         q10_add_questions, q10_add_choices } from '../variables/assessments/psqi.js'

class Psqi extends Component {
  constructor() {
      super()
      this.state = {
          status: 1,
          message: '',
          tracker_steps: 7,
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'who5',
          q1: '',
          q2: '',
          q3: '',
          q4: '',
          q51: -1,
          q52: -1,
          q53: -1,
          q54: -1,
          q55: -1,
          q56: -1,
          q57: -1,
          q58: -1,
          q59: -1,
          q510: -1,
          q510_other: '',
          q6: -1,
          q7: -1,
          q8: -1,
          q9: -1,
          q10: -1,
          q101: -1,
          q102: -1,
          q103: -1,
          q104: -1,
          q105: -1,
          q105_other: '',
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmData = (set_status) => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: this.props.match.params.type,
        timepoint: this.props.match.params.timepoint,
        frm: 'psqi',
       }
    }).then((result) => {
      //console.log(result['data'])
      if(result['data'].end_timestamp > 0) {
        this.setState({
          assm_status: set_status,
          status: set_status,
          tracker_steps: 4,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q51: result['data'].q51,
          q52: result['data'].q52,
          q53: result['data'].q53,
          q54: result['data'].q54,
          q55: result['data'].q55,
          q56: result['data'].q56,
          q57: result['data'].q57,
          q58: result['data'].q58,
          q59: result['data'].q59,
          q510: result['data'].q510,
          q510_other: result['data'].q510_other,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          q9: result['data'].q9,
          q10: result['data'].q10,
          q101: result['data'].q101,
          q102: result['data'].q102,
          q103: result['data'].q103,
          q104: result['data'].q104,
          q105: result['data'].q105,
          q105_other: result['data'].q105_other,
        })
      } else {
        if(set_status == 3) {
          this.setState({
            status: set_status
          })
        }
      }
    })
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    if(timepoint == -1) {
      //console.log('---')
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_baseline_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { uid: this.props.authReducer.uid }
      })
      .then((result) => {
        //console.log('+++ '+result['data'])
          if(result['data'] !== 'psqi') {
            this.props.history.push('/member/assessment/'+result['data']+'/'+this.props.match.params.type+'/'+timepoint)
          } else {
            this.setState({
              tracker_steps: 7,
              next_step: 'complete_baseline',
            })
          }
        }
      )
    } else if(timepoint == -2)  {
      this.setState({
        next_step: 'who5',
        tracker_steps: 4,
      })
      this.getAssmData(2)
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/get_assessments_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid:  this.props.authReducer.uid,
        }
      })
      .then((result) => {
        var next_step = 'who5'
        if(result['data'].m_who5 == 0) {
          next_step = 'providers'
          if(result['data'].m_providers == 0) {
            next_step = 'mtf'
            if(result['data'].m_mtf == 0) {
              next_step = 'complete_assessments'
            }
          }
        }
        this.setState({
          next_step: next_step,
        })
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/chk_each_assm',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          assm: 'patients_assessment_psqi',
         }
      })
      .then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        //console.log(calTimepoint)
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month
                 || this.props.match.params.timepoint <= -9) {
          // Current timepoint => Fill the form //
          if(result['data'].m_psqi == 0) {
            axios({
              method: 'post',
              url: connectNode + 'patient/assessment/update_skip_step',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.props.authReducer.uid,
                type: this.props.match.params.type,
                timepoint: this.props.match.params.timepoint,
                update_step: 'who5',
               }
            })
            .then((result) => {
              this.props.history.push('/member/assessment/who5/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            })
          }
          if(result['data'].status !== null) {

            if(result['data'].assm_status == 1) {
              // Completed //
              this.getAssmData(2)
            } else {
              if(result['data'].status == 1 && result['data'].step !== 'psqi') {
                this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
              }
            }
          } else {
            // Back up overview //
            this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }
        } else {
          // Past timepoint //
          this.getAssmData(3)
        }
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
    //console.log(e.target.name+": "+this.state.[e.target.name])
  }

  handleChange2_1 = (e) => {
    if(e.target.value >= 0 && e.target.value <= 24) {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  handleChange2_2 = (e) => {
    if(e.target.value >= 0) {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  handleChange3 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = 'who5'
    if(timepoint == -1) {
      next_step = 'complete_baseline'
    } else if(timepoint == -2) {
      next_step = 'who5'
    }
    if(this.state.status === 1) {
      if(this.state.q1 === '' || this.state.q2 === '' || this.state.q3 === '' ||
        this.state.q4 === '' || this.state.q51 == -1 || this.state.q52 == -1 ||
        this.state.q53 == -1 || this.state.q54 == -1 || this.state.q55 == -1 ||
        this.state.q56 == -1 || this.state.q57 == -1 || this.state.q58 == -1 ||
        this.state.q59 == -1 ||
        (this.state.q510 == -1 && this.state.q510_other !== '') ||
        this.state.q6 == -1 || this.state.q7 == -1 || this.state.q8== -1 ||
        this.state.q9 == -1 || this.state.q10 == -1 ) {
        this.setState({
          message: 'Please select or fill in answers to all questions.',
        })
        window.scrollTo(0, 0)
      } else {
        if(this.state.q10 >2 && (this.state.q101 == -1 || this.state.q102 == -1 ||
        this.state.q103 == -1 || this.state.q104 == -1
        || (this.state.q105 == -1 && this.state.q105_other !== ''))) {
          this.setState({
            message: 'Please select or fill in answers to all questions.',
          })
          window.scrollTo(0, 0)
        } else {
          axios({
            method: 'post',
            url: connectNode + 'patient/assessment/add_psqi',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: this.props.authReducer.uid,
              user: this.state,
              type: type,
              timepoint: this.props.match.params.timepoint,
            }
          })
          .then((result) => {
            //console.log(result['data'])
            if(result['data'] === next_step) {
              this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
              this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
            }
          })
        }
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {
    //console.log(this.state)
    return (
      <div className="psqi box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          Pittsburgh Sleep Quality Index (PSQI)
          </div>
          {//this.state.status == 1 &&
          <StepperAssm activeStep={this.state.tracker_steps} assm={'PSQI'} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box1">
            <div className="row">
              <div className="small-12 columns">
                <b>Instructions:</b> The following questions relate to your usual sleep
                habits during the PAST MONTH only. Your answers should indicate the most
                accurate reply for the majority of days and nights in the past month.
                Please answer all questions.<br/><br/>
                <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
              </div>
              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>

              <div className="small-12 columns bold">
                  1. During the past month, when have you usually gone to bed at night?
              </div>
              <div className="small-12 columns top_space2">
                <ArrowRightIcon />  Usual bed time:
                <TextField label="" name="q1" onChange={(e) => this.handleChange(e)}
                  variant="outlined" defaultValue={this.state.q1} value={this.state.q1}
                  disabled={this.state.status > 1 && 'disabled'} type="time"
                  InputProps={{ inputProps: { name: "q1" }}} />
              </div>
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>

              <div className="small-12 columns bold">
                  2. During the past month, how long (in minutes) has it usually take you to fall asleep each night?
              </div>
              <div className="small-12 columns top_space2">
                <ArrowRightIcon /> Number of minutes:
                <TextField label="" name="q2" type="number"
                  onChange={(e) => this.handleChange2_2(e)} value={this.state.q2}
                  variant="outlined" defaultValue={this.state['q2']}
                  disabled={this.state.status > 1 && 'disabled'}
                  InputProps={{ maxLength: 3 }}  />
              </div>
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>

              <div className="small-12 columns bold">
                  3. During the past month, when have you usually gotten up in the morning?
              </div>
              <div className="small-12 columns top_space2">
                <ArrowRightIcon /> Usual getting up time:
                <TextField label="" name="q3" onChange={(e) => this.handleChange(e)}
                variant="outlined" defaultValue={this.state.q3} type="time"
                disabled={this.state.status > 1 && 'disabled'} value={this.state.q3}
                InputProps={{ inputProps: { name: "q3" }}}  />
              </div>
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>

              <div className="small-12 columns bold">
                  4. During the past month, how many hours of actual sleep did you get a night? (This may be different than the number of hours you spend in bed.)
              </div>
              <div className="small-12 columns top_space2">
                <ArrowRightIcon /> Hours of sleep per night:
                <TextField label="" name="q4" type="number"
                  onChange={(e) => this.handleChange2_1(e)} variant="outlined"
                  InputProps={{ maxLength: 3, inputProps: { min: 0, max: 24 } }}
                  defaultValue={this.state['q4']} value={this.state.q4}
                  disabled={this.state.status > 1 && 'disabled'} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns box1">
            <div className="row">
              <div className="small-12 columns">
                <b>Instructions:</b> For each of the remaining questions, check
                the one best response. Please answer all questions.
              </div>
              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>

              <div className="small-12 columns bold">
                  5. During the past month, how often have you had trouble sleeping because you...
              </div>
              <div className="small-12 columns top_space2">
                <div className="row show-for-medium">
                  <div className="medium-4 columns">&nbsp;</div>
                  {q5_choices.map(each =>
                    <div className="medium-2 columns" key={'q5'+each.value}>
                      {each.label}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  {q5_questions.map(eachQ =>
                    <div className="" key={"q5"+eachQ.value} >
                      <div className="small-12 medium-4 columns">
                        {eachQ.label}
                        {eachQ.value==10 &&
                        <TextField label="" name="q510_other" className="q510_other"
                          onChange={(e) => this.handleChange2(e)} variant="outlined"
                          value={this.state.q510_other}
                          disabled={this.state.status > 1 && 'disabled'}
                          InputProps={{ maxLength: 100 }}  />}
                      </div>
                      {q5_choices.map(each =>
                        <div className="small-12 medium-2 align_center columns" key={"q5"+eachQ.value+"_"+each.value} >
                          <input type="radio" name={"q5"+eachQ.value} value={each.value}
                          checked={this.state["q5"+eachQ.value] == each.value && 'checked'}
                          disabled={this.state.status > 1 && 'disabled'}
                          onChange={(e) => this.handleChange(e)} />
                          <span className="show-for-small-only">{each.label}</span>
                        </div>
                      )}
                      <div className="small-12 columns">
                        <div className="sep_line2">&nbsp;</div>
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="small-12 medium-4 columns">
                      (j) Other reason(s), please describe
                    </div>
                    <div className="small-12 medium-8 columns">
                      <TextField label="" name="q510_other" className="q510_other"
                        onChange={(e) => this.handleChange2(e)} variant="outlined"
                        value={this.state.q510_other}
                        disabled={this.state.status > 1 && 'disabled'}
                        InputProps={{ maxLength: 100 }}  />
                    </div>
                    <div className="small-12 medium-4 columns">
                      How often during the past month have you had trouble sleeping because of this?
                    </div>
                    {q5_choices.map(each =>
                      <div className="small-12 medium-2 align_center columns top_space1" key={"q510"+"_"+each.value} >
                        <input type="radio" name={"q510"} value={each.value}
                        checked={this.state.q510 == each.value && 'checked'}
                        disabled={this.state.status > 1 && 'disabled'}
                        onChange={(e) => this.handleChange(e)} />
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
              <div className="small-12 medium-4 columns bold">
                6. During the past month, how would you rate your sleep quality overall?
              </div>
              {q6_choices.map(each =>
              <div className="small-12 medium-2 align_center columns">
                <span className="new_line show-for-medium">{each.label}</span>
                <input type="radio" name="q6" value={each.value}
                checked={this.state['q6'] == each.value && 'checked'}
                disabled={this.state.status > 1 && 'disabled'}
                onChange={(e) => this.handleChange(e)}/>
                <span className="new_line show-for-small-only">{each.label}</span>
              </div>
              )}

              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
              <div className="small-12 columns top_space2">
                <div className="row show-for-medium">
                  <div className="medium-4 columns">&nbsp;</div>
                  {q7_choices.map(each =>
                    <div className="medium-2 columns" key={"q7"+each.value} >
                      {each.label}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
                  <div className="small-12 medium-4 columns bold">
                    7. During the past month, how often have you taken medicine
                    (prescribed or "over the counter") to help you sleep?
                  </div>
                  {q7_choices.map(each =>
                    <div className="small-12 medium-2 align_center columns" key={"q7c"+"_"+each.value} >
                      <input type="radio" name="q7" value={each.value}
                      checked={this.state['q7'] == each.value && 'checked'}
                      disabled={this.state.status > 1 && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                  )}
                  <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
                  <div className="small-12 medium-4 columns bold">
                    8. During the past month, how often have you had trouble staying
                    awake while driving, eating meals, or engaging in social activity?
                  </div>
                  {q7_choices.map(each =>
                    <div className="small-12 medium-2 align_center columns" key={"q8"+"_"+each.value}>
                      <input type="radio" name="q8" value={each.value}
                      checked={this.state['q8'] == each.value && 'checked'}
                      disabled={this.state.status > 1 && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
              <div className="small-12 medium-4 columns bold">
                9. During the past month, how much of a problem has it been for you
                to keep up enough enthusiasm to get things done?
              </div>
              {q9_choices.map(each =>
              <div className="small-12 medium-2 align_center columns">
                <span className="new_line show-for-medium">{each.label}</span>
                <input type="radio" name="q9" value={each.value}
                checked={this.state['q9'] == each.value && 'checked'}
                disabled={this.state.status > 1 && 'disabled'}
                onChange={(e) => this.handleChange(e)}/>
                <span className="new_line show-for-small-only">{each.label}</span>
              </div>
              )}

              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
              <div className="small-12 medium-4 columns bold">
                10. Do you have a bed partner or room mate?
              </div>
              {q10_choices.map(each =>
              <div className="small-12 medium-2 align_center columns">
                <span className="new_line show-for-medium">{each.label}</span>
                <input type="radio" name="q10" value={each.value}
                checked={this.state['q10'] == each.value && 'checked'}
                disabled={this.state.status > 1 && 'disabled'}
                onChange={(e) => this.handleChange(e)}/>
                <span className="new_line show-for-small-only">{each.label}</span>
              </div>
              )}

              { this.state.q10 >2 &&
              <div>
                <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                <div className="small-12 columns bold">
                  <ArrowRightIcon />  If you have a roommate or bed partner, ask him/her how often in the past month you have had...
                </div>
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
                <div className="small-12 columns top_space2">
                  <div className="row show-for-medium">
                    <div className="medium-4 columns">&nbsp;</div>
                    {q10_add_choices.map(each =>
                      <div className="medium-2 columns">
                        {each.label}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>
                    {q10_add_questions.map(eachQ =>
                      <div className="">
                        <div className="small-12 medium-4 columns">
                          {eachQ.label}
                          {eachQ.value==5 &&
                          <TextField label="" name="q105_other" className="q105_other"
                            onChange={(e) => this.handleChange2(e)} variant="outlined"
                            disabled={this.state.status > 1 && 'disabled'}
                            InputProps={{ maxLength: 100 }} value={this.state.q105_other} />}
                        </div>
                        {q10_add_choices.map(each =>
                          <div className="small-12 medium-2 align_center columns">
                            <input type="radio" name={"q10"+eachQ.value} value={each.value}
                            checked={this.state["q10"+eachQ.value] == each.value && 'checked'}
                            disabled={this.state.status > 1 && 'disabled'}
                            onChange={(e) => this.handleChange(e)}/>
                            <span className="show-for-small-only">{each.label}</span>
                          </div>
                        )}
                        {eachQ.value < 5 &&
                        <div className="small-12 columns">
                          <div className="sep_line2">&nbsp;</div>
                        </div>}
                      </div>
                    )}
                  </div>
                </div>
              </div>}

            </div>
          </div>
        </div>

        <div className="row">
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Psqi)
