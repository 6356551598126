import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
//import '../../../styles/assets/css/pages/dashboard.css'
import '../../../../styles/assets/css/pages/graph.css'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryScatter, VictoryGroup } from 'victory'
import { db_scale_day} from '../../../patients/variables/dashboard.js'
import { ch_daily_tracker_sleep, ch_weekly_tracker_sleep,
  ch_daily_tracker_medication, ch_weekly_tracker_medication,
  ch_daily_tracker_exercise, ch_weekly_tracker_exercise,
  ch_daily_weekly_mood_dep2, ch_daily_weekly_mood_dep3, ch_daily_weekly_mood_manic2,
  ch_daily_weekly_anxiety2, tracker_timeback }
  from '../variables/assessments_scale.js'

class Graph1 extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      graph_config_status: 1, // 1:hide, 2:show
      graph_type: 1, // 1:day, 2:week
      graph_data_show: 1, // 1: mood-depressed, 2: mood-manic, 3: sleep, 4: medication, 5: exercise, 6: anxiety
      tracker_timeback: 1,
      tracker_day: [],
      tracker_week: [],
      dataList: [],
    }
  }

  componentDidMount() {
    this.convertData(this.props.data_group)
    console.log(this.state.dataList)
  }

  convertData = (getData) => {
    var genBuff = []
    getData.map(each => {
      genBuff.push({
        date: this.convertDateFormat2(each.end_date),
        cnt: each.cnt,
      })
    })
    //console.log(genBuff)
    this.setState({
      dataList: genBuff,
    })
  }

  convertDateFormat2 = (date) => {
    var buff2 = date.split('T')
    var buff = buff2[0].split('-')
    return buff[1]+'/'+buff[2]+'/'+buff[0]
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  genArr1(start, num) {
    var a = []
    for(var i=start; i<=num; i++) { a[i] = i }
    return a
  }

  genArr2() {
    var a = []
    var gen_val = {}
    const arr = this.state.dataList
    //var name = ''
    arr.map(each => {
      gen_val = {
        x: each.date,
        y: each.cnt,
      }
      a.push(gen_val)
    })
    a.reverse()
    return a
  }

  genArr3(start, type, time) {
    var a = [] // y-axis
    const maxVal = Math.max(...this.state.dataList.map(o => o.cnt))
    for(var i=0; i<=maxVal+1; i++) {
      a.push(i)
    }
    return a
  }

  getXaxis() {
    var length = 0
    if(this.state.graph_type == 1) {
      length = this.state.tracker_day.length
    } else if(this.state.graph_type == 2) {
      length = this.state.tracker_week.length
    }
    return length
  }

  render() {
    console.log(this.state.dataList)
    return (
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-2 columns head2 float_left1 no_padding_right no_padding_left">
            Weekly Summary
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <VictoryChart theme={VictoryTheme.material}
              padding={{ top: 5, bottom: 30, left: 15, right: 15 }}
              domainPadding={0} height={100} >
              <VictoryAxis crossAxis
                tickValues={this.genArr1(0, this.state.dataList.length+1)}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 15, angle: 80},
                }}
              />
            <VictoryAxis dependentAxis crossAxis
                tickValues={this.genArr3(0, this.findLabelByValue(db_scale_day, this.state.graph_type, this.state.graph_data_show))}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5}
                }}
              />
              <VictoryGroup data={this.genArr2()} color="#ffac29" >
            <VictoryLine
              domain={{x: [0, this.getXaxis()+1], y: [0, 6]}}
              style={{
                data: { stroke: "#ffac29", strokeWidth: 0.5 },
                parent: { border: "1px solid #cccccc"}
              }}
              data={this.genArr2()}
            />
            <VictoryScatter size={1.15} symbol="dot"/>
            </VictoryGroup>
            </VictoryChart>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Graph1)
