import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../styles/assets/css/pages/interventions.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import Star from '@material-ui/icons/Star'
import CourseNavigation from './course_navigation.jsx'

class CourseLibrary extends Component {
  constructor() {
      super(  );
      this.state = {
          main_title: '',
          image: '',
          sessions: [],
          stars: 0,
          start_date: '',
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_main_page',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        cid: this.props.match.params.cid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          main_title: result['data'][0][0].main_title,
          stars: result['data'][1],
          start_date: result['data'][0][0].start_date,
        })
      }
    })
  }

  calculateDayDiff = (date, weeks_available) => {
    var dateDiff =  Math.floor(Math.abs(new Date() - new Date(date)) / (1000 * 60 * 60 * 24))
    //console.log(date, weeks_available + " : " + dateDiff)
    if(weeks_available > 0) {
      if(dateDiff/7 >= weeks_available) {
        return 1
      } else {
        return 2
      }
    } else {
      return 1
    }
    return 2
  }

  render() {
    //console.log(this.state.sessions)
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-6 columns headerPage1">
            {this.state.main_title}
          </div>
          <div className="small-12 medium-6 columns align_right">
            <CourseNavigation />
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
          <div className="small-12 columns block1 library_page">
            <div className="course_title_topic2">Audio Tracks</div>
            <div><ul>
            <li><a href="./session/2/4/1">Movement Exercise (session 1)</a></li>
            {this.calculateDayDiff(this.state.start_date, 1) == 1 &&
            <li><a href="./session/3/3/1">Body Breath Audio Track (session 2)</a> </li>}
            {this.calculateDayDiff(this.state.start_date, 1) == 2 &&
            <li><a href="./session/4/5/1">Mindfulness of Feelings (session 3)</a> </li>}
            {this.calculateDayDiff(this.state.start_date, 1) == 3 &&
            <li><a href="./session/5/4/1">Mindfulness of Thoughts (session 4)</a> </li>}
            {this.calculateDayDiff(this.state.start_date, 1) == 5 &&
            <li><a href="./session/7/5/5">Loving-Kindness Meditation (session 6)</a> </li>}
            </ul></div>
          </div>
          <div className="small-12 columns block1 library_page">
            <div className="course_title_topic2">Charts</div>
            <div className="title_session2">Week 1</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week1.pdf" target="_blank" >Practice Tracker Week 1</a></li>
            </ul></div>
            {this.calculateDayDiff(this.state.start_date, 1) == 1 && <>
            <div className="title_session2">Week 2</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week2.pdf" target="_blank" >Practice Tracker Week 2</a></li>
            </ul></div></>}
            {this.calculateDayDiff(this.state.start_date, 2) == 1 && <>
            <div className="title_session2">Week 3</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week3.pdf" target="_blank" >Practice Tracker Week 3</a></li>
            <li><a href="/mbct/library/Difficult_Moments_Chart.pdf" target="_blank" >Difficult Moments Chart</a></li>
            </ul></div></>}
            {this.calculateDayDiff(this.state.start_date, 3) == 1 && <>
            <div className="title_session2">Week 4</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week4.pdf" target="_blank" >Practice Tracker Week 4</a></li>
            <li><a href="/mbct/library/Difficult_Moments_Chart.pdf" target="_blank" >Difficult Moments Chart</a></li>
            </ul></div></>}
            {this.calculateDayDiff(this.state.start_date, 4) == 1 && <>
            <div className="title_session2">Week 5</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week5.pdf" target="_blank" >Practice Tracker Week 5</a></li>
            <li><a href="/mbct/library/Difficult_Moments_Chart.pdf" target="_blank" >Difficult Moments Chart</a></li>
            </ul></div></>}
            {this.calculateDayDiff(this.state.start_date, 5) == 1 && <>
            <div className="title_session2">Week 6</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week6.pdf" target="_blank" >Practice Tracker Week 6</a></li>
            <li><a href="/mbct/library/Difficult_Moments_Chart.pdf" target="_blank" >Difficult Moments Chart</a></li>
            </ul></div></>}
            {this.calculateDayDiff(this.state.start_date, 6) == 1 && <>
            <div className="title_session2">Week 7</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week7.pdf" target="_blank" >Practice Tracker Week 7</a></li>
            <li><a href="/mbct/library/Difficult_Moments_Chart.pdf" target="_blank" >Difficult Moments Chart</a></li>
            <li><a href="/mbct/library/Compassionate_Coaching_Diary.pdf" target="_blank" >Compassionate Coaching Diary</a></li>
            </ul></div></>}
            {this.calculateDayDiff(this.state.start_date, 7) == 1 && <>
            <div className="title_session2">Week 8</div>
            <div><ul>
            <li><a href="/mbct/library/Practice_Tracker_Week8.pdf" target="_blank" >Practice Tracker Week 8</a></li>
            <li><a href="/mbct/library/Difficult_Moments_Chart.pdf" target="_blank" >Difficult Moments Chart</a></li>
            <li><a href="/mbct/library/Compassionate_Coaching_Diary.pdf" target="_blank" >Compassionate Coaching Diary</a></li>
            <li><a href="./session/9/5/3">Compassionate Coaching Exercise</a></li>
            </ul></div></>}
          </div>
        </div>
      </div>
    </div>
)}}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourseLibrary)
