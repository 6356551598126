import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form2.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Email from '@material-ui/icons/Email'
import WarningIcon from '@material-ui/icons/Warning'
import Spinner from 'react-spinner-material'
//import { makeStyles } from "@material-ui/core/styles";

class CoursesAssign extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          loading: false,
          uid: 0,
          pid: 0,
          mbct: -1,
      }
  }

  componentDidMount() {
    this.getAssmStatus()
  }

  getAssmStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_course_assign',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: 1,
        pid: this.props.match.params.pid,
        uid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      console.log(result['data'])
      this.setState({
        mbct: this.transCheckedVal(result['data']),
      })
      if(typeof result['data'].uid != undefined) {
        //console.log(result['data'])
        this.setState({
          uid: result['data'].uid,
          loading: true,
        })
      }
    })
  }

  transCheckedVal = (val) => {
    if(val == 2) { // disable
      return 0
    } else if(val > 0){
      return 1
    }
    return val
  }

  conditionSwitch = (val) => {
    var new_val = true
    if(val === 0) {
      val = false
    }
    return val
  }

  handleSwitchChange = (e, name) => {
    var val = 1
    if(e.target.checked === false) {
      val = 0
    }
    this.setState({
      [name]: val
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/update_course_assign',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: 1,
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
      }
    })
    .then((result) => {
      if(result['data'] > 0) {
        this.setState({
          message: 'Update successfully!',
        })
        window.scrollTo(0, 0)
      } else {
        this.setState({
          message: 'Something went wrong. Please try again.',
        })
        window.scrollTo(0, 0)
      }
    })
  }

  render() {
    return (
      <div className="patient_edit">
        <div className="row">
          {!this.state.loading &&
          <div className="small-12 columns loading2">
            <Spinner color={"#bbbbbb"} radius={50}/>
          </div>}
          {this.state.loading &&
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className="warning_msg8">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>}
            <form id="make_appointment" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="small-12 columns">
                  <div className="title1">
                    Courses
                  </div>
                </div>
                <div className="small-12 columns">
                  <div className="bg1">
                    <div className="row">
                      <div className="small-12 medium-3 columns">
                        <Switch
                          checked={this.state.mbct}
                          onChange={(e) => this.handleSwitchChange(e, 'mbct')}
                          color="primary" name="mbct"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        /> MBCT
                      </div>
                      <div className="medium-6 columns show-for-medium">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="small-12 columns text_align_right">
                  <Button variant="contained" color="primary" disableElevation
                   onClick={this.handleSubmit} type="submit">
                    Update
                  </Button>
                </div>
              </div>
            </form>
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CoursesAssign)
